<script setup lang="ts">
import { reactive } from 'vue';
import { LoaderIcon } from 'vue-tabler-icons';
import { isString } from '@/utils';

const props = withDefaults(
  defineProps<{
    appearance?: 'primary' | 'secondary' | 'gray' | 'danger' | 'link';
    confirm?: boolean | string;
    disabled?: boolean;
    small?: boolean;
    loading?: boolean;
    pure?: boolean;
  }>(),
  { appearance: 'primary' },
);

const emit = defineEmits<{ (e: 'click'): void }>();

const state = reactive({
  isButtonConfirmShown: false,
});

const buttonClickHandler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
  if (props.disabled || props.loading) {
    return;
  }
  if (props.confirm && !state.isButtonConfirmShown) {
    state.isButtonConfirmShown = true;
    return;
  }
  emit('click');
  state.isButtonConfirmShown = false;
};
</script>

<template>
  <button
    v-if="pure && !state.isButtonConfirmShown"
    class="active:scale-95 duration-300"
    :class="{ 'btn--disabled': disabled }"
    @click="buttonClickHandler"
  >
    <Icon v-if="loading" :src="LoaderIcon" class="animate-spin" size="18" />
    <slot v-else />
  </button>
  <button
    v-else
    class="btn"
    :class="[
      state.isButtonConfirmShown ? 'btn--danger' : `btn--${appearance}`,
      {
        'btn--disabled': disabled,
        'btn--small': small,
      },
    ]"
    @click="buttonClickHandler"
    @mouseleave="state.isButtonConfirmShown = false"
  >
    <span
      v-if="state.isButtonConfirmShown"
      v-text="isString(confirm) ? confirm : 'Confirm'"
    />
    <slot v-else />
    <div v-if="loading" class="btn__loader">
      <Icon :src="LoaderIcon" class="animate-spin" size="18" />
    </div>
  </button>
</template>

<style scoped>
.btn {
  @apply relative shrink-0 flex justify-center items-center gap-2 py-3 px-6 border border-solid border-transparent text-sm leading-4 rounded-xl overflow-hidden  text-white active:scale-95 duration-300;
}

.btn--primary {
  @apply bg-primary hover:bg-primary-dark;
}

.btn--secondary {
  @apply text-primary bg-transparent border border-primary;
}

.btn--danger {
  @apply rounded-xl bg-red-500 text-red-50 hover:bg-red-600 active:bg-red-700 !important;
}

.btn--danger-light {
  @apply border border-red-500 bg-white text-red-500 hover:bg-red-200;
}

.btn--link {
  @apply bg-transparent text-primary hover:scale-105 active:scale-95;
}

.btn--gray {
  @apply bg-slate-100 text-slate-700 hover:bg-slate-200 active:bg-slate-300;
}

.btn--small {
  @apply p-2 font-normal;
}

.btn--disabled {
  @apply bg-transparent text-slate-400 opacity-100 cursor-not-allowed active:scale-100 !important;
}

.btn__loader {
  @apply absolute inset-0 bg-inherit flex justify-center items-center !important;
}
</style>
