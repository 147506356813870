import { computed, ComputedRef, Ref } from 'vue';
import {
  useTodos as useTodosAPI,
  useUpdateTodoItem,
  useDeleteTodoItem,
} from '@/api/todo';
import { TodoItemStatus, ToDoItem } from '@/api/types';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

export interface Todo {
  id: number;
  title: string;
  description: string;
  deadline: Date | null;
  status: TodoItemStatus;
  assignee?: {
    id: string;
    name: string;
    avatar?: string;
  };
  toString(): string;
}

interface TodosHook {
  todos: ComputedRef<Todo[] | undefined>;
  areTodosLoading: Ref<boolean>;
  updateStatus: (id: Todo['id']) => Promise<void>;
  updateAssignee: (id: Todo['id'], personId: string | null) => Promise<void>;
  updateDeadline: (
    id: Todo['id'],
    newDeadline: Todo['deadline'],
  ) => Promise<void>;
  deleteItem: (id: Todo['id']) => Promise<void>;
  refetchTodos: ReturnType<typeof useTodosAPI>['refetch'];
}

const mapToDoItemToTodo = ({
  id,
  title,
  description,
  dueAt,
  status,
  person,
  personId,
}: ToDoItem): Todo => ({
  id,
  title,
  description: description || '',
  deadline: dueAt ? new Date(dueAt) : null,
  status: <TodoItemStatus>status,
  ...(personId &&
    person && {
      assignee: {
        id: personId,
        name: person.name || person.email || 'Unknown',
        avatar: person.avatarUrl ?? undefined,
      },
    }),
  toString(): string {
    return `\
${this.title}
${this.description}
${this.deadline ? `\nDue date: ${this.deadline.toDateString()}` : ''}\
${this.assignee ? `\nAssignee: ${this.assignee.name}` : ''}\
`;
  },
});

const useUpdateStatus =
  (
    todos: ComputedRef<Todo[] | undefined>,
    updateTodoItemMutation: ReturnType<typeof useUpdateTodoItem>['mutateAsync'],
  ) =>
  async (id: Todo['id']): Promise<void> => {
    try {
      const todoItem = todos.value?.find((todo) => todo.id === id);
      if (!todoItem) {
        throw new Error('TodoItem not found');
      }

      await updateTodoItemMutation({
        id,
        status:
          todoItem.status === TodoItemStatus.IN_PROGRESS
            ? TodoItemStatus.DONE
            : TodoItemStatus.IN_PROGRESS,
      });
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    }
  };

const useDelete =
  (
    deleteTodoItemMutation: ReturnType<typeof useDeleteTodoItem>['mutateAsync'],
  ) =>
  async (id: Todo['id']): Promise<void> => {
    try {
      await deleteTodoItemMutation(id);
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    }
  };

const useUpdateAssignee =
  (
    updateTodoItemMutation: ReturnType<typeof useUpdateTodoItem>['mutateAsync'],
  ) =>
  async (id: Todo['id'], personId: string | null): Promise<void> => {
    try {
      await updateTodoItemMutation({ id, personId });
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    }
  };

const useUpdateDeadline =
  (
    updateTodoItemMutation: ReturnType<typeof useUpdateTodoItem>['mutateAsync'],
  ) =>
  async (id: Todo['id'], newDeadline: Todo['deadline']): Promise<void> => {
    try {
      await updateTodoItemMutation({ id, dueAt: newDeadline });
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    }
  };

export const useTodos = (): TodosHook => {
  const {
    data: todosData,
    isLoading: areTodosLoading,
    refetch: refetchTodos,
  } = useTodosAPI();
  const todos = computed<Todo[]>(
    () => todosData.value?.map(mapToDoItemToTodo) ?? [],
  );

  const { mutateAsync: updateTodoItemMutation } = useUpdateTodoItem();
  const { mutateAsync: deleteTodoItemMutation } = useDeleteTodoItem();

  const updateStatus = useUpdateStatus(todos, updateTodoItemMutation);
  const updateAssignee = useUpdateAssignee(updateTodoItemMutation);
  const updateDeadline = useUpdateDeadline(updateTodoItemMutation);
  const deleteItem = useDelete(deleteTodoItemMutation);

  return {
    todos,
    areTodosLoading,
    updateStatus,
    updateAssignee,
    updateDeadline,
    deleteItem,
    refetchTodos,
  };
};
