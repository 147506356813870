<script setup lang="ts">
import { ref } from 'vue';
import { UserIcon, PencilIcon, CameraIcon } from 'vue-tabler-icons';
import type { AvatarProps } from '@/api/types';
import { getColor } from '@/utils/people';

const props = withDefaults(
  defineProps<{
    person: AvatarProps;
    size?: string | number;
    isEditable?: boolean;
  }>(),
  { size: 12 },
);

defineEmits<{ (e: 'change', file: string): void }>();

const imageFailed = ref(false);

const name = props.person.name || props.person.email || '';
const [letter] = name;
</script>

<template>
  <div
    class="relative inline-block aspect-square rounded-full select-none"
    :class="[`w-${size}`, `h-${size}`]"
  >
    <img
      v-if="person.avatarUrl && !imageFailed"
      :src="person.avatarUrl"
      class="w-full h-full object-cover rounded-full"
      @error="imageFailed = true"
    />
    <v-avatar
      v-else-if="letter"
      class="w-full h-full flex rounded-full justify-center items-center uppercase text-3xl"
      :size="props.size"
      :color="getColor(name)"
    >
      <span v-text="letter" />
    </v-avatar>
    <Icon v-else :src="UserIcon" class="w-full h-full" />
    <FilePicker
      v-if="isEditable"
      class="absolute w-11 h-11 bottom-0 right-0 rounded-full bg-primary text-white flex justify-center items-center cursor-pointer hover:scale-105 duration-300 active:scale-100"
      @upload="(file: string) => $emit('change', file)"
    >
      <v-icon
        :icon="person.avatarUrl && !imageFailed ? PencilIcon : CameraIcon"
        :size="size"
      />
    </FilePicker>
  </div>
</template>
