export function isEmail(str: string) {
  //https://fightingforalostcause.net/content/misc/2006/compare-email-regex.php
  const regExp =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  return regExp.test(str);
}

export function isDomain(str: string) {
  const regExp = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  return regExp.test(str);
}

export function parseBoolean(str?: string | boolean) {
  return !!str && (JSON.parse(str as string) as boolean);
}

export function isBoolean(value: unknown): value is boolean {
  return !!value === value;
}

export function isString(value: unknown): value is string {
  return `${value}` === value;
}

export function isNumber(value: unknown): value is number {
  return Number(value) === value;
}

export function isDate(value: string) {
  return !!Date.parse(value);
}

export function includesCaseInsensetive(string: string, substring: string) {
  return string.toLowerCase().includes(substring.toLowerCase());
}

export function isEqual(a: unknown, b: unknown) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function safeParse(str: string) {
  try {
    return JSON.parse(str);
  } catch {
    return undefined;
  }
}

export function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function capitalizeFirstLetter(string?: string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const isStringOrError = (error: unknown): error is Error | string => {
  return error instanceof Error || typeof error === 'string';
};
