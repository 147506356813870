<script setup lang="ts">
import { VueElement } from 'vue';
import {
  CircleIcon,
  CircleCheckIcon,
  InfoSquareRoundedIcon,
} from 'vue-tabler-icons';
import { isBoolean } from '@/utils';

interface OptionType {
  label: string;
  value: string | boolean;
  disabled?: boolean;
  appendContent?: VueElement;
}

const props = withDefaults(
  defineProps<{
    value?: string | boolean;
    options?: OptionType[];
    loading?: boolean;
    title?: string;
    description?: string;
    name?: string;
  }>(),
  {
    name: 'radio',
  },
);

const emit = defineEmits<{ (e: 'change', value: string | boolean): void }>();

const onChange = (event: Event) => {
  const newValue = (event.target as HTMLInputElement).value;

  emit(
    'change',
    isBoolean(props.value) ? (newValue === 'true' ? true : false) : newValue,
  );
};
</script>

<template>
  <fieldset class="flex flex-col gap-4">
    <legend v-if="props.title" class="text-xs font-medium mb-2">
      {{ props.title }}
      <Icon
        v-if="props.description"
        v-tooltip="props.description"
        :src="InfoSquareRoundedIcon"
        size="16"
        class="mb-0.5"
      />
    </legend>
    <label
      v-for="option in props.options"
      :key="option.value.toString()"
      class="relative w-fit flex items-center gap-3 cursor-pointer"
      :class="[
        props.value === option.value ? 'text-primary' : 'text-slate-900',
        { 'cursor-progress': props.loading },
        { 'cursor-not-allowed !text-slate-500': option.disabled },
        { 'hover:opacity-70': !option.disabled },
      ]"
    >
      <input
        :checked="props.value === option.value"
        :value="option.value"
        :disabled="props.loading || option.disabled"
        type="radio"
        :name="props.name"
        class="hidden"
        @change="onChange"
      />
      <Icon
        :src="props.value === option.value ? CircleCheckIcon : CircleIcon"
        size="20"
      />
      <p
        class="text-base font-medium"
        :class="{ '!font-normal': option.disabled }"
      >
        {{ option.label }}
      </p>
      <component :is="option.appendContent" v-if="option.appendContent" />
    </label>
  </fieldset>
</template>
