import { api } from '@/api/index';
import {
  SpaceRole,
  FolderWithConversations,
  CreateSpaceDto,
  UpdateSpaceDto,
  DeleteSpaceDto,
  InviteToSpaceDto,
  UpdateSpaceAccessRoleDto,
  RemoveFromSpaceDto,
} from '@/api/types';
import {
  useCurrentWorkspace,
  useUpdateCurrentWorkspace,
  useCurrentWorkspaceId,
} from '@/auth/use-workspace';
import {
  useQuery,
  useMutation,
  UseQueryReturnType,
  useQueryClient,
} from '@tanstack/vue-query';
import { computed, Ref, unref } from 'vue';

export const useUpdate = (id?: string | Ref<string | undefined>) => {
  const { refetch } = useCurrentWorkspace();

  return useMutation({
    mutationKey: ['update_folder', id],
    mutationFn: ({ name, color }: { name: string; color: string }) => {
      return api.spaces.updateSpace.mutate({
        spaceId: unref(id) as string,
        spaceData: {
          name: name,
          color: color,
        },
      });
    },
    onSuccess: () => {
      refetch();
    },
  });
};

export const useRemoveFolderAccess = (id?: string | Ref<string>) => {
  const updateWorkspace = useUpdateCurrentWorkspace();
  const { data: workspace } = useCurrentWorkspace();

  return useMutation({
    mutationKey: ['folder_access', id],
    mutationFn: (accountId: string) =>
      api.spaces.deleteSpaceAccess.mutate({
        spaceId: unref(id) as string,
        accountId,
      }),
    onSuccess: (deleted) => {
      updateWorkspace({
        ...workspace.value,
        spaces: workspace.value?.spaces.map((space) => {
          if (space.id !== id) {
            return space;
          }
          return {
            ...space,
            spaceAccesses: space.spaceAccesses.filter(
              ({ accountId }) => deleted.accountId !== accountId,
            ),
          };
        }),
      });
    },
  });
};

export const useInvite = (id?: string | Ref<string | undefined>) => {
  const { refetch } = useCurrentWorkspace();

  return useMutation({
    mutationKey: ['invite_to_folder', id],
    mutationFn: (invite: { email: string; role: SpaceRole }) =>
      api.spaces.inviteSpaceMember.mutate({
        spaceId: unref(id) as string,
        invites: [invite],
      }),
    onSuccess: () => refetch(),
  });
};

export const useUpdateAccess = (id?: string | Ref<string>) => {
  const updateWorkspace = useUpdateCurrentWorkspace();
  const { data: workspace } = useCurrentWorkspace();

  return useMutation({
    mutationKey: ['update_folder_access', id],
    mutationFn: (accountId: string, role = SpaceRole.MEMBER) =>
      api.spaces.updateSpaceAccessRole.mutate({
        spaceId: unref(id) as string,
        role: role,
        accountId,
      }),
    onSuccess: (fresh) => {
      updateWorkspace({
        ...workspace.value,
        spaces: workspace.value?.spaces.map((space) => {
          if (space.id !== id) {
            return space;
          }
          return {
            ...space,
            spaceAccesses: space.spaceAccesses.map((access) =>
              fresh.accountId !== access.accountId
                ? access
                : { ...access, role: fresh.role },
            ),
          };
        }),
      });
    },
  });
};

export const useDelete = (id?: string | Ref<string>) => {
  const { refetch } = useCurrentWorkspace();

  return useMutation({
    mutationKey: ['delete_folder', unref(id)],
    mutationFn: (deleteConversations: boolean = false) =>
      api.spaces.deleteSpace.mutate({
        spaceId: unref(id) as string,
        deleteConversations,
      }),
    onSuccess: () => refetch(),
  });
};

export const useGetFoldersByWorkspaceId = (): UseQueryReturnType<
  FolderWithConversations[],
  unknown
> => {
  const workspaceId = useCurrentWorkspaceId();
  return useQuery<FolderWithConversations[]>({
    enabled: computed(() => !!workspaceId.value),
    queryKey: ['folders', workspaceId],
    queryFn: () =>
      api.spaces.getPrincipalSpaces.query({ workspaceId: workspaceId.value }),
  });
};

export const useCreateFolder = () => {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();

  return useMutation({
    mutationFn: (spaceData: CreateSpaceDto['spaceData']) =>
      api.spaces.createSpace.mutate({
        workspaceId: workspaceId.value,
        spaceData,
      }),
    onSuccess: () => queryClient.invalidateQueries(['folders']),
  });
};

export const useUpdateFolder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: UpdateSpaceDto) => api.spaces.updateSpace.mutate(dto),
    onSuccess: () => queryClient.invalidateQueries(['folders']),
  });
};

export const useDeleteFolder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: DeleteSpaceDto) => api.spaces.deleteSpace.mutate(dto),
    onSuccess: () => queryClient.invalidateQueries(['folders']),
  });
};

export const useAddFolderAccessors = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: InviteToSpaceDto) =>
      api.spaces.inviteSpaceMember.mutate(dto),
    onSuccess: () => queryClient.invalidateQueries(['folders']),
  });
};

export const useUpdateFolderAccessorRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: UpdateSpaceAccessRoleDto) =>
      api.spaces.updateSpaceAccessRole.mutate(dto),
    onSuccess: () => queryClient.invalidateQueries(['folders']),
  });
};

export const useRemoveFolderAccessor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: RemoveFromSpaceDto) =>
      api.spaces.deleteSpaceAccess.mutate(dto),
    onSuccess: () => queryClient.invalidateQueries(['folders']),
  });
};
