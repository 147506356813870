import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';
import { parseBoolean } from '..';

export function initSentry(app: App, router: Router) {
  if (!import.meta.env.VITE_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    app,
    enabled: parseBoolean(import.meta.env.VITE_SENTRY_ENABLED),
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    tracesSampleRate:
      (import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE as number) ?? 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate:
      import.meta.env.VITE_SENTRY_REPLAYS_SAMPLE_RATE ?? 1.0,
    trackComponents: [
      'SignIn',
      'Onboarding',
      'Settings',
      'Conversations',
      'Conversation',
    ],
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          new URL(import.meta.env.VITE_TRPC_BACKEND_URL).host,
          /^\//,
        ],
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // See: https://github.com/getsentry/raven-js/issues/73
    ignoreErrors: [
      'top.GLOBALS',
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'conduitPage',
      'Script error.',
    ],
    denyUrls: [
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /127\.0\.0\.1:4001\/isrunning/i,
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  Sentry.attachErrorHandler(app, { logErrors: true });
}
