import * as amplitude from '@amplitude/analytics-browser';
import { CustomUser } from '@/api/types';
import { LocationQuery } from 'vue-router';
import { UtmMedium, UtmSource } from './enums';

const mode = import.meta.env.MODE;

amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY as string);

export function identifyUser(user: CustomUser) {
  const userData = new Map<string, string>();
  userData.set('id', user.id);
  // based on a new requirement we changed userId to userEmail
  userData.set('user_id', user.email);
  userData.set('email', user.email);

  const name = user.name;
  if (name) {
    const [firstname, lastname] = name.split(' ');
    userData.set('name', name);
    if (firstname) {
      userData.set('firstname', firstname);
    }
    if (lastname) {
      userData.set('lastname', lastname);
    }
  }
  if (user.primaryAccount) {
    userData.set('primaryWorkspaceId', user.primaryAccount.workspaceId);
  }

  amplitude.setUserId(user.email);
  const props = new amplitude.Identify();

  userData.forEach((value, key) => {
    props.set(key, value);
  });
  amplitude.identify(props);
}

export function trackEvent(event: string, data?: Record<string, unknown>) {
  const screen = {
    client_width: window.innerWidth,
    client_height: window.innerHeight,
  };

  amplitude.track(event, { mode, system_source: 'webapp', ...screen, ...data });
}

export function trackView(pathname: string, view?: string) {
  trackEvent('View open', { view, pathname });
}

export const trackEventFromUtms = (query: LocationQuery): void => {
  const source = query.utm_source?.toString();
  const medium = query.utm_medium?.toString();

  const isSignInEvent =
    (source === UtmSource.Email && medium === UtmMedium.MagicLink) ||
    (source === UtmSource.Google && medium === UtmMedium.OAuth);

  if (isSignInEvent) {
    trackEvent('Sign in', {
      source,
      medium,
    });
  }
};
