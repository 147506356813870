import { api } from '@/api';
import { signOut } from '@/auth';
import { showError } from '@/utils/alert';
import { useMutation } from '@tanstack/vue-query';

export const signIn = async (referralCode?: string) => {
  try {
    const userData = await api.auth.signIn.mutate({ referralCode });
    if (!userData) {
      throw new Error('User not found');
    }
    return userData;
  } catch (e) {
    showError(e as Error);
  }
};

export const getCustomToken = () => {
  return api.auth.getCustomToken.query();
};

export const getAuthCode = async () => {
  try {
    return await api.auth.getAuthCode.query();
  } catch (e) {
    showError(e as Error);
  }
};

export const useScheduleDeleteCurrentUser = () => {
  return useMutation({
    mutationFn: () => api.auth.scheduleDeletePrincipal.mutate(),
    onSuccess: () => signOut(),
  });
};
