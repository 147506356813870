import { api } from '@/api';
import type { UserUpdateData } from '@/api/types';
import { useMutation, useQueryClient } from '@tanstack/vue-query';

export const updateUserData = (userProperties: UserUpdateData) => {
  return api.users.updatePrincipalData.mutate(userProperties);
};

export const handleOAuthCode = (code: string, redirectUrl: string) => {
  return api.users.credentials.handleOAuthCode.query({
    code,
    redirectUrl,
    provider: 'GOOGLE',
  });
};

export const setPrimaryAccount = (accountId: string) => {
  return api.users.setPrimaryAccount.mutate({ accountId });
};

export const invite = (emails: string[]) => {
  return api.users.invite.mutate({ emails });
};

export const useUpdateUserData = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UserUpdateData) =>
      api.users.updatePrincipalData.mutate(data),
    onSuccess: () => queryClient.invalidateQueries(['user']),
  });
};
