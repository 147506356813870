import { ref, Ref } from 'vue';
import { TranscriptItem, TranscriptHook, useTranscript } from '.';
import {
  useCreateHighlight as useCreateHighlightAPI,
  useDeleteBlock as useDeleteBlockAPI,
  useUpdateBlock as useUpdateBlock,
} from '@/api/blocks';
import { BlockCreateData } from '@/api/types';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface CreateHighlightDto {
  blockId: NonNullable<BlockCreateData['parentId']>;
  speakerId: NonNullable<BlockCreateData['participantId']>;
}

interface ManageTranscriptHook extends TranscriptHook {
  isHighlightUpdating: Ref<boolean>;
  isEditing: Ref<boolean>;
  isSaving: Ref<boolean>;
  toggleEditing: () => void;
  createHighlight: (dto: CreateHighlightDto) => Promise<void>;
  deleteBlock: (blockId: string) => Promise<void>;
  resetChanges: () => void;
  saveChanges: () => Promise<void>;
}

const isHighlightUpdating = ref(false);
const isEditing = ref(false);
const isSaving = ref(false);

const toggleEditing = (): void => {
  isEditing.value = !isEditing.value;
};

const useCreateHighlight =
  (
    createHighlightMutation: ReturnType<
      typeof useCreateHighlightAPI
    >['mutateAsync'],
  ) =>
  async (dto: CreateHighlightDto): Promise<void> => {
    try {
      isHighlightUpdating.value = true;
      await createHighlightMutation(dto);
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isHighlightUpdating.value = false;
    }
  };

const useDeleteBlock =
  (deleteBlockMutation: ReturnType<typeof useDeleteBlockAPI>['mutateAsync']) =>
  async (blockId: TranscriptItem['id']): Promise<void> => {
    try {
      isHighlightUpdating.value = true;
      await deleteBlockMutation(blockId);
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isHighlightUpdating.value = false;
    }
  };

const useUpdateBlocks =
  (
    transcript: Ref<TranscriptItem[]>,
    updateBlockMutation: ReturnType<typeof useUpdateBlock>['mutateAsync'],
  ) =>
  async (): Promise<void> => {
    try {
      isEditing.value = false;
      isSaving.value = true;
      await Promise.all(
        transcript.value.map(async (item) => {
          if (item.text !== item.originalText) {
            await updateBlockMutation({
              blockId: item.id,
              data: { text: item.text },
            });
          }
        }),
      );
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isSaving.value = false;
    }
  };

export const useManageTranscript = (): ManageTranscriptHook => {
  const { mutateAsync: createHighlightMutation } = useCreateHighlightAPI();
  const { mutateAsync: deleteBlockMutation } = useDeleteBlockAPI();

  const createHighlight = useCreateHighlight(createHighlightMutation);
  const deleteBlock = useDeleteBlock(deleteBlockMutation);

  const transcriptHook = useTranscript();
  const resetChanges = (): void => {
    transcriptHook.transcript.value.forEach((item) => {
      item.text = item.originalText;
    });
    isEditing.value = false;
  };

  const { mutateAsync: updateBlockMutation } = useUpdateBlock();
  const saveChanges = useUpdateBlocks(
    transcriptHook.transcript,
    updateBlockMutation,
  );

  return {
    ...transcriptHook,
    isHighlightUpdating,
    isEditing,
    isSaving,
    toggleEditing,
    createHighlight,
    deleteBlock,
    resetChanges,
    saveChanges,
  };
};
