<script setup lang="ts">
import { ref, computed } from 'vue';
import { ChevronDownIcon, CheckIcon } from 'vue-tabler-icons';
import { onClickOutside, useThrottleFn } from '@vueuse/core';
import { useFloating, shift, flip, offset } from '@floating-ui/vue';
import { isString } from '@/utils';

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    options?: {
      value: string;
      name: string;
      description?: string;
      disabled?: boolean;
    }[];
    buttonDelete?: boolean | string;
    position?: 'top-start' | 'bottom-start' | 'top-end' | 'bottom-end';
    triggerBtnClass?: string;
    small?: boolean;
  }>(),
  { position: 'bottom-end' },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'buttonDeleteClick'): void;
}>();

const dropdownRef = ref();
const isDropdownOpen = ref(false);

const modelValueName = computed(() => {
  if (!props.options) {
    return props.modelValue;
  }
  const matchedOption = props.options.find(
    (option) => option.value === props.modelValue,
  );
  return matchedOption?.name || 'Please select';
});

const selectOption = (newValue: string) => {
  emit('update:modelValue', newValue);
};

const floating = ref(null);
const { floatingStyles } = useFloating(dropdownRef, floating, {
  placement: props.position,
  middleware: [shift(), flip({ crossAxis: false }), offset(4)],
});

const toggle = useThrottleFn((nevValue: boolean) => {
  isDropdownOpen.value = nevValue;
}, 40);

onClickOutside(floating, () => toggle(false));
</script>

<template>
  <div ref="dropdownRef" class="inline-block">
    <div
      class="h-4 flex items-center gap-0.5 text-slate-800 cursor-pointer active:scale-95 duration-300"
      :class="[triggerBtnClass, small ? 'text-xs' : 'text-sm']"
      @click="toggle(!isDropdownOpen)"
    >
      <span v-text="modelValueName" />
      <Icon
        :class="{ '-rotate-180': isDropdownOpen }"
        :src="ChevronDownIcon"
        :size="small ? '14' : '20'"
      />
    </div>
    <Teleport to="#teleport">
      <div
        v-if="isDropdownOpen"
        ref="floating"
        class="z-[9999]"
        :style="floatingStyles"
        @click="toggle(false)"
      >
        <slot />
        <ul v-if="!$slots.default" class="my-2">
          <li
            v-for="option in options"
            :key="option.name"
            class="border-b flex items-center justify-between"
            :class="{ disabled: option.disabled }"
            @click="selectOption(option.value)"
          >
            <div class="w-11/12">
              <h3 class="text-slate-800 mb-1" v-text="option.name" />
              <p
                class="pr-1 pb-2 text-sm text-gray-400"
                v-text="option.description"
              />
            </div>
            <Icon
              v-show="modelValue === option.value"
              :src="CheckIcon"
              class="text-primary"
            />
          </li>
          <li v-if="buttonDelete" class="btn-wrapper">
            <Button
              appearance="danger-light"
              class="w-full pointer-events-auto"
              confirm
              @click="emit('buttonDeleteClick')"
            >
              {{ isString(buttonDelete) ? buttonDelete : 'Remove' }}
            </Button>
          </li>
        </ul>
      </div>
    </Teleport>
  </div>
</template>

<style scoped>
ul {
  @apply flex flex-col flex-wrap shadow rounded-2xl overflow-hidden w-64 bg-white text-left !important;
}

ul li.disabled {
  @apply hidden !important;
}

ul li {
  @apply p-3 pb-0 hover:bg-slate-100 active:bg-slate-200 cursor-pointer last:pb-3 last:border-b-0 !important;
}

.btn-wrapper {
  @apply pointer-events-none bg-white hover:bg-white active:bg-white !important;
}
</style>
