<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useUser } from '@/auth/use-session';
import BtnWithAModal from '@/components/common/organisms/BtnWithAModal.vue';
import VCopyButton from '@/components/common/organisms/VCopyButton.vue';
import EmailMultipleInput from '@/components/common/molecules/EmailMultipleInput.vue';
import {
  validationEmail,
  validationRequired,
  validationMaxLength,
} from '@/utils/validation/text-field';
import { GiftIcon } from 'vue-tabler-icons';
import { invite } from '@/api/users';
import { showError, showMessage } from '@/utils/alert';
import { useMutation } from '@tanstack/vue-query';
import { useRefFromRouterQuery } from '@/store/hooks/UseRefFromRouterQuery';

const { data: user } = useUser();

const inviteLink = computed(() => {
  return `${window.location.origin}/sign-in?referralCode=${user?.value.id}`;
});

const isModalOpened = useRefFromRouterQuery<boolean>(
  'showReferral',
  'true',
  true,
  false,
);

const state = reactive({
  isFormValid: false,
  emails: [],
});

const childComponentRef = ref();
const { isLoading, mutate: sendInvites } = useMutation({
  mutationFn: (emails: string[]) => invite(emails),
  onError(error: Error) {
    showError(error);
  },
  onSuccess() {
    showMessage('Request sent');
    childComponentRef.value.closeModal();
  },
});
const emailsInputRef = ref(null);
const sendInvitesHandler = (emails: string[]) => {
  if (!state.isFormValid) {
    emailsInputRef.value?.$el?.querySelector('input')?.focus();
    return;
  }
  sendInvites(emails);
};
</script>

<template>
  <BtnWithAModal
    ref="childComponentRef"
    btn-text="Send a referral to a friend Get 1 Week of Noty Pro!"
    btn-class="typo-body1"
    variant="elevated"
    color="primary"
    :is-open="isModalOpened"
  >
    <template #btn-append>
      <v-icon
        :icon="GiftIcon"
        size="30"
        color="#A79AFC"
        class="absolute right-0 top-8 -rotate-12 opacity-60 scale-200"
      />
    </template>
    <template #modal>
      <h1 class="typo-h1">Sharing is caring!</h1>
      <p class="typo-body1 mb-8">Refer a friend and get 7 days of Noty Pro!</p>
      <h2 class="mb-1">How it works:</h2>
      <ul>
        <li>1. Add their email address or send them the below link directly</li>
        <li>2. Your friend signs up with us</li>
        <li>3. You get 7 days of Noty Pro</li>
        <li>4. Repeat and Get even More!</li>
      </ul>
      <h2 class="mt-6 mb-2 basis full">Send the email to:</h2>
      <v-form
        v-model="state.isFormValid"
        class="w-full flex"
        validate-on="blur"
        @submit.prevent="sendInvitesHandler(state.emails)"
      >
        <EmailMultipleInput
          ref="emailsInputRef"
          v-model="state.emails"
          placeholder="Type emails to send invite letters.."
          :rules="[
            validationEmail,
            validationRequired,
            (emails: string[]) => validationMaxLength(emails, 10),
          ]"
        />
        <v-btn
          variant="flat"
          color="primary"
          type="submit"
          :loading="isLoading"
          text="Send"
          class="h-[38px] max-w-[60px]"
        />
      </v-form>
      <h2 class="my-2">Or copy the link below to send them directly:</h2>
      <div class="flex">
        <div class="px-3 py-2 bg-grey-10 rounded text-sm break-all mr-1">
          {{ inviteLink }}
        </div>
        <VCopyButton
          :value="inviteLink"
          variant="flat"
          color="primary"
          no-icon
          class="max-w-[60px] w-full"
        />
      </div>
    </template>
  </BtnWithAModal>
</template>
