import { Ref, UnwrapRef, ref } from 'vue';
import { useRouter } from 'vue-router';

export const useRefFromRouterQuery = <T>(
  key: string,
  checkValue: string,
  correct: T,
  wrong: T,
): Ref<UnwrapRef<T>> => {
  const router = useRouter();

  const locationQuery = router.currentRoute.value.query;
  const hasKey = key in locationQuery;
  if (!hasKey) {
    return ref(wrong);
  }

  const r = ref(locationQuery[key] === checkValue ? correct : wrong);
  router.replace({ query: { ...locationQuery, [key]: undefined } });
  return r;
};
