import { ref, Ref } from 'vue';
import { useDeleteCurrentWorkspace as useDeleteCurrentWorkspaceApi } from '@/api/workspaces';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface DeleteWorkspaceHook {
  isDeleteCurrentWorkspaceConfirmationOpen: Ref<boolean>;
  isDeleting: Ref<boolean>;
  toggleDeleteCurrentWorkspaceConfirmation: () => void;
  deleteCurrentWorkspace: () => Promise<void>;
}

const isDeleteCurrentWorkspaceConfirmationOpen = ref(false);
const isDeleting = ref(false);

const toggleDeleteCurrentWorkspaceConfirmation = (): void => {
  isDeleteCurrentWorkspaceConfirmationOpen.value =
    !isDeleteCurrentWorkspaceConfirmationOpen.value;
};

const useDelete =
  (
    deleteCurrentWorkspaceMutation: ReturnType<
      typeof useDeleteCurrentWorkspaceApi
    >['mutateAsync'],
  ) =>
  async (): Promise<void> => {
    try {
      isDeleting.value = true;
      await deleteCurrentWorkspaceMutation();
      isDeleteCurrentWorkspaceConfirmationOpen.value = false;
      // Redirect home with reload
      location.replace('/');
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isDeleting.value = false;
    }
  };

export const useDeleteCurrentWorkspace = (): DeleteWorkspaceHook => {
  const { mutateAsync: deleteCurrentWorkspaceMutation } =
    useDeleteCurrentWorkspaceApi();
  const deleteCurrentWorkspace = useDelete(deleteCurrentWorkspaceMutation);

  return {
    isDeleteCurrentWorkspaceConfirmationOpen,
    isDeleting,
    toggleDeleteCurrentWorkspaceConfirmation,
    deleteCurrentWorkspace,
  };
};
