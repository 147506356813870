import { useSession } from '@/auth/use-session';
import { useExternalResources } from '@/store/hooks';
import { onMounted, onUnmounted, watch } from 'vue';

const INTERCOM_APP_ID = 'jpw1bjoe';

export const useIntercom = (): void => {
  const { data } = useSession();

  const [loadResource, unloadResource] = useExternalResources({
    id: 'intercom-js',
    attrs: {
      ['app_id']: INTERCOM_APP_ID,
    },
    innerText: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
  });

  onMounted(() => {
    if (INTERCOM_APP_ID) {
      loadResource();
    }
  });

  onUnmounted(() => {
    unloadResource();
  });

  watch(
    () => data.value?.user,
    (user) => {
      if (INTERCOM_APP_ID && user?.email) {
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: INTERCOM_APP_ID,
          name: user?.name, // Full name
          email: user.email, // the email for your user
          created_at: user?.createdAt, // Signup date as a Unix timestamp
        });
      }
    },
  );
};
