<script setup lang="ts">
import { TrashIcon } from 'vue-tabler-icons';
import { useCurrentConversation } from '@/components/conversation-new/hooks';

const {
  deleteConversation,
  isConversationDeleting,
  isDeleteConfirmationOpen,
  toggleDeleteConfirmation,
} = useCurrentConversation();
</script>
<template>
  <v-dialog v-model="isDeleteConfirmationOpen" persistent max-width="544">
    <div
      class="flex flex-col gap-y-8 bg-white w-full overflow-hidden rounded-2xl p-8 border border-grey-20 border-solid relative"
    >
      <ClosePopupButton
        :on-click="toggleDeleteConfirmation"
        :disabled="isConversationDeleting"
      />
      <div class="flex flex-col gap-y-3">
        <h4 class="font-inter text-2xl leading-normal">Delete confirmation</h4>
        <p class="typo-body1 font-normal">
          Are you sure you want to delete this conversation?
        </p>
      </div>
      <div class="flex justify-end items-center gap-x-4">
        <v-btn
          variant="text"
          color="error-functional-600"
          height="auto"
          class="typo-body1 font-semibold px-[2px]"
          :disabled="isConversationDeleting"
          :loading="isConversationDeleting"
          @click="deleteConversation"
        >
          <template #prepend>
            <Icon :src="TrashIcon" class="w-4 h-4 text-alerts-error-600" />
          </template>
          Delete conversation
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          height="36"
          class="typo-body1 font-semibold"
          :disabled="isConversationDeleting"
          @click="toggleDeleteConfirmation"
        >
          Cancel
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
