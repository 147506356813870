const KEY = 'referral_email';

export const setReferralEmail = (email: string) => {
  localStorage.setItem(KEY, email);
};

export const popReferralEmail = () => {
  const email = localStorage.getItem(KEY);
  localStorage.removeItem(KEY);
  return email;
};
