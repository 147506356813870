import { Ref, computed, ComputedRef, ref, watch } from 'vue';
import { isEmpty } from 'lodash';
import { useUser } from '@/auth/use-session';
import { User, UserUpdateData } from '@/api/types';
import { useUpdateUserData } from '@/api/users';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface PersonalData {
  avatarUrl: string;
  name: string;
  email: string;
  companyRole: string;
  companyName: string;
}

interface PersonalDataHook {
  userData: Ref<PersonalData>;
  hasChanges: ComputedRef<boolean>;
  isSaving: Ref<boolean>;
  reset: () => void;
  save: () => void;
}

const mapUserToUserData = (user: Ref<User | undefined>): PersonalData =>
  ['avatarUrl', 'name', 'email', 'companyRole', 'companyName'].reduce(
    (acc, key) => ({
      ...acc,
      [key]: user.value?.[key as keyof User] || '',
    }),
    {} as PersonalData,
  );

const userData = ref<PersonalData>({} as PersonalData);
const isSaving = ref(false);

const useReset = (user: Ref<User | undefined>) => (): void => {
  userData.value = mapUserToUserData(user);
};

const computeUserUpdateData = (user: Ref<User | undefined>): UserUpdateData => {
  const { avatarUrl, ...textFields } = userData.value;
  return {
    ...(avatarUrl !== (user.value?.avatarUrl ?? '') && {
      avatarBase64: avatarUrl,
    }),
    ...Object.entries(textFields).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(value !== (user.value?.[key as keyof User] ?? '') && {
          [key]: value,
        }),
      }),
      {} as UserUpdateData,
    ),
  };
};

const useSave =
  (
    user: Ref<User | undefined>,
    updateUserDataMutation: ReturnType<typeof useUpdateUserData>['mutateAsync'],
  ) =>
  async () => {
    try {
      isSaving.value = true;
      const updateData = computeUserUpdateData(user);
      !isEmpty(updateData) && (await updateUserDataMutation(updateData));
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isSaving.value = false;
    }
  };

export const usePersonalData = (): PersonalDataHook => {
  const { data: user } = useUser();
  userData.value = mapUserToUserData(user);
  watch(user, () => {
    userData.value = mapUserToUserData(user);
  });

  const reset = useReset(user);

  const { mutateAsync: updateUserDataMutation } = useUpdateUserData();
  const save = useSave(user, updateUserDataMutation);

  const hasChanges = computed(() =>
    Object.entries(userData.value).some(
      ([key, value]) => value !== (user.value?.[key as keyof User] ?? ''),
    ),
  );

  return {
    userData,
    hasChanges,
    isSaving,
    reset,
    save,
  };
};
