<script setup lang="ts">
import { useFreeSeatsStorage, useIsFreeSeatsPopupTime } from '@/utils/popups';
import { useStorage } from '@vueuse/core';
import { ref, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useCurrentWorkspace } from '@/auth/use-workspace';
import {
  useIsTeamPlan,
  useIsWorkspaceMember,
  useIsWorkspaceAdmin,
} from '@/store/hooks';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { trackEvent } from '@/utils/analytics';

import { AccountRole } from '@/api/types';
import FreeSeatsIcon from '@/assets/free-seats.svg';
import FreeIcon from '@/assets/free.svg';

const FREE_SEATS_LIMIT = 5;

const isShown = ref(false);
const router = useRouter();
const route = useRoute();

const { data: workspace, isLoading } = useCurrentWorkspace();
const isTeamPlan = useIsTeamPlan();
const isCurrentUserMember = useIsWorkspaceMember();
const isCurrentUserAdmin = useIsWorkspaceAdmin();
const isFreeSeatTime = useIsFreeSeatsPopupTime();
const storage = useFreeSeatsStorage();

const onceStorage = useStorage<Record<string, boolean>>('seats-popup-once', {});

const isSettingsRoute = computed(
  () => route.name === ROUTE_NAMES.SETTINGS.WORKSPACE,
);
const isOtherRoute = computed(
  () => route.name !== ROUTE_NAMES.SETTINGS.WORKSPACE,
);
const shouldShow = computed(() => {
  return (
    (isOtherRoute.value && !onceStorage.value[workspace.value?.id || '']) ||
    (isSettingsRoute.value && isFreeSeatTime.value)
  );
});

const paidSeatsNumber = computed(() => {
  return workspace.value
    ? workspace.value.accounts?.filter(({ role }) => role !== AccountRole.LITE)
        .length
    : Infinity;
});

const handleCloseModal = () => {
  trackEvent('Free seats popup - close', {
    workspace: workspace.value?.id,
  });

  isShown.value = false;
};

const handleInviteClick = () => {
  trackEvent('Free seats popup - invite team', {
    workspace: workspace.value?.id,
  });

  isShown.value = false;

  router.push({
    name: ROUTE_NAMES.SETTINGS.MEMBERS,
    params: { workspaceId: workspace.value?.id },
  });
};

watch(
  () => ({
    seats: paidSeatsNumber.value,
    plan: isTeamPlan.value,
    user: isCurrentUserMember.value || isCurrentUserAdmin.value,
    show: shouldShow.value,
  }),
  ({ seats, plan, user, show }) => {
    if (seats >= FREE_SEATS_LIMIT || !plan || !user || !show) {
      return;
    }

    isShown.value = true;
    if (isSettingsRoute.value) {
      storage.value[workspace.value?.id || ''] = Date.now();
      return;
    }
    if (isOtherRoute.value) {
      onceStorage.value[workspace.value?.id || ''] = true;
      return;
    }
  },
);
trackEvent('Popup Invite Your Teammates View');
</script>

<template>
  <v-dialog v-model="isShown" :persistent="true" width="auto">
    <v-card max-width="545" class="p-9 rounded-xl text-center align-center">
      <div
        class="relative mt-[52px] mb-[42px] inline-flex flex-col items-center mr-9"
      >
        <FreeSeatsIcon />
        <FreeIcon class="absolute top-1 right-0 translate-x-1/2" />
      </div>

      <p class="max-w-[410px] text-black text-2xl font-bold text-center mb-2">
        You got more free seats in your plan.
      </p>

      <p class="max-w-[410px] text-black text-xl mb-9">
        Invite your teammates and become productive
      </p>

      <v-card-actions class="gap-2">
        <v-btn
          size="large"
          variant="text"
          color="grey"
          @click="handleCloseModal"
        >
          Close
        </v-btn>
        <v-btn
          size="large"
          color="primary"
          variant="flat"
          :loading="isLoading"
          @click="handleInviteClick"
        >
          Invite team!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
