import { api } from '@/api';
import { WorkspaceUpdateInput, ZoomJoinToType } from '@/api/types';
import { useUser, useUserId } from '@/auth/use-session';
import {
  useCurrentWorkspace,
  useCurrentWorkspaceId,
  useWorkspaces,
} from '@/auth/use-workspace';
import { showError } from '@/utils/alert';
import { trackEvent } from '@/utils/analytics';
import { useIsOnboardingProcess } from '@/utils/onboarding';
import { router } from '@/router';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { watch } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';

async function createWorkspace(name: string, onboarding = false) {
  const workspace = await api.workspaces.create.mutate({ name });
  trackEvent('Workspace created', {
    workspace: workspace.id,
    workspace_name: name,
    onboarding: onboarding,
  });
  if (workspace.trialEnd) {
    window.localStorage.setItem('FreeTrialNotification', workspace.id);
  }
  if (onboarding) {
    await addDemoConversation(workspace.id);
  }
  return workspace;
}

function joinWorkspace(id: string) {
  trackEvent('Workspace join', { workspace: id, onboarding: true });
  return api.workspaces.join.mutate({ id });
}

export const useNoWorkspaceRedirect = () => {
  const { isFetched: isUserFetched, data: user } = useUser();
  const { isFetched: isWorkspacesFetched, data: workspaces } = useWorkspaces();
  const isOnboarding = useIsOnboardingProcess();

  watch(
    () => isUserFetched.value && isWorkspacesFetched.value,
    (isFetched) => {
      if (!isFetched) {
        return;
      }
      if (!user.value?.primaryAccount && !workspaces.value?.length) {
        router.push({
          name: isOnboarding.value
            ? ROUTE_NAMES.ONBOARDING
            : ROUTE_NAMES.CREATE_WORKSPACE,
        });
      }
    },
    { immediate: true },
  );
};

function deleteWorkspace(id: string) {
  return api.workspaces.delete
    .mutate({ id })
    .then(() => {
      location.replace('/');
    })
    .catch(showError);
}

function updateWorkspace(workspace: WorkspaceUpdateInput) {
  return api.workspaces.update.mutate(workspace).catch(showError);
}

function getWorkspacePeople(id: string, spaceId?: string) {
  return api.workspaces.getPeople
    .query({ id, spaceId }, { context: { skipBatch: true } })
    .catch(showError);
}

export const fetchPersonalSettings = (workspaceId: string) =>
  api.workspaces.personalSettings.query({ id: workspaceId });

export const updateConversationEndEmailsIgnore = (
  workspaceId: string,
  ignore = false,
) =>
  api.workspaces.setIgnoreConversationEndEmails.mutate({
    id: workspaceId,
    ignore,
  });

function addDemoConversation(workspaceId: string) {
  return api.workspaces.demoConversation.mutate({ id: workspaceId });
}

function updateZoomCapturingSettings(
  workspaceId: string,
  zoomBotJoiningStrategy: ZoomJoinToType,
) {
  return api.workspaces.updateZoomCapturingSettings.mutate({
    id: workspaceId,
    zoomBotJoiningStrategy,
  });
}

function calendarEvents(workspaceId: string, from?: Date, to?: Date) {
  return api.workspaces.calendarEvents.query({ id: workspaceId, from, to });
}

export {
  createWorkspace,
  deleteWorkspace,
  getWorkspacePeople,
  calendarEvents,
  joinWorkspace,
  updateWorkspace,
  updateZoomCapturingSettings,
};

export const useDeleteCurrentWorkspace = () => {
  const queryClient = useQueryClient();
  const currentWorkspace = useCurrentWorkspaceId();

  return useMutation({
    mutationFn: () =>
      api.workspaces.delete.mutate({ id: currentWorkspace.value }),
    onSuccess: () => {
      queryClient.invalidateQueries(['workspaces']);
      queryClient.invalidateQueries(['currentWorkspace']);
    },
  });
};

export const useUpdateCurrentWorkspace = () => {
  const queryClient = useQueryClient();
  const currentWorkspaceId = useCurrentWorkspaceId();

  return useMutation({
    mutationFn: (dto: Omit<WorkspaceUpdateInput, 'id'>) =>
      api.workspaces.update.mutate({
        ...dto,
        id: currentWorkspaceId.value,
      }),
    onSuccess: () => queryClient.invalidateQueries(['currentWorkspace']),
  });
};

export const useMakeCurrentWorkspacePrimary = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();
  const { data: currentWorkspace } = useCurrentWorkspace();

  return useMutation({
    mutationFn: () => {
      const newPrimaryAccountId = currentWorkspace.value?.accounts.find(
        (user) => user.userId === userId.value,
      )?.id;

      if (!newPrimaryAccountId) {
        throw new Error('Primary account not found');
      }

      return api.users.setPrimaryAccount.mutate({
        accountId: newPrimaryAccountId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
      queryClient.invalidateQueries(['workspaces']);
    },
  });
};

export const useCurrentWorkspacePeople = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  return useQuery({
    queryKey: ['currentWorkspacePeople', currentWorkspaceId],
    queryFn: () =>
      api.workspaces.getPeople.query({ id: currentWorkspaceId.value }),
  });
};
