import { Ref, ref } from 'vue';
import {
  useCurrentConversation as useCurrentConversationAPI,
  useDeleteCurrentConversation,
  useUpdateCurrentConversation,
} from '@/api/conversations';
import { ConversationWithPersmissions } from '@/api/types';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

export interface CurrentConversationHook {
  conversation: Ref<ConversationWithPersmissions | undefined>;
  isConversationLoading: Ref<boolean>;
  isNameUpdating: Ref<boolean>;
  updateName: (newName: string) => Promise<void>;
  isDeleteConfirmationOpen: Ref<boolean>;
  toggleDeleteConfirmation: () => void;
  isConversationDeleting: Ref<boolean>;
  deleteConversation: () => Promise<void>;
}

const isNameUpdating = ref(false);
const isDeleteConfirmationOpen = ref(false);
const isConversationDeleting = ref(false);

const toggleDeleteConfirmation = (): void => {
  isDeleteConfirmationOpen.value = !isDeleteConfirmationOpen.value;
};

const useUpdateName =
  (
    updateCurrentConversationMutation: ReturnType<
      typeof useUpdateCurrentConversation
    >['mutateAsync'],
  ) =>
  async (name: string): Promise<void> => {
    try {
      isNameUpdating.value = true;
      await updateCurrentConversationMutation({ name });
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isNameUpdating.value = false;
    }
  };

const useDelete =
  (
    deleteCurrentConversationMutation: ReturnType<
      typeof useDeleteCurrentConversation
    >['mutateAsync'],
  ) =>
  async (): Promise<void> => {
    try {
      isConversationDeleting.value = true;
      await deleteCurrentConversationMutation();
      isDeleteConfirmationOpen.value = false;
      location.replace('/');
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isConversationDeleting.value = false;
    }
  };

export const useCurrentConversation = (): CurrentConversationHook => {
  const { data: conversation, isLoading: isConversationLoading } =
    useCurrentConversationAPI();

  const { mutateAsync: updateCurrentConversationMutation } =
    useUpdateCurrentConversation();
  const { mutateAsync: deleteCurrentConversationMutation } =
    useDeleteCurrentConversation();

  const updateName = useUpdateName(updateCurrentConversationMutation);
  const deleteConversation = useDelete(deleteCurrentConversationMutation);

  return {
    conversation,
    isConversationLoading,
    isNameUpdating,
    updateName,
    isDeleteConfirmationOpen,
    toggleDeleteConfirmation,
    isConversationDeleting,
    deleteConversation,
  };
};
