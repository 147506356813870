<script setup lang="ts">
import { VideoIcon, PencilIcon } from 'vue-tabler-icons';
import { SidebarItem, GmeetLangsAutocomplete } from '.';
import { useGmeetLang } from '../hooks';

const { showLangsAutocomplete, langTitle } = useGmeetLang();
</script>

<template>
  <v-menu v-model="showLangsAutocomplete" :close-on-content-click="false">
    <template #activator="{ props }">
      <SidebarItem v-bind="props">
        <template #prepend>
          <Icon :src="VideoIcon" class="h-5 w-5" />
        </template>
        <template #append>
          <Icon :src="PencilIcon" class="h-5 w-5" />
        </template>
        <template #default>
          <div class="flex flex-col items-start grow">
            <div class="typo-body1">Transcription Language</div>
            <div
              class="font-inter text-2xs font-medium leading-3 text-grey-100"
            >
              {{ langTitle }}
            </div>
          </div>
        </template>
      </SidebarItem>
    </template>
    <template #default>
      <GmeetLangsAutocomplete />
    </template>
  </v-menu>
</template>
