<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { ChevronUpIcon } from 'vue-tabler-icons';
import { onClickOutside } from '@vueuse/core';

const props = withDefaults(
  defineProps<{
    isOpen?: boolean;
    accordion?: boolean;
    showIcon?: boolean;
  }>(),
  { accordion: true },
);

const emit = defineEmits<{ (e: 'toggle'): void }>();

const toggle = () => {
  if (!isContentProvided.value) {
    return;
  }
  emit('toggle');
};

const collapse = ref();
onClickOutside(collapse, () => {
  if (props.isOpen && !props.accordion) {
    toggle();
  }
});

const contentWrapper = ref();
const isContentProvided = computed(
  () => !!contentWrapper.value?.childElementCount,
);

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen && !props.accordion) {
      setTimeout(() => {
        contentWrapper.value.scrollIntoViewIfNeeded({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      });
    }
  },
);
</script>

<template>
  <div ref="collapse" tabindex="0" class="relative" @click.prevent.stop>
    <div
      role="button"
      class="w-full flex justify-between items-center cursor-pointer"
      @click="toggle"
    >
      <div class="max-w-full flex-auto">
        <slot name="header" />
      </div>
      <Icon
        v-if="props.showIcon"
        :src="ChevronUpIcon"
        class="mr-3"
        :class="[{ '-rotate-180': props.isOpen }]"
        size="18"
      />
    </div>
    <div
      v-show="props.isOpen"
      ref="contentWrapper"
      class="w-full z-30"
      :class="{ 'absolute pb-8': !accordion }"
      @click="!props.accordion && toggle()"
    >
      <slot name="content" />
    </div>
  </div>
</template>
