import { ref, Ref, onUnmounted } from 'vue';

export enum ConversationAssistsTab {
  Todo = 'To do',
  Transcription = 'Transcription',
}

interface ConversationAssistsTabsHook {
  tabs: ConversationAssistsTab[];
  selectedTab: Ref<ConversationAssistsTab>;
}

const selectedTab = ref(ConversationAssistsTab.Todo);

export const useConversationAssistsTabs = (): ConversationAssistsTabsHook => {
  const tabs = Object.values(ConversationAssistsTab);
  onUnmounted(() => (selectedTab.value = ConversationAssistsTab.Todo));

  return {
    tabs,
    selectedTab,
  };
};
