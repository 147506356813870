import { createClient } from '@supabase/supabase-js';

const url = import.meta.env.VITE_SUPABASE_URL as string;
const key = import.meta.env.VITE_SUPABASE_ANON_KEY as string;
if (!url || !key) {
  console.error('Supabase credentials not provided', { url, key });
}
export const supabase = createClient(url, key, {
  auth: {
    autoRefreshToken: true,
  },
});
