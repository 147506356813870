<script setup lang="ts">
import { ref } from 'vue';
import { useUser } from '@/auth/use-session';

const props = withDefaults(
  defineProps<{
    btnText: string;
    btnClass?: string;
    variant?: 'elevated' | 'text';
    color?: string;
    isOpen?: boolean;
  }>(),
  {
    variant: 'text',
  },
);

const closeModal = () => {
  isActive.value = false;
};
defineExpose({
  closeModal,
});

const isActive = ref(props.isOpen || false);
const { isLoading: isUserLoading } = useUser();
</script>

<template>
  <v-dialog v-model="isActive" width="570" height="auto" persistent>
    <template #activator="{ props: dialogModel }">
      <v-btn
        :elevation="2"
        :color="props.color"
        :variant="props.variant"
        v-bind="dialogModel"
        :disabled="isUserLoading"
        size="large"
        class="p-2 px-4 tracking-[0.01rem] no-text-transform bg-white text-black rounded-b-md shadow-none h-auto overflow-hidden"
      >
        <template #prepend>
          <slot name="btn-prepend" />
        </template>
        <template #default>
          <span
            class="typo-body2 text-base w-[200px] text-none text-ellipsis text-left overflow-hidden"
            :class="props.btnClass"
            v-text="props.btnText"
          />
        </template>
        <template #append>
          <slot name="btn-append" />
        </template>
      </v-btn>
    </template>
    <v-card class="p-10 pb-6 rounded-[16px] max-sm:rounded-lg">
      <v-card-item class="p-0">
        <v-card-text class="p-0">
          <keep-alive>
            <slot name="modal" />
          </keep-alive>
        </v-card-text>
      </v-card-item>
      <v-card-actions class="p-0 ml-auto mt-6">
        <v-btn
          variant="text"
          text="Close"
          class="bg-white text-primary h-[38px] w-20"
          @click="isActive = false"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-btn :deep(.v-btn__content) {
  @apply whitespace-normal;
}
</style>
