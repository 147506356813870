import { ComputedRef, computed, ref, Ref } from 'vue';
import type { AIMessage } from '@noty/database';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';
import { useAskQuestion, useGetChatHistory } from '@/api/ai-messages';
import { AIMessageDirection, AIMessageStatus, Pricing } from '@/api/types';
import { useCurrentConversation } from '.';

type Message = Pick<AIMessage, 'id' | 'message' | 'status' | 'direction'>;

interface ChatAssistantHook {
  chatHistory: Ref<Message[] | undefined>;
  isChatLoading: Ref<boolean>;
  isMessagePending: ComputedRef<boolean>;
  questionInput: Ref<string>;
  createChatAssist: () => Promise<void>;
  usedAssists: ComputedRef<number>;
  assistsLimit: ComputedRef<number>;
  areLimitsLoading: Ref<boolean>;
}

const questionInput = ref('');

const useCreateChatAssist =
  (askQuestionMutation: ReturnType<typeof useAskQuestion>['mutateAsync']) =>
  async () => {
    try {
      const question = questionInput.value.trim();
      questionInput.value = '';

      await askQuestionMutation({ question });
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    }
  };

export const useChatAssistant = (): ChatAssistantHook => {
  const { data: chatHistory, isLoading: isChatLoading } = useGetChatHistory();
  const { mutateAsync: askQuestionMutation } = useAskQuestion();
  const { conversation, isConversationLoading: areLimitsLoading } =
    useCurrentConversation();

  const isMessagePending = computed(
    () => chatHistory.value?.at(-1)?.status === AIMessageStatus.PENDING,
  );

  const createChatAssist = useCreateChatAssist(askQuestionMutation);

  const usedAssists = computed(
    () =>
      chatHistory.value?.filter(
        ({ direction }) => direction === AIMessageDirection.OUTGOING,
      ).length ?? 0,
  );

  const assistsLimit = computed(() => {
    /**
     * @remarks Had to duplicate this rule from the BE
     * due to an issue with the enum import from the database
     * @see {@link file://./../../../../../trpc_backend/src/domain/ai-messages/rules.ts#getAIMessagesLimitByPricing}
     * @todo Remove this duplication once the importing issue is resolved
     */
    switch (conversation.value?.workspace.pricing ?? Pricing.PERSONAL) {
      case Pricing.PERSONAL:
        return 1;
      default:
        return 3;
    }
  });

  return {
    chatHistory,
    isChatLoading,
    isMessagePending,
    questionInput,
    createChatAssist,
    usedAssists,
    assistsLimit,
    areLimitsLoading,
  };
};
