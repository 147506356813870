import { api } from '@/api';
import { showError } from '@/utils/alert';

export const updateSubscription = async (
  workspaceId: string,
  priceId: string,
  promotionCodeId?: string,
  trialPeriodDays?: number,
) => {
  const res = await api.subscriptions.updateSubscription.mutate({
    workspaceId,
    priceId,
    promotionCodeId,
    trialPeriodDays,
  });
  if (res?.checkoutRedirectUrl) {
    window.open(res.checkoutRedirectUrl, '_self');
  }
};

export const cancelSubscription = async (workspaceId: string) => {
  return await api.subscriptions.cancelSubscription.mutate({ workspaceId });
};

export const getBillingPortalLink = (workspaceId?: string) => {
  return api.subscriptions.billingPortalLink.mutate({ workspaceId });
};

export const applyPromotionCode = async (
  workspaceId: string,
  promotionCode: string,
) => {
  try {
    return await api.subscriptions.applyPromotionCode.mutate({
      workspaceId,
      promotionCode,
    });
  } catch (e) {
    showError(e as Error);
    throw e;
  }
};

export const getWorkspaceSubscription = (workspaceId: string) => {
  return api.subscriptions.getWorkspaceSubscription.query({ workspaceId });
};
