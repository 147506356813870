import { useCurrentWorkspaceId } from '@/auth/use-workspace';
import { useStorage } from '@vueuse/core/index';
import { getWeek, getDay } from 'date-fns';
import { computed } from 'vue';

const TUESDAY = 2;
const WEDNESDAY = 3;

export const useFreeSeatsStorage = () =>
  useStorage<Record<string, number>>(`free-seats-show`, {});

export const useFreeSeatsTime = () => {
  const workspaceId = useCurrentWorkspaceId();
  const seats = useFreeSeatsStorage();

  return computed(() => seats.value[workspaceId.value] ?? 0);
};

export const useIsFreeSeatsPopupTime = () => {
  const prevTimestamp = useFreeSeatsTime();
  const now = Date.now();

  return computed(() => {
    if (!Number.isInteger(prevTimestamp.value) || prevTimestamp.value === 0) {
      return true;
    }

    const prevWeek = getWeek(prevTimestamp.value);
    const thisWeek = getWeek(now);

    return (
      thisWeek > prevWeek + 1 ||
      (thisWeek === prevWeek + 1 && getDay(now) > TUESDAY)
    );
  });
};

export const useDiscountTimeStorage = () =>
  useStorage<Record<string, number>>(`y-discount`, {});

const useDiscountTime = () => {
  const workspaceId = useCurrentWorkspaceId();
  const discounts = useDiscountTimeStorage();

  return computed(() => discounts.value[workspaceId.value] ?? 0);
};

export const useIsDiscountPopupTime = () => {
  const prevTimestamp = useDiscountTime();
  const now = Date.now();

  return computed(() => {
    if (!Number.isInteger(prevTimestamp.value) || prevTimestamp.value === 0) {
      return true;
    }
    const prevWeek = getWeek(prevTimestamp.value);
    const thisWeek = getWeek(now);

    return (
      thisWeek > prevWeek + 2 ||
      (thisWeek === prevWeek + 2 && getDay(now) > WEDNESDAY)
    );
  });
};
