import { ref, Ref, ComputedRef, computed } from 'vue';
import {
  useCurrentWorkspace,
  useWorkspaces,
  useSetCurrentWorkspaceId,
} from '@/auth/use-workspace';
import { Workspace } from '@/api/types';
import { useUser } from '@/auth/use-session';

interface WorkspacesMenuHook {
  isDropdownOpen: Ref<boolean>;
  areWorkspacesLoading: Ref<boolean>;
  allWorkspaces: Ref<Workspace[] | undefined>;
  workspaces: ComputedRef<Workspace[]>;
  currentWorkspace: Ref<Workspace | undefined>;
  primaryWorkspaceId: ComputedRef<string>;
  changeCurrentWorkspace: (workspaceId: string) => void;
}

const isDropdownOpen = ref(false);

export const useWorkspacesMenu = (): WorkspacesMenuHook => {
  const { data: user } = useUser();
  const { data: allWorkspaces, isLoading: areWorkspacesLoading } =
    useWorkspaces();
  const { data: currentWorkspace } = useCurrentWorkspace();

  const primaryWorkspaceId = computed(
    () => user.value?.primaryAccount?.workspaceId ?? '',
  );
  const workspaces = computed(
    () =>
      allWorkspaces.value?.filter(
        ({ id }) => id !== currentWorkspace.value?.id,
      ) ?? [],
  );

  const setCurrentWorkspaceId = useSetCurrentWorkspaceId();
  const changeCurrentWorkspace = (workspaceId: string) => {
    setCurrentWorkspaceId(workspaceId);
    isDropdownOpen.value = false;
  };

  return {
    isDropdownOpen,
    areWorkspacesLoading,
    allWorkspaces,
    workspaces,
    currentWorkspace,
    primaryWorkspaceId,
    changeCurrentWorkspace,
  };
};
