import { ref, Ref, ComputedRef, computed } from 'vue';
import {
  useCurrentConversationAssists,
  useGenerateAssistForCurrentConversation,
} from '@/api/assists';
import { AIAssistType } from '@/components/conversation/AIAssistant';
import { AssistsType } from '@/api/types';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';
import { useTodos } from '.';

type GeneratableAIAssistType = Exclude<AIAssistType, 'RECAP'>;

interface ManageAssistsHook {
  assist: ComputedRef<AssistsType | undefined>;
  isAssistLoading: Ref<boolean>;
  isAssistGenerating: Ref<boolean>;
  generateAssist: () => Promise<void>;
}

const isAssistGenerating = ref(false);

const useGenerateAssist =
  (
    type: GeneratableAIAssistType,
    generateAssistMutation: ReturnType<
      typeof useGenerateAssistForCurrentConversation
    >['mutateAsync'],
    refetchTodos: ReturnType<typeof useTodos>['refetchTodos'],
  ) =>
  async (): Promise<void> => {
    try {
      isAssistGenerating.value = true;
      await generateAssistMutation(type);

      /**
       * Todos are generated in an async queue,
       * so we might need to refetch them multiple times
       *
       * @todo Rethink the logic, async todos generation
       * is not the best option, since the users expect the todos
       * to be generated instantly
       */
      let refetchCount = 1;
      do {
        const { data: todos } = await refetchTodos();
        if (todos?.length) {
          break;
        }

        refetchCount++;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } while (refetchCount < 5);
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isAssistGenerating.value = false;
    }
  };

export const useManageAIAssist = (
  type: GeneratableAIAssistType,
): ManageAssistsHook => {
  const { data: assists, isLoading: isAssistLoading } =
    useCurrentConversationAssists(type);
  const assist = computed(() => assists.value?.[0]);

  const { mutateAsync: generateAssistMutation } =
    useGenerateAssistForCurrentConversation();
  const { refetchTodos } = useTodos();
  const generateAssist = useGenerateAssist(
    type,
    generateAssistMutation,
    refetchTodos,
  );

  return {
    assist,
    isAssistLoading,
    isAssistGenerating,
    generateAssist,
  };
};
