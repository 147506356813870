import {
  computed,
  ComputedRef,
  ref,
  Ref,
  UnwrapRef,
  watch,
  onBeforeMount,
  onUnmounted,
} from 'vue';
import { RecycleScrollerInstance } from 'vue-virtual-scroller';
import { intervalToDuration } from 'date-fns';
import { useCurrentConversationBlocks } from '@/api/blocks';
import { ReturnedBlock, BlockType } from '@/api/types';
import { CurrentConversationHook, useCurrentConversation } from '.';

export interface TranscriptItem {
  id: string;
  text: string;
  originalText: string;
  time: string;
  highlightId?: string;
  isHighlighted: boolean;
  isSearched: boolean;
  speaker: {
    id: string;
    name: string;
    avatar?: string;
  };
}

interface TranscriptData {
  items: TranscriptItem[];
  searchable: number[];
}

export interface TranscriptHook
  extends Pick<
    CurrentConversationHook,
    'conversation' | 'isConversationLoading'
  > {
  scroller: Ref<RecycleScrollerInstance | null>;
  search: Ref<string>;
  searchCursor: Ref<number>;
  scrollPrev: () => void;
  scrollNext: () => void;
  highlightsOnly: Ref<boolean>;
  toggleHighlightsOnly: () => void;
  transcript: Ref<TranscriptItem[]>;
  searchables: Ref<number[]>;
  isTranscriptLoading: ComputedRef<boolean>;
}

/**
 * Should be provided as a ref to the RecycleScroller component
 */
const scroller = ref<UnwrapRef<TranscriptHook['scroller']>>(null);

const search = ref('');
const searchCursor = ref(0);
const highlightsOnly = ref(false);

const transcript = ref<TranscriptItem[]>([]);
const searchables = ref<number[]>([]);

const toggleHighlightsOnly = (): void => {
  highlightsOnly.value = !highlightsOnly.value;
};

const useGetBlockTime =
  (conversationTime: ComputedRef<string | undefined>) =>
  (blockTime: ReturnedBlock['startTime']): string => {
    if (!conversationTime.value) {
      return '';
    }

    const { minutes = 0, seconds = 0 } = intervalToDuration({
      start: new Date(conversationTime.value),
      end: new Date(blockTime),
    });

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

const markText = (
  text: string,
): Pick<TranscriptItem, 'text' | 'isSearched'> => {
  let isSearched = false;

  if (!search.value) {
    return { text, isSearched };
  }

  const maybeMarkedText = text.replace(
    new RegExp(search.value, 'gi'),
    (match) => {
      isSearched = true;
      return `<mark>${match}</mark>`;
    },
  );

  return { text: maybeMarkedText, isSearched };
};

const useMapBlockToTranscriptItem =
  (getBlockTime: (blockTime: ReturnedBlock['startTime']) => string) =>
  (block: ReturnedBlock): TranscriptItem => {
    const { id, participant, text, type, startTime } = block;

    let maybeMarkedText = text ?? '';
    let isSearched = false;

    if (search.value && !highlightsOnly.value) {
      const result = markText(maybeMarkedText);
      maybeMarkedText = result.text;
      isSearched = result.isSearched;
    }

    return {
      id,
      text: maybeMarkedText,
      originalText: text ?? '',
      time: getBlockTime(startTime),
      isHighlighted: type === BlockType.HIGHLIGHT,
      isSearched,
      speaker: {
        id: participant?.id ?? '',
        name: participant?.person.name ?? participant?.person.email ?? '',
        avatar: participant?.person.avatarUrl ?? undefined,
      },
    };
  };

const useComputeTranscriptData =
  (
    blocks: Ref<ReturnedBlock[] | undefined>,
    mapBlockToTranscriptItem: (block: ReturnedBlock) => TranscriptItem,
  ) =>
  (): void => {
    const itemsMap = new Map<ReturnedBlock['id'], TranscriptItem>();
    const searchedList = new Set<TranscriptItem['id']>();

    (blocks.value ?? []).forEach((block) => {
      if (block.type !== BlockType.HIGHLIGHT) {
        const item = mapBlockToTranscriptItem(block);
        itemsMap.set(item.id, item);
        item.isSearched && searchedList.add(item.id);
        return;
      }

      const parent = itemsMap.get(block.parentId ?? '');
      if (parent) {
        const { text, isSearched } = markText(parent.originalText);

        Object.assign(parent, {
          isHighlighted: true,
          highlightId: block.id,
          text,
          isSearched: isSearched || (highlightsOnly.value && !search.value),
        });
      }
    });

    const items: TranscriptData['items'] = [];
    const searchablesList: TranscriptData['searchable'] = [];
    for (const item of itemsMap.values()) {
      items.push(item);
      item.isSearched && searchablesList.push(items.length - 1);
    }

    transcript.value = items;
    searchables.value = searchablesList;
  };

const scrollPrev = (): void => {
  if (scroller.value) {
    searchCursor.value =
      searchCursor.value === 0
        ? searchables.value.length - 1
        : searchCursor.value - 1;

    scroller.value.scrollToItem(searchables.value[searchCursor.value]);
  }
};

const scrollNext = (): void => {
  if (scroller.value) {
    searchCursor.value = (searchCursor.value + 1) % searchables.value.length;
    scroller.value.scrollToItem(searchables.value[searchCursor.value]);
  }
};

export const useTranscript = (): TranscriptHook => {
  const { conversation, isConversationLoading } = useCurrentConversation();
  const { data: blocks, isLoading: areBlocksLoading } =
    useCurrentConversationBlocks();

  const isTranscriptLoading = computed(
    () => isConversationLoading.value || areBlocksLoading.value,
  );

  const conversationTime = computed(() => conversation.value?.startTime);
  const getBlockTime = useGetBlockTime(conversationTime);
  const mapBlockToTranscriptItem = useMapBlockToTranscriptItem(getBlockTime);

  const computeTranscriptData = useComputeTranscriptData(
    blocks,
    mapBlockToTranscriptItem,
  );

  /**
   * @summary Recompute transcript data to mark new search results.
   * Reset cursor and scroll to the first search result
   * if scroller is available and search or highlights are active
   */
  watch([blocks, search, highlightsOnly], () => {
    computeTranscriptData();
    searchCursor.value = 0;
    if (scroller.value && (search.value || highlightsOnly.value)) {
      scroller.value.scrollToItem(searchables.value[0]);
    }
  });

  onBeforeMount(computeTranscriptData);
  onUnmounted(() => {
    search.value = '';
    highlightsOnly.value = false;
  });

  return {
    conversation,
    isConversationLoading,
    scroller,
    search,
    searchCursor,
    scrollPrev,
    scrollNext,
    highlightsOnly,
    toggleHighlightsOnly,
    transcript,
    searchables,
    isTranscriptLoading,
  };
};
