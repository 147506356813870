import {
  LanguageTitleMap,
  LangCode,
} from 'trpc_backend/src/domain/conversations/languages';

export const CONVERSATION_LANGUAGES: {
  name: string;
  value: LangCode;
  beta?: boolean;
}[] = Object.entries(LanguageTitleMap).map(([code, name]) => ({
  name,
  value: code as LangCode,
  beta: name.endsWith('(beta)'),
}));
