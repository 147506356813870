import { supabase } from '@/auth/supabase';
import {
  createTRPCProxyClient,
  splitLink,
  httpLink,
  httpBatchLink,
} from '@trpc/client';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import type { Router } from 'trpc_backend/src/trpc/router';

const url = import.meta.env.VITE_TRPC_BACKEND_URL as string;

const headers = async () => {
  const accessToken = await supabase.auth
    .getSession()
    .then(({ data: { session } }) => session?.access_token);
  return accessToken ? { authorization: `Bearer ${accessToken}` } : {};
};

/** @see https://trpc.io/docs/links/httpBatchLink#disabling-request-batching */
export const api = createTRPCProxyClient<Router>({
  links: [
    splitLink({
      condition(op) {
        /* check for context property 'skipBatch' */
        return op.context.skipBatch === true;
      },
      /* when condition is true, use normal request: */
      true: httpLink({ url, headers }),
      /* when condition is false, use batching: */
      false: httpBatchLink({ url, headers }),
    }),
  ],
});

export type InputTypes = inferRouterInputs<Router>;
export type OutputTypes = inferRouterOutputs<Router>;
