<script setup lang="ts">
import { ChevronLeftIcon } from 'vue-tabler-icons';
import { useSidebarSize } from '../hooks';

const { isCollapsed, toggle } = useSidebarSize();
</script>

<template>
  <div class="flex items-center justify-between">
    <RouterLink to="/">
      <img
        src="https://storage.googleapis.com/noty-ai/webapp/images/noty-full-logo.svg"
        alt="Noty.ai"
        class="w-[67px] h-auto"
      />
    </RouterLink>
    <Icon
      :src="ChevronLeftIcon"
      class="w-5 h-5 cursor-pointer"
      :class="{
        'rotate-180': isCollapsed,
        // TODO: Unhide the icon when mobile adaptation is ready
        invisible: true,
      }"
      @click="toggle"
    />
  </div>
</template>
