interface ExternalResource {
  id: string;
  href?: string;
  innerText?: string;
  attrs?: Record<string, string>;
}

export const useExternalResources = (
  props: ExternalResource,
): (() => void)[] => {
  const script: HTMLScriptElement = document.createElement('script');
  script.type = 'text/javascript';
  script.id = props.id;
  if (props.href) {
    script.src = props.href;
  }
  if (props.innerText) {
    script.innerText = props.innerText;
  }
  if (props.attrs) {
    for (const [key, value] of Object.entries(props.attrs)) {
      script.setAttribute(key, value);
    }
  }

  const loadResource = () => {
    document.body.appendChild(script);
  };

  const unLoadResource = () => {
    const externalResource = document.getElementById(props.id);
    externalResource?.parentNode?.removeChild(externalResource);
  };

  return [loadResource, unLoadResource];
};
