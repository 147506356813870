import { ref, Ref } from 'vue';
import { differenceBy } from 'lodash';
import { Folder, SpaceRole } from '@/api/types';
import { getRandomFolderName } from '@/api/spaces';
import { defaultSpaceColor } from '@/constants/spaceColors';
import {
  useAddFolderAccessors,
  useCreateFolder,
  useDeleteFolder,
  useUpdateFolder,
  useUpdateFolderAccessorRole,
  useRemoveFolderAccessor,
} from '@/api/folders';
import { showError } from '@/utils/alert';
import { isStringOrError } from '@/utils';

interface FolderAccessor {
  accountId: string;
  userId: string;
  role: SpaceRole;
  name: string;
  email: string;
  avatarUrl: string;
}

interface FolderAccessorsCateforized {
  addedAccessors: Pick<FolderAccessor, 'email' | 'role'>[];
  updatedAccessors: Pick<FolderAccessor, 'accountId' | 'role'>[];
  removedAccessors: Pick<FolderAccessor, 'accountId'>[];
}

interface UpdateFolderAccessesHook {
  addFolderAccessorsMutation: ReturnType<
    typeof useAddFolderAccessors
  >['mutateAsync'];
  updateFolderAccessorRoleMutation: ReturnType<
    typeof useUpdateFolderAccessorRole
  >['mutateAsync'];
  removeFolderAccessorMutation: ReturnType<
    typeof useRemoveFolderAccessor
  >['mutateAsync'];
}

interface SaveFolderHook {
  createFolderMutation: ReturnType<typeof useCreateFolder>['mutateAsync'];
  updateFolderMutation: ReturnType<typeof useUpdateFolder>['mutateAsync'];
  updateFolderAccessesMutation: () => Promise<void>;
}

interface FolderData extends Pick<Folder, 'name' | 'color'> {
  id?: string;
  accessors: FolderAccessor[];
}

interface ManageFolderHook {
  isOpen: Ref<boolean>;
  isDeleteConfirmationOpen: Ref<boolean>;
  shouldDeleteConversations: Ref<boolean>;
  isDeleting: Ref<boolean>;
  isSaving: Ref<boolean>;
  isActionInProgress: Ref<boolean>;
  selectedFolder: Ref<Folder | null>;
  folderData: Ref<FolderData>;
  open: (folder?: Folder) => void;
  close: () => void;
  openDeleteConfirmation: () => void;
  closeDeleteConfirmation: () => void;
  save: () => Promise<void>;
  deleteFolder: (deleteConversations?: boolean) => Promise<void>;
  addAccessorToData: (email: string) => void;
  removeAccessorFromData: (accountId: string) => void;
}

const generateEmptyData = (): FolderData => ({
  name: getRandomFolderName(),
  color: defaultSpaceColor(),
  accessors: [],
});

const isOpen = ref(false);
const isDeleteConfirmationOpen = ref(false);
const shouldDeleteConversations = ref(false);
const isDeleting = ref(false);
const isSaving = ref(false);
const isActionInProgress = ref(false);
const selectedFolder = ref<Folder | null>(null);
const folderData = ref<FolderData>(generateEmptyData());

const mapFolderToFolderData = ({
  id,
  name,
  color,
  spaceAccesses,
}: Folder): FolderData => ({
  id,
  name,
  color,
  accessors: spaceAccesses.map(
    ({
      accountId,
      role,
      account: {
        userId,
        person: { name, email, avatarUrl },
      },
    }) => ({
      accountId,
      role: role as SpaceRole,
      userId: userId ?? '',
      name: name ?? '',
      email: email ?? '',
      avatarUrl: avatarUrl ?? '',
    }),
  ),
});

const open = (folder?: Folder): void => {
  if (folder) {
    selectedFolder.value = folder;
    folderData.value = mapFolderToFolderData(folder);
  } else {
    selectedFolder.value = null;
    folderData.value = generateEmptyData();
  }
  isOpen.value = true;
};

const close = (): void => {
  isOpen.value = false;
};

const addAccessorToData = (email: string): void => {
  const existingAccessor = folderData.value.accessors.find(
    (accessor) => accessor.email === email,
  );
  if (existingAccessor) {
    return;
  }

  folderData.value.accessors.push({
    accountId: '',
    userId: '',
    role: SpaceRole.MEMBER,
    name: '',
    email,
    avatarUrl: '',
  });
};

const removeAccessorFromData = (accountId: string): void => {
  folderData.value.accessors = folderData.value.accessors.filter(
    (accessor) => accessor.accountId !== accountId,
  );
};

const openDeleteConfirmation = (): void => {
  isOpen.value = false;
  isDeleteConfirmationOpen.value = true;
};

const closeDeleteConfirmation = (): void => {
  isOpen.value = false;
  isDeleteConfirmationOpen.value = false;
};

const categorizeAccessors = (): FolderAccessorsCateforized => {
  const { accessors: updateAccessors } = folderData.value;
  const { spaceAccesses: existingAccesses = [] } = selectedFolder.value ?? {};

  const removedAccessors = differenceBy(
    existingAccesses,
    updateAccessors,
    'accountId',
  );

  const addedAccessors: FolderAccessorsCateforized['addedAccessors'] = [];
  const updatedAccessors: FolderAccessorsCateforized['updatedAccessors'] = [];
  updateAccessors.forEach((accessor) => {
    const existingAccessor = existingAccesses.find(
      (existing) => existing.accountId === accessor.accountId,
    );
    if (!existingAccessor) {
      addedAccessors.push({
        email: accessor.email,
        role: accessor.role,
      });
    } else if (existingAccessor.role !== accessor.role) {
      updatedAccessors.push({
        accountId: accessor.accountId,
        role: accessor.role,
      });
    }
  });

  return {
    addedAccessors,
    updatedAccessors,
    removedAccessors,
  };
};

const useUpdateFolderAccesses =
  ({
    addFolderAccessorsMutation,
    updateFolderAccessorRoleMutation,
    removeFolderAccessorMutation,
  }: UpdateFolderAccessesHook) =>
  async (): Promise<void> => {
    const spaceId = selectedFolder.value?.id;
    if (!spaceId) {
      throw new Error('Folder is not provided');
    }

    const { addedAccessors, updatedAccessors, removedAccessors } =
      categorizeAccessors();

    removedAccessors.length &&
      (await Promise.all(
        removedAccessors.map(({ accountId }) =>
          removeFolderAccessorMutation({ spaceId, accountId }),
        ),
      ));
    updatedAccessors.length &&
      (await Promise.all(
        updatedAccessors.map(({ accountId, role }) =>
          updateFolderAccessorRoleMutation({ spaceId, accountId, role }),
        ),
      ));
    addedAccessors.length &&
      (await addFolderAccessorsMutation({ spaceId, invites: addedAccessors }));
  };

const useSave =
  ({
    createFolderMutation,
    updateFolderMutation,
    updateFolderAccessesMutation,
  }: SaveFolderHook) =>
  async () => {
    try {
      isSaving.value = true;
      isActionInProgress.value = true;

      const hasFolderDataChanged =
        selectedFolder.value?.name !== folderData.value.name ||
        selectedFolder.value?.color !== folderData.value.color;

      if (selectedFolder.value) {
        hasFolderDataChanged &&
          (await updateFolderMutation({
            spaceId: selectedFolder.value.id,
            spaceData: {
              name: folderData.value.name,
              color: folderData.value.color,
            },
          }));
        await updateFolderAccessesMutation();
      } else {
        await createFolderMutation(folderData.value);
      }

      close();
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isSaving.value = false;
      isActionInProgress.value = false;
    }
  };

const useDelete =
  (deleteFolder: ReturnType<typeof useDeleteFolder>['mutateAsync']) =>
  async (deleteConversations: boolean = false) => {
    if (!selectedFolder.value) {
      return;
    }

    try {
      isActionInProgress.value = true;
      shouldDeleteConversations.value = deleteConversations;
      isDeleting.value = true;
      await deleteFolder({
        spaceId: selectedFolder.value.id,
        deleteConversations,
      });
      closeDeleteConfirmation();
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isActionInProgress.value = false;
      shouldDeleteConversations.value = false;
      isDeleting.value = false;
    }
  };

export const useManageFolder = (): ManageFolderHook => {
  const { mutateAsync: createFolderMutation } = useCreateFolder();
  const { mutateAsync: updateFolderMutation } = useUpdateFolder();
  const { mutateAsync: deleteFolderMutation } = useDeleteFolder();
  const { mutateAsync: addFolderAccessorsMutation } = useAddFolderAccessors();
  const { mutateAsync: updateFolderAccessorRoleMutation } =
    useUpdateFolderAccessorRole();
  const { mutateAsync: removeFolderAccessorMutation } =
    useRemoveFolderAccessor();

  const updateFolderAccesses = useUpdateFolderAccesses({
    addFolderAccessorsMutation,
    updateFolderAccessorRoleMutation,
    removeFolderAccessorMutation,
  });

  const save = useSave({
    createFolderMutation,
    updateFolderMutation,
    updateFolderAccessesMutation: updateFolderAccesses,
  });

  const deleteFolder = useDelete(deleteFolderMutation);

  return {
    isOpen,
    isDeleteConfirmationOpen,
    shouldDeleteConversations,
    isDeleting,
    isSaving,
    isActionInProgress,
    selectedFolder,
    folderData,
    open,
    close,
    openDeleteConfirmation,
    closeDeleteConfirmation,
    save,
    deleteFolder,
    addAccessorToData,
    removeAccessorFromData,
  };
};
