<script setup lang="ts">
import { VideoIcon } from 'vue-tabler-icons';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { MenuLink } from '@/api/types';
import { FoldersDropdown, SidebarItem } from './components';

const links: MenuLink[] = [
  {
    title: 'My Meetings',
    icon: VideoIcon,
    to: { name: ROUTE_NAMES.HOME },
  },
];
</script>

<template>
  <div class="flex flex-col gap-y-2">
    <SidebarItem
      v-for="{ title, icon, to } in links"
      :key="title"
      :title="title"
      :to="to"
    >
      <template #prepend>
        <Icon :src="icon" class="h-5 w-5" />
      </template>
    </SidebarItem>
    <FoldersDropdown />
  </div>
</template>
