import { SpaceRole, AccountRole } from '@/api/types';

export type MemberOption = {
  value: AccountRole;
  name: string;
  description: string;
  disabled?: boolean;
};

export type FolderMemberOption = {
  value: SpaceRole;
  name: string;
  description: string;
};

export const MEMBER_ROLES_OPTIONS: MemberOption[] = [
  {
    value: AccountRole.ADMIN,
    name: 'Admin',
    description:
      'A paid member with extra workspace control: invite, upgrade, remove members, assign and revoke roles.',
  },
  {
    value: AccountRole.MEMBER,
    name: 'Member',
    description: 'A paid member with full access to premium plan features.',
  },
  {
    value: AccountRole.LITE,
    name: 'Lite',
    description:
      'A free member has access only to the basic features of the Free plan.',
  },
];

export const FOLDER_MEMBER_ROLES_OPTIONS: FolderMemberOption[] = [
  {
    value: SpaceRole.ADMIN,
    name: 'Admin',
    description: 'Can manage settings and invite new members',
  },
  {
    value: SpaceRole.MEMBER,
    name: 'Member',
    description: 'Can NOT manage settings or invite new members',
  },
];

export const DEFAULT_MEMBER_ROLE: AccountRole | SpaceRole = AccountRole.MEMBER;
export const DEFAULT_FREE_MEMBER_ROLE: AccountRole | SpaceRole =
  AccountRole.LITE;

export const getDefaultMemberRole = (isFreeWorkspace: boolean) =>
  isFreeWorkspace ? DEFAULT_FREE_MEMBER_ROLE : DEFAULT_MEMBER_ROLE;
