<script setup lang="ts">
import { useIdentity, useSession } from '@/auth/use-session';
import { isRouterReady } from '@/router/use-is-router-ready';
import { store, resolveConfirmPopup } from '@/store';
import Layout from '@/components/layouts/Layout.vue';
import { useIntercom } from '@/utils/analytics/use-intercom';

const isReady = isRouterReady();
useIdentity();
useIntercom();

const { isFetched } = useSession();
</script>

<template>
  <template v-if="isFetched && isReady">
    <router-view v-if="$route.meta.noLayout" v-slot="{ Component }">
      <Suspended timeout="0">
        <component :is="Component" />
      </Suspended>
    </router-view>

    <Suspended v-else>
      <v-app class="bg-background-window">
        <Layout>
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </Layout>
      </v-app>
    </Suspended>
  </template>
  <Loader v-else />
  <ConfirmPopup
    v-if="store.confirmPopup.isShown"
    :dialog="store.confirmPopup.dialog"
    :options="store.confirmPopup.options"
    @answer="resolveConfirmPopup"
  />
</template>
