import { randomInteger } from '@/utils';

export const SPACE_COLORS = [
  'orange',
  'amber',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'pink',
  'rose',
];

export function defaultSpaceColor() {
  return SPACE_COLORS[randomInteger(0, SPACE_COLORS.length - 1)];
}
