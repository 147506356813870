import { ref, computed } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { api } from '@/api';
import {
  type AssistsType,
  AIAssistType,
  AIAssistStatus,
  CreateAssistDto,
} from '@/api/types';
import { useCurrentConversationId } from '@/store/hooks';
import { MaybeArray } from '@/utils/types';

export const getConversationAssists = (conversationId: string) => {
  return api.assists.getForConversation.query({ conversationId });
};

export const createConversationAssist = (
  conversationId: string,
  type: Exclude<AssistsType['type'], 'RECAP'>,
) => {
  return api.assists.create.mutate({ conversationId, type });
};

export const getConversationAssist = (
  conversationId: string,
  type: Exclude<AssistsType['type'], 'RECAP'>,
) => {
  return api.assists.getForConversation.query({ conversationId, type });
};

export const getAssistBlocks = (
  conversationId: string,
  type: Exclude<AssistsType['type'], 'TODO_ITEMS'>,
) => {
  return api.blocks.getAssistBlocks.query({ conversationId, type });
};

export const useCurrentConversationAssists = (
  type: MaybeArray<Exclude<AIAssistType, 'RECAP'>>,
) => {
  const conversationId = useCurrentConversationId();
  const refetchInterval = ref<number | false>(false);

  return useQuery({
    enabled: computed(() => !!conversationId.value),
    queryKey: [
      'conversation_assist',
      conversationId,
      ...(Array.isArray(type) ? type : [type]),
    ],
    queryFn: async () => {
      const assists = await api.assists.getForConversation.query({
        conversationId: conversationId.value!,
        type,
      });

      if (assists.some(({ status }) => status === AIAssistStatus.PENDING)) {
        refetchInterval.value = 5000;
      } else {
        refetchInterval.value = false;
      }

      return assists;
    },
    refetchInterval,
  });
};

export const useGenerateAssistForCurrentConversation = () => {
  const conversationId = useCurrentConversationId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (type: CreateAssistDto['type']) => {
      if (!conversationId.value) {
        throw new Error('Conversation id is required');
      }

      return api.assists.create.mutate({
        conversationId: conversationId.value,
        type,
      });
    },
    onSuccess: (data, assistType) =>
      queryClient.invalidateQueries([
        'conversation_assist',
        conversationId,
        assistType,
      ]),
  });
};
