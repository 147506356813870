import { Pricing } from '@/api/types';

import { useCurrentWorkspace } from '@/auth/use-workspace';
import { computed } from 'vue';

export const useIsPersonalPlan = () => {
  const { data: data } = useCurrentWorkspace();

  return computed(() => {
    if (!data.value) {
      return false;
    }
    return data.value.pricing === Pricing.PERSONAL;
  });
};
