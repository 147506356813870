<script setup lang="ts">
import { reactive, computed, watch, ref } from 'vue';
import { XIcon, MailIcon, CheckIcon } from 'vue-tabler-icons';
import { isEmail } from '@/utils';
import { showMessage } from '@/utils/alert';
import { AccountRole, ParticipantPerson } from '@/api/types';
import {
  MemberOption,
  FolderMemberOption,
  MEMBER_ROLES_OPTIONS,
  getDefaultMemberRole,
} from '@/constants/dropdownOptions';
import { useIsPersonalPlan } from '@/store/hooks';

const emit = defineEmits<{
  (e: 'update:modelValue', value: Set<string>): void;
  (e: 'textInput', test: string): void;
  (
    e: 'sendInvites',
    invites: {
      email: string;
      role: AccountRole.MEMBER | AccountRole.LITE;
    }[],
  ): void;
}>();

const props = withDefaults(
  defineProps<{
    modelValue?: Set<string>;
    suggestions?: ParticipantPerson[];
    existingEmails?: string[];
    isPure?: boolean;
    inputClass?: string;
    suggestionsLabel?: string;
    placeholder?: string;
    validate?: (str: string) => boolean;
    validationLabel?: string;
    disabled?: boolean;
    rolesSet?: MemberOption[] | FolderMemberOption[];
  }>(),
  {
    modelValue: () => new Set(),
    suggestions: () => [],
    existingEmails: () => [],
    suggestionsLabel: 'Workspace members',
    placeholder: 'Type emails to invite new teammates',
    validate: isEmail,
    validationLabel: 'New member',
    rolesSet: () => MEMBER_ROLES_OPTIONS,
  },
);

const isFreeWorkspace = useIsPersonalPlan();
const defaultRole = computed(() => getDefaultMemberRole(isFreeWorkspace.value));

const state = reactive({
  input: '',
  isInputFocused: false,
  role: defaultRole.value,
  emails: props.modelValue,
});

const isInputBeingEmail = computed(() => {
  const input = state.input.trim();
  const isInputBeingEmail = props.validate(input);

  if (!isInputBeingEmail) {
    return false;
  }

  const isInputExistsInSuggestions = props.suggestions.some(
    ({ person }) => person.email === input,
  );

  return isInputExistsInSuggestions ? false : true;
});
const areSuggestionsShown = computed(
  () =>
    isInputBeingEmail.value ||
    (state.isInputFocused && !!props.suggestions.length),
);

watch(
  () => props.modelValue,
  () => (state.emails = props.modelValue),
  { deep: true },
);
watch(
  () => state.emails,
  () => emit('update:modelValue', state.emails),
  { deep: true },
);

const addEmail = () => {
  if (!isInputBeingEmail.value) {
    return;
  }
  const email = state.input.trim().toLowerCase();
  if (props.existingEmails.includes(email)) {
    showMessage(`The ${email} has been already used`, {
      type: 'warning',
    });
  } else {
    state.emails.add(email);
  }
  state.input = '';
};

const deleteEmail = (email: string) => {
  state.emails.delete(email);
};

const input = ref(null);
const sendInvites = () => {
  if (!Array.from(state.emails).length) {
    input.value?.$el?.querySelector('input')?.focus();
    showMessage('Add at least one email');
    return;
  }

  const invites = Array.from(state.emails).map((email: string) => ({
    email: email,
    role: state.role,
  }));
  emit('sendInvites', invites);
  state.emails.clear();
};

const focusHandler = () => {
  state.isInputFocused = true;
};

const blurHandler = () => {
  state.isInputFocused = false;
  state.input = '';
  emit('textInput', '');
};
</script>

<template>
  <div class="flex" :class="{ 'cursor-not-allowed': props.disabled }">
    <Collapse
      :is-open="areSuggestionsShown"
      :accordion="false"
      class="container"
    >
      <template #header>
        <div
          class="p-1.5 pl-3 border border-solid border-slate-100 bg-white rounded-lg flex justify-between items-center gap-4"
          :class="[inputClass, { 'border-primary': state.isInputFocused }]"
        >
          <div
            class="input-area"
            :class="{ 'pointer-events-none': props.disabled }"
          >
            <p
              v-for="email in state.emails"
              :key="email"
              class="input-area__badge"
              @mousedown.prevent
            >
              <span>{{ email }}</span>
              <Icon
                class="cursor-pointer hover:text-red-500"
                size="16"
                :src="XIcon"
                @click="deleteEmail(email)"
              />
            </p>
            <div class="inline-block flex-grow min-w-56">
              <Input
                ref="input"
                v-model="state.input"
                class="input-area__input"
                data-testid="invite-teammates"
                :placeholder="props.placeholder"
                @keyup.enter="addEmail"
                @focus="focusHandler"
                @blur="blurHandler"
                @update:model-value="
                  (value: string) => $emit('textInput', value)
                "
              />
            </div>
          </div>
          <Dropdown
            v-if="!props.isPure"
            v-model="state.role"
            small
            :class="{ 'pointer-events-none': props.disabled }"
            :options="props.rolesSet"
          />
          <div v-if="!props.isPure" class="shrink-0 relative">
            <v-btn
              variant="flat"
              color="primary"
              data-testid="invite-button"
              @click="sendInvites"
            >
              Invite
            </v-btn>
          </div>
        </div>
      </template>
      <template #content>
        <div class="mt-1 py-1 px-3 shadow-rude rounded-2xl bg-white">
          <div v-if="isInputBeingEmail" class="w-full inline-block mb-1">
            <label class="mb-2">{{ props.validationLabel }}</label>
            <Button
              class="btn--suggestion"
              @mousedown.prevent
              @click="addEmail"
            >
              <Icon :src="MailIcon" size="16" />
              <span class="text-xs">{{ state.input.toLowerCase() }}</span>
            </Button>
          </div>
          <div v-if="suggestions.length" class="w-full my-1 mb-1 inline-block">
            <label class="mb-2" v-text="props.suggestionsLabel" />
            <Button
              v-for="member in suggestions"
              :key="member.id"
              class="btn--suggestion"
              @mousedown.prevent
              @click="
                member.person.email &&
                  (state.emails.has(member.person.email)
                    ? state.emails.delete(member.person.email)
                    : state.emails.add(member.person.email))
              "
            >
              <Avatar :person="member.person" size="5" />
              <span class="text-xs">
                {{ member.person.name || member.person.email }}
              </span>
              <Icon
                v-show="
                  member.person.email && state.emails.has(member.person.email)
                "
                :src="CheckIcon"
                class="ml-auto text-primary"
              />
            </Button>
          </div>
        </div>
      </template>
    </Collapse>
  </div>
</template>

<style>
.input-area {
  @apply w-full flex items-center flex-wrap gap-1.5;
}

.input-area__input {
  @apply text-xs border-0 mr-2 p-0 h-7 bg-transparent !important;
}

.input-area__badge {
  @apply h-6 flex items-center gap-1 pl-1 pr-0.5 bg-slate-50 rounded border border-solid border-slate-200 text-xs text-slate-600 cursor-default hover:bg-white !important;
}

.btn--suggestion {
  @apply w-full my-2 justify-start p-3 flex items-center gap-4 cursor-pointer text-black bg-slate-50 rounded-lg hover:bg-slate-100 !important;
}

.btn--invite {
  @apply ml-2 py-2 px-4 rounded-lg text-xs font-semibold !important;
}
</style>
