import { ref, Ref, computed, ComputedRef } from 'vue';
import { useUserId } from '@/auth/use-session';
import { useWorkspaces } from '@/auth/use-workspace';
import {
  SpaceRole,
  ReturnedSortedWorkspaces,
  AvatarProps,
  Identifiable,
} from '@/api/types';
import { ArrayElement } from '@/utils/types';
import { useScheduleDeleteCurrentUser } from '@/api/auth';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface WorkspaceWithAccessors
  extends ArrayElement<ReturnedSortedWorkspaces> {
  accessors: Array<AvatarProps & Identifiable>;
}

type WorkspacesByRole = Record<SpaceRole, WorkspaceWithAccessors[]>;

interface DeleteAccountHook {
  isDeleteAccountPopupOpen: Ref<boolean>;
  isDeleteAccountConfirmationOpen: Ref<boolean>;
  isDeleteAccountSuccessOpen: Ref<boolean>;
  isDeleting: Ref<boolean>;
  workspacesByRole: ComputedRef<WorkspacesByRole>;
  closeAllPopups: () => void;
  openDeleteAccountPopup: () => void;
  openDeleteAccountConfirmation: () => void;
  openDeleteAccountSuccess: () => void;
  deleteAccount: () => Promise<void>;
}

const isDeleteAccountPopupOpen = ref(false);
const isDeleteAccountConfirmationOpen = ref(false);
const isDeleteAccountSuccessOpen = ref(false);
const isDeleting = ref(false);

const closeAllPopups = (): void => {
  isDeleteAccountConfirmationOpen.value = false;
  isDeleteAccountPopupOpen.value = false;
  isDeleteAccountSuccessOpen.value = false;
};

const openDeleteAccountPopup = (): void => {
  closeAllPopups();
  isDeleteAccountPopupOpen.value = true;
};

const openDeleteAccountConfirmation = () => {
  closeAllPopups();
  isDeleteAccountConfirmationOpen.value = true;
};

const openDeleteAccountSuccess = () => {
  closeAllPopups();
  isDeleteAccountSuccessOpen.value = true;
};

const useDelete =
  (
    deleteAccountMutation: ReturnType<
      typeof useScheduleDeleteCurrentUser
    >['mutateAsync'],
  ) =>
  async (): Promise<void> => {
    try {
      isDeleting.value = true;
      await deleteAccountMutation();
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isDeleting.value = false;
    }
  };

export const useDeleteAccount = (): DeleteAccountHook => {
  const userId = useUserId();
  const { data: workspaces } = useWorkspaces();

  const workspacesByRole = computed(() =>
    (workspaces.value ?? []).reduce<WorkspacesByRole>(
      (acc, workspace) => {
        const accountRole = workspace.accounts.find(
          (account) => account.userId === userId.value,
        )?.role;
        const role =
          accountRole === SpaceRole.ADMIN ? SpaceRole.ADMIN : SpaceRole.MEMBER;

        return {
          ...acc,
          [role]: [
            ...acc[role],
            {
              ...workspace,
              accessors: workspace.accounts.map(({ person, personId }) => ({
                ...person,
                id: personId,
              })),
            },
          ],
        };
      },
      { [SpaceRole.ADMIN]: [], [SpaceRole.MEMBER]: [] },
    ),
  );

  const { mutateAsync: deleteAccountMutation } = useScheduleDeleteCurrentUser();
  const deleteAccount = useDelete(deleteAccountMutation);

  return {
    isDeleteAccountPopupOpen,
    isDeleteAccountConfirmationOpen,
    isDeleteAccountSuccessOpen,
    isDeleting,
    workspacesByRole,
    closeAllPopups,
    openDeleteAccountPopup,
    openDeleteAccountConfirmation,
    openDeleteAccountSuccess,
    deleteAccount,
  };
};
