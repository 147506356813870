<script setup lang="ts">
import { AccountRole } from '@/api/types';
import { useUser } from '@/auth/use-session';
import {
  useCurrentWorkspace,
  useSetCurrentWorkspaceId,
  useWorkspaces,
} from '@/auth/use-workspace';
import DiscountNotification from '@/components/DiscountNotification.vue';
import NewYearWelcomePopup from '@/components/popups/NewYearWelcomePopup.vue';
import FreeSeatsPopup from '@/components/popups/FreeSeatsPopup.vue';
import MobileUnderDevelopmentPopup from '@/components/popups/MobileUnderDevelopmentPopup.vue';
import FreeTrialNotification from '@/components/FreeTrialNotification.vue';
import SidebarMenu from '@/components/SidebarMenu';
import { isString } from '@/utils';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useIsOnboardingProcess } from '@/utils/onboarding';
import { router } from '@/router';
import { ROUTE_NAMES } from '@/constants/routeNames';
import {
  ManageFolderPopup,
  DeleteFolderConfirmation,
  DeleteAccountPopup,
  DeleteAccountConfirmation,
  DeleteAccountSuccess,
  DeleteCurrentWorkspaceConfirmation,
  DeleteCurrentConversationConfirmation,
  LeaveCurrentWorkspaceConfirmation,
} from '@/components/popups';

const isOnboarding = useIsOnboardingProcess();
const { data: workspace } = useCurrentWorkspace();
const { isFetched: isUserFetched, data: user } = useUser();
const { isFetched: isWorkspacesFetched, data: workspaces } = useWorkspaces();

const loading = computed(() => {
  if (!isUserFetched.value || !isWorkspacesFetched.value) {
    // for public conversation we don't need to wait for user to be fetched
    return route.name !== ROUTE_NAMES.CONVERSATION;
  }
  if (!user.value?.primaryAccount && !workspaces.value?.length) {
    router.push({
      name: isOnboarding.value
        ? ROUTE_NAMES.ONBOARDING
        : ROUTE_NAMES.CREATE_WORKSPACE,
    });
    return true;
  }
  return false;
});

const EDIT_WS_PERMISSION = computed(() =>
  (workspace.value?.accounts || []).some((account) => {
    return (
      account.role === AccountRole.ADMIN && account.userId === user.value?.id
    );
  }),
);

const route = useRoute();
const sidebarMenu = ref<InstanceType<typeof SidebarMenu> | null>(null);

const isFreeTrialNotificationShown = computed(
  () =>
    EDIT_WS_PERMISSION.value &&
    workspace.value?.trialEnd &&
    user.value?.onboarding?.guides?.home &&
    !window.localStorage.getItem('DiscountNotification'),
);
const isDiscountNotificationShown = computed(
  () => user.value?.onboarding?.guides?.home,
);
const setWorkspaceId = useSetCurrentWorkspaceId();
watch(
  () => route.params.workspaceId,
  (routeId) => {
    if (isString(routeId) && routeId !== workspace.value?.id) {
      setWorkspaceId(routeId);
    }
  },
  { immediate: true },
);
</script>

<template>
  <Loader v-if="loading" />
  <v-layout v-else class="min-w-[800px] bg-transparent p-2">
    <SidebarMenu ref="sidebarMenu" />
    <v-main>
      <v-container fluid class="main-container">
        <slot />
      </v-container>
    </v-main>
  </v-layout>

  <FreeTrialNotification v-if="isFreeTrialNotificationShown" />
  <DiscountNotification
    v-if="isDiscountNotificationShown"
    :is-admin="EDIT_WS_PERMISSION"
  />
  <FreeSeatsPopup />
  <NewYearWelcomePopup />
  <MobileUnderDevelopmentPopup />
  <ManageFolderPopup />
  <DeleteFolderConfirmation />
  <DeleteAccountPopup />
  <DeleteAccountConfirmation />
  <DeleteAccountSuccess />
  <DeleteCurrentWorkspaceConfirmation />
  <DeleteCurrentConversationConfirmation />
  <LeaveCurrentWorkspaceConfirmation />
</template>
<style scoped>
.main-container {
  @apply overflow-auto bg-white h-full p-0 rounded-xl;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.1),
    0px 4px 5px 0px rgba(0, 0, 0, 0.07),
    0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}
</style>
