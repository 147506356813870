<script setup lang="ts">
import { ref } from 'vue';
import { UserMinusIcon } from 'vue-tabler-icons';
import type { VTextField } from 'vuetify/lib/components/index.mjs';
import { FOLDER_MEMBER_ROLES_OPTIONS } from '@/constants/dropdownOptions';
import { useUserId } from '@/auth/use-session';
import LetterAvatar from '@/components/common/molecules/LetterAvatar.vue';
import MenuIcon from '@/components/common/molecules/MenuIcon.vue';
import { validationEmail } from '@/utils/validation/text-field';
import { useManageFolder } from '@/components/settings/Folders/hooks';

const currentUserId = useUserId();
const { folderData, addAccessorToData, removeAccessorFromData } =
  useManageFolder();

const membersInputEl = ref<VTextField | null>(null);
const membersInput = ref<string>('');
const handleInvite = async (): Promise<void> => {
  if (!membersInputEl.value) {
    return;
  }

  const [error] = await membersInputEl.value.validate();
  if (error) {
    return;
  }

  addAccessorToData(membersInput.value);
  membersInput.value = '';
};
</script>
<template>
  <div class="flex flex-col gap-y-3">
    <div class="flex flex-col gap-y-2">
      <label class="typo-body1" for="manage-folder-popup__members">
        Members
      </label>
      <div class="flex gap-x-[10px]">
        <v-text-field
          id="manage-folder-popup__members"
          ref="membersInputEl"
          v-model="membersInput"
          class="manage-folder-popup__input grow"
          hide-details="auto"
          placeholder="Type emails to invite new teammates…"
          :rules="[validationEmail]"
          validate-on="submit"
        />
        <v-btn
          variant="outlined"
          color="primary"
          height="34"
          :ripple="{ class: 'text-primary' }"
          @click="handleInvite"
        >
          Invite
        </v-btn>
      </div>
    </div>
    <div class="flex flex-col gap-y-3 max-h-[110px] overflow-y-auto">
      <div
        v-for="(
          { accountId, userId, name, email, avatarUrl }, index
        ) in folderData.accessors"
        :key="accountId"
        class="flex justify-between gap-x-4"
      >
        <div class="flex gap-x-2">
          <LetterAvatar :name="name || email || ''" :image="avatarUrl ?? ''" />
          <div class="flex flex-col justify-between">
            <div class="flex items-center gap-x-1">
              <span v-if="name" class="typo-body1">
                {{ name }}
              </span>
              <div
                v-if="userId === currentUserId"
                class="px-1 py-[2px] font-inter text-2xs leading-3 font-medium text-primary bg-primary-50 rounded-[14px]"
              >
                That's you
              </div>
            </div>
            <span class="typo-body2 text-grey-70">
              {{ email }}
            </span>
          </div>
        </div>
        <div class="flex items-center gap-x-4">
          <v-btn
            variant="text"
            height="21"
            class="manage-folder-popup__remove-member-btn"
            :disabled="userId === currentUserId"
            @click="removeAccessorFromData(accountId)"
          >
            <template #prepend>
              <Icon :src="UserMinusIcon" class="w-4 h-4" />
            </template>
            <template #default>
              <span class="typo-body1 font-semibold">Remove</span>
            </template>
          </v-btn>
          <v-select
            v-model="folderData.accessors[index].role"
            variant="plain"
            density="compact"
            hide-details="true"
            :items="FOLDER_MEMBER_ROLES_OPTIONS"
            item-title="name"
            class="manage-folder-popup__role-select"
            :menu-icon="MenuIcon"
            :menu-props="{ location: 'bottom center' }"
            :disabled="userId === currentUserId"
          >
            <template #item="{ props }">
              <v-list-item
                v-bind="props"
                density="compact"
                class="manage-folder-popup__role-select__item"
              />
            </template>
          </v-select>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.manage-folder-popup__remove-member-btn {
  @apply p-0;
}

.manage-folder-popup__remove-member-btn .v-btn__prepend {
  @apply ml-0;
}

.manage-folder-popup__role-select .v-field__input {
  @apply p-0 typo-body1 min-h-0 h-auto items-center;
}

.manage-folder-popup__role-select.v-select
  .v-field__input
  .v-select__selection {
  @apply m-0 ms-0 me-0 min-w-[60px];
}

.manage-folder-popup__role-select
  .v-field.v-field--variant-plain
  .v-field__append-inner {
  @apply items-center py-0;
}

.manage-folder-popup__role-select
  .v-field.v-field--variant-plain
  .v-field__append-inner
  .v-icon {
  @apply min-h-0 h-auto min-w-0 w-auto;
}

.manage-folder-popup__role-select__item {
  @apply py-1 ps-2 pe-2 min-h-0 !important;
}

.manage-folder-popup__role-select__item .v-list-item-title {
  @apply typo-body1;
}
</style>
