export const ROUTE_NAMES = {
  DASHBOARD: 'dashboard',
  CONVERSATION: 'conversation',
  CONVERSATIONS: 'conversations',
  CREATE_WORKSPACE: 'create-workspace',
  LOADING: 'loading',
  OAUTH: 'oauth',
  ONBOARDING: 'onboarding',
  SIGN_IN: 'sign-in',
  SIGN_IN_OAUTH: 'sign-in-oauth',
  EXTENSION_UNINSTALLED: 'extension-uninstalled',
  SETTINGS_NEW: {
    INDEX: 'settings-new-index',
    PERSONAL: 'personal-settings',
    WORKSPACE: {
      INDEX: 'workspace-settings-index',
      WORKSPACE: 'workspace-settings',
      FOLDERS: 'workspace-settings-folders',
      TEAM: 'workspace-settings-team',
      BILLINGS: 'workspace-settings-billings',
      INTEGRATIONS: 'workspace-settings-integrations',
    },
  },

  SETTINGS: {
    ACCOUNT: 'account',
    CALENDARS: 'calendars',
    MEMBERS: 'members',
    WORKSPACE: 'workspace',
    FOLDERS: 'folders',
    FOLDER: 'folder',
    PLANS: 'plans',
    INTEGRATIONS: 'integrations',
  },
  HOME: 'home',
  DASHBOARD_DEFAULT: 'dashboard-default',
} as const;

export const ROUTE_META = {
  SETTINGS: 'settings',
};
