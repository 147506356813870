import type {
  ConfirmPopupOptionsType,
  ConversationParticipantPerson,
  CustomUser,
  WorkspaceAccountPerson,
} from '@/api/types';
import { reactive, ref } from 'vue';

interface Store {
  /** @deprecated use useUser() instead */
  user?: CustomUser;
  /** @deprecated use useCurrentWorkspace() instead */
  currentWorkspace?: WorkspaceAccountPerson;
  currentConversation?: ConversationParticipantPerson;
  confirmPopup: {
    isShown: boolean;
    controller: (value: boolean | null) => void;
    dialog?: string;
    options?: ConfirmPopupOptionsType;
  };
}

/** @deprecated */
export const store = reactive<Store>({
  confirmPopup: {
    isShown: false,
    controller: () => null,
  },
});

export function showConfirmPopup(
  dialog?: string,
  options?: ConfirmPopupOptionsType,
) {
  const confirmPopupPromise = new Promise(
    (resolve: (b: boolean | null) => void) => {
      store.confirmPopup.controller = resolve;
    },
  );
  if (dialog) {
    store.confirmPopup.dialog = dialog;
  }
  if (options) {
    store.confirmPopup.options = options;
  }
  store.confirmPopup.isShown = true;
  return confirmPopupPromise;
}

export function resolveConfirmPopup(answer: boolean | null) {
  store.confirmPopup.controller(answer);
  store.confirmPopup.isShown = false;
  delete store.confirmPopup.dialog;
  delete store.confirmPopup.options;
}

/* it's quick fix, I hate it */
export const showReward = ref(false);
