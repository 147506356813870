<script setup lang="ts">
import { TrashIcon } from 'vue-tabler-icons';
import { useDeleteCurrentWorkspace } from '@/components/settings/Workspace/hooks';

const {
  isDeleteCurrentWorkspaceConfirmationOpen,
  isDeleting,
  toggleDeleteCurrentWorkspaceConfirmation,
  deleteCurrentWorkspace,
} = useDeleteCurrentWorkspace();
</script>
<template>
  <v-dialog
    v-model="isDeleteCurrentWorkspaceConfirmationOpen"
    persistent
    max-width="544"
  >
    <div
      class="flex flex-col gap-y-8 bg-white w-full overflow-x-hidden rounded-2xl p-8 max-h-[800px] border border-grey-20 border-solid relative"
    >
      <ClosePopupButton
        :on-click="toggleDeleteCurrentWorkspaceConfirmation"
        :disabled="isDeleting"
      />
      <div class="flex flex-col gap-y-4">
        <h4 class="font-inter text-2xl leading-normal">Delete confirmation</h4>
        <p class="typo-body1 font-normal">
          Are you sure you want to delete this workspace? All conversations will
          be lost.
        </p>
      </div>
      <div class="flex justify-end items-center gap-x-4">
        <v-btn
          variant="text"
          color="error-functional-600"
          height="auto"
          class="typo-body1 font-semibold px-[2px]"
          :disabled="isDeleting"
          :loading="isDeleting"
          @click="deleteCurrentWorkspace"
        >
          <template #prepend>
            <Icon :src="TrashIcon" class="w-4 h-4 text-alerts-error-600" />
          </template>
          Delete workspace
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          height="36"
          class="typo-body1 font-semibold"
          :disabled="isDeleting"
          @click="toggleDeleteCurrentWorkspaceConfirmation"
        >
          Cancel
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
