import { GmeetLanguageCode } from './gmeet';

/**
 * Mapping of Google Meet language codes to Noty supported language codes
 * @remarks A numeric key is used, e.g. `1: 'EN'`
 */
export const GmeetToNotyLangCodeMap = Object.freeze(<const>{
  [GmeetLanguageCode['af-ZA']]: 'AF',
  [GmeetLanguageCode['sq-AL']]: 'SQ',
  [GmeetLanguageCode['am-ET']]: 'AM',
  [GmeetLanguageCode['ar-EG']]: 'AR_EG',
  [GmeetLanguageCode['ar-x-LEVANT']]: 'AR_X_LEVANT',
  [GmeetLanguageCode['ar-x-MAGHREBI']]: 'AR_X_MAGHREBI',
  [GmeetLanguageCode['ar-x-GULF']]: 'AR_X_GULF',
  [GmeetLanguageCode['ar-AE']]: 'AR_AE',
  [GmeetLanguageCode['hy-AM']]: 'HY',
  [GmeetLanguageCode['az-AZ']]: 'AZ',
  [GmeetLanguageCode['eu-ES']]: 'EU',
  [GmeetLanguageCode['bn-BD']]: 'BN_BD',
  [GmeetLanguageCode['bg-BG']]: 'BG',
  [GmeetLanguageCode['my-MM']]: 'MY',
  [GmeetLanguageCode['ca-ES']]: 'CA',
  [GmeetLanguageCode['cmn-Hans-CN']]: 'CMN_HANS_CN',
  [GmeetLanguageCode['cmn-Hant-TW']]: 'CMN_HANT_TW',
  [GmeetLanguageCode['cs-CZ']]: 'CS',
  [GmeetLanguageCode['nl-NL']]: 'NL',
  [GmeetLanguageCode['en-US']]: 'EN',
  [GmeetLanguageCode['en-AU']]: 'EN_AU',
  [GmeetLanguageCode['en-IN']]: 'EN_IN',
  [GmeetLanguageCode['en-PH']]: 'EN_PH',
  [GmeetLanguageCode['en-GB']]: 'EN_GB',
  [GmeetLanguageCode['et-EE']]: 'ET',
  [GmeetLanguageCode['fil-PH']]: 'FIL',
  [GmeetLanguageCode['fi-FI']]: 'FI',
  [GmeetLanguageCode['fr-FR']]: 'FR',
  [GmeetLanguageCode['fr-CA']]: 'FR_CA',
  [GmeetLanguageCode['gl-ES']]: 'GL',
  [GmeetLanguageCode['ka-GE']]: 'KA',
  [GmeetLanguageCode['de-DE']]: 'DE',
  [GmeetLanguageCode['el-GR']]: 'EL',
  [GmeetLanguageCode['gu-IN']]: 'GU',
  [GmeetLanguageCode['iw-IL']]: 'IW',
  [GmeetLanguageCode['hi-IN']]: 'HI',
  [GmeetLanguageCode['hu-HU']]: 'HU',
  [GmeetLanguageCode['is-IS']]: 'IS',
  [GmeetLanguageCode['id-ID']]: 'ID',
  [GmeetLanguageCode['it-IT']]: 'IT',
  [GmeetLanguageCode['ja-JP']]: 'JA',
  [GmeetLanguageCode['jv-ID']]: 'JV',
  [GmeetLanguageCode['kn-IN']]: 'KN',
  [GmeetLanguageCode['kk-KZ']]: 'KK',
  [GmeetLanguageCode['km-KH']]: 'KM',
  [GmeetLanguageCode['rw-RW']]: 'RW',
  [GmeetLanguageCode['ko-KR']]: 'KO',
  [GmeetLanguageCode['lo-LA']]: 'LO',
  [GmeetLanguageCode['lv-LV']]: 'LV',
  [GmeetLanguageCode['lt-LT']]: 'LT',
  [GmeetLanguageCode['mk-MK']]: 'MK',
  [GmeetLanguageCode['ms-MY']]: 'MS',
  [GmeetLanguageCode['ml-IN']]: 'ML',
  [GmeetLanguageCode['mr-IN']]: 'MR',
  [GmeetLanguageCode['mn-MN']]: 'MN',
  [GmeetLanguageCode['ne-NP']]: 'NE',
  [GmeetLanguageCode['nso-ZA']]: 'NSO',
  [GmeetLanguageCode['nb-NO']]: 'NO',
  [GmeetLanguageCode['fa-IR']]: 'FA',
  [GmeetLanguageCode['pl-PL']]: 'PL',
  [GmeetLanguageCode['pt-BR']]: 'PT_BR',
  [GmeetLanguageCode['pt-PT']]: 'PT',
  [GmeetLanguageCode['ro-RO']]: 'RO',
  [GmeetLanguageCode['sr-RS']]: 'SR',
  [GmeetLanguageCode['st-ZA']]: 'ST',
  [GmeetLanguageCode['si-LK']]: 'SI',
  [GmeetLanguageCode['sk-SK']]: 'SK',
  [GmeetLanguageCode['sl-SI']]: 'SL',
  [GmeetLanguageCode['es-MX']]: 'ES_MX',
  [GmeetLanguageCode['es-ES']]: 'ES',
  [GmeetLanguageCode['su-ID']]: 'SU',
  [GmeetLanguageCode['sw']]: 'SW',
  [GmeetLanguageCode['ss-latn-ZA']]: 'SS',
  [GmeetLanguageCode['sv-SE']]: 'SV',
  [GmeetLanguageCode['ta-IN']]: 'TA',
  [GmeetLanguageCode['te-IN']]: 'TE',
  [GmeetLanguageCode['th-TH']]: 'TH',
  [GmeetLanguageCode['ve-ZA']]: 'VE',
  [GmeetLanguageCode['tn-latn-ZA']]: 'TN',
  [GmeetLanguageCode['tr-TR']]: 'TR',
  [GmeetLanguageCode['uk-UA']]: 'UK',
  [GmeetLanguageCode['ur-PK']]: 'UR',
  [GmeetLanguageCode['uz-UZ']]: 'UZ',
  [GmeetLanguageCode['vi-VN']]: 'VI',
  [GmeetLanguageCode['xh-ZA']]: 'XH',
  [GmeetLanguageCode['ts-ZA']]: 'TS',
  [GmeetLanguageCode['zu-ZA']]: 'ZU',
});

export type LangCode = (typeof GmeetToNotyLangCodeMap)[keyof typeof GmeetToNotyLangCodeMap];

/**
 * @remarks Keep using the same language codes as it was implemented
 * in the extension from the beginning in order to avoid breaking changes
 */
export const NotyLangCodes = Object.freeze(
  Object.values(GmeetToNotyLangCodeMap).reduce(
    (acc, code) => ({ ...acc, [code]: code }),
    {} as Record<LangCode, LangCode>,
  ),
);

/**
 * A reverse mapping from Noty language codes to Google Meet language codes
 * @remarks The value is a numeric string, e.g. `EN: '1'`
 */
export const NotyLangToGmeetCodeMap = Object.freeze(
  Object.entries(GmeetToNotyLangCodeMap).reduce(
    (acc, [gmeetCode, notyLang]) => ({ ...acc, [notyLang]: gmeetCode }),
    {} as Record<LangCode, GmeetLanguageCode>,
  ),
);

export const LanguageTitleMap: Record<LangCode, string> = {
  AF: 'Afrikaans (beta)',
  SQ: 'Albanian (beta)',
  AM: 'Amharic (beta)',
  AR_EG: 'Arabic (Egypt) (beta)',
  AR_X_LEVANT: 'Arabic (Levant) (beta)',
  AR_X_MAGHREBI: 'Arabic (Maghrebi) (beta)',
  AR_X_GULF: 'Arabic (Peninsular Gulf) (beta)',
  AR_AE: 'Arabic (United Arab Emirates) (beta)',
  HY: 'Armenian (beta)',
  AZ: 'Azerbaijani (Azerbaijan) (beta)',
  EU: 'Basque (beta)',
  BN_BD: 'Bengali (Bangladesh) (beta)',
  BG: 'Bulgarian (Bulgaria) (beta)',
  MY: 'Burmese (Myanmar) (beta)',
  CA: 'Catalan (Spain) (beta)',
  CMN_HANS_CN: 'Chinese, Mandarin (Simplified) (beta)',
  CMN_HANT_TW: 'Chinese, Mandarin (Traditional) (beta)',
  CS: 'Czech (beta)',
  NL: 'Dutch',
  EN: 'English',
  EN_AU: 'English (Australia) (beta)',
  EN_IN: 'English (India) (beta)',
  EN_PH: 'English (Philippines) (beta)',
  EN_GB: 'English (UK)',
  ET: 'Estonian (beta)',
  FIL: 'Filipino (beta)',
  FI: 'Finnish (beta)',
  FR: 'French',
  FR_CA: 'French (Canada)',
  GL: 'Galician (beta)',
  KA: 'Georgian (beta)',
  DE: 'German',
  EL: 'Greek (beta)',
  GU: 'Gujarati (beta)',
  IW: 'Hebrew (beta)',
  HI: 'Hindi (beta)',
  HU: 'Hungarian (beta)',
  IS: 'Icelandic (beta)',
  ID: 'Indonesian (beta)',
  IT: 'Italian',
  JA: 'Japanese',
  JV: 'Javanese (beta)',
  KN: 'Kannada (beta)',
  KK: 'Kazakh (beta)',
  KM: 'Khmer (beta)',
  RW: 'Kinyarwanda (beta)',
  KO: 'Korean',
  LO: 'Lao (beta)',
  LV: 'Latvian (beta)',
  LT: 'Lithuanian (beta)',
  MK: 'Macedonian (beta)',
  MS: 'Malay (beta)',
  ML: 'Malayalam (beta)',
  MR: 'Marathi (beta)',
  MN: 'Mongolian (beta)',
  NE: 'Nepali (beta)',
  NSO: 'Northern Sotho (beta)',
  NO: 'Norwegian (beta)',
  FA: 'Persian (beta)',
  PL: 'Polish',
  PT_BR: 'Portuguese (Brazil)',
  PT: 'Portuguese',
  RO: 'Romanian',
  SR: 'Serbian (beta)',
  ST: 'Sesotho (beta)',
  SI: 'Sinhala (beta)',
  SK: 'Slovak (beta)',
  SL: 'Slovenian (beta)',
  ES_MX: 'Spanish (Mexico)',
  ES: 'Spanish',
  SU: 'Sundanese (beta)',
  SW: 'Swahili (beta)',
  SS: 'Swati (beta)',
  SV: 'Swedish (beta)',
  TA: 'Tamil (beta)',
  TE: 'Telugu (beta)',
  TH: 'Thai',
  VE: 'Tshivenda (beta)',
  TN: 'Tswana (beta)',
  TR: 'Turkish',
  UK: 'Ukrainian (beta)',
  UR: 'Urdu (beta)',
  UZ: 'Uzbek (beta)',
  VI: 'Vietnamese',
  XH: 'Xhosa (beta)',
  TS: 'Xitsonga (beta)',
  ZU: 'Zulu (beta)',
};
