// Official Google Meet language codes mapping
export enum GmeetLanguageCode {
  'af-ZA' = 98,
  'sq-AL' = 113,
  'am-ET' = 99,
  'ar-EG' = 47,
  'ar-x-LEVANT' = 85,
  'ar-x-MAGHREBI' = 86,
  'ar-x-GULF' = 84,
  'ar-AE' = 60,
  'hy-AM' = 107,
  'az-AZ' = 100,
  'eu-ES' = 103,
  'bn-BD' = 87,
  'bg-BG' = 81,
  'my-MM' = 126,
  'ca-ES' = 129,
  'cmn-Hans-CN' = 34,
  'cmn-Hant-TW' = 35,
  'cs-CZ' = 93,
  'nl-NL' = 8,
  'en-US' = 1,
  'en-AU' = 22,
  'en-IN' = 19,
  'en-PH' = 102,
  'en-GB' = 20,
  'et-EE' = 119,
  'fil-PH' = 120,
  'fi-FI' = 95,
  'fr-FR' = 5,
  'fr-CA' = 73,
  'gl-ES' = 130,
  'ka-GE' = 122,
  'de-DE' = 6,
  'el-GR' = 101,
  'gu-IN' = 88,
  'iw-IL' = 105,
  'hi-IN' = 18,
  'hu-HU' = 106,
  'is-IS' = 121,
  'id-ID' = 41,
  'it-IT' = 7,
  'ja-JP' = 9,
  'jv-ID' = 108,
  'kn-IN' = 89,
  'kk-KZ' = 191,
  'km-KH' = 82,
  'rw-RW' = 83,
  'ko-KR' = 11,
  'lo-LA' = 96,
  'lv-LV' = 132,
  'lt-LT' = 131,
  'mk-MK' = 125,
  'ms-MY' = 43,
  'ml-IN' = 90,
  'mr-IN' = 91,
  'mn-MN' = 109,
  'ne-NP' = 127,
  'nso-ZA' = 75,
  'nb-NO' = 25,
  'fa-IR' = 104,
  'pl-PL' = 39,
  'pt-BR' = 4,
  'pt-PT' = 17,
  'ro-RO' = 40,
  'sr-RS' = 134,
  'st-ZA' = 76,
  'si-LK' = 128,
  'sk-SK' = 112,
  'sl-SI' = 133,
  'es-MX' = 2,
  'es-ES' = 3,
  'su-ID' = 123,
  'sw' = 97,
  'ss-latn-ZA' = 77,
  'sv-SE' = 24,
  'ta-IN' = 114,
  'te-IN' = 115,
  'th-TH' = 37,
  've-ZA' = 78,
  'tn-latn-ZA' = 79,
  'tr-TR' = 38,
  'uk-UA' = 44,
  'ur-PK' = 116,
  'uz-UZ' = 117,
  'vi-VN' = 42,
  'xh-ZA' = 74,
  'ts-ZA' = 80,
  'zu-ZA' = 118,
}
