import { ref, computed } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { AIMessage } from '@noty/database';
import { useCurrentConversationId } from '@/store/hooks';
import { api } from '@/api';
import {
  AIMessageAskQuestionDto,
  AIMessageDirection,
  AIMessageStatus,
} from './types';
import { useUserId } from '@/auth/use-session';

export const useGetChatHistory = () => {
  const conversationId = useCurrentConversationId();
  const refetchInterval = ref<number | false>(false);

  return useQuery({
    enabled: computed(() => !!conversationId.value),
    queryKey: ['chatHistory', conversationId],
    queryFn: async () => {
      const messages = await api.aiMessages.getChatHistory.query({
        conversationId: conversationId.value!,
      });

      refetchInterval.value =
        messages.at(-1)?.status === AIMessageStatus.PENDING ? 5000 : false;
      return messages;
    },
    refetchInterval,
  });
};

export const useAskQuestion = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();
  const userId = useUserId();

  const chatQueryKey = ['chatHistory', conversationId];

  return useMutation({
    mutationFn: (dto: Pick<AIMessageAskQuestionDto, 'question'>) => {
      if (!conversationId.value) {
        throw new Error('Conversation id is required');
      }

      return api.aiMessages.askQuestion.mutate({
        ...dto,
        conversationId: conversationId.value,
      });
    },
    onMutate: async ({ question }) => {
      await queryClient.cancelQueries({ queryKey: chatQueryKey });

      const previousHistory =
        queryClient.getQueryData<AIMessage[]>(chatQueryKey);
      const now = new Date();
      const messagePartial = {
        conversationId: conversationId.value!,
        userId: userId.value ?? '',
        createdAt: now,
        updatedAt: now,
      };

      queryClient.setQueryData(
        chatQueryKey,
        (prev: AIMessage[] | undefined) => [
          ...(prev ?? []),
          {
            ...messagePartial,
            id: Math.random(),
            message: question,
            status: AIMessageStatus.FULFILLED,
            direction: AIMessageDirection.OUTGOING,
          },
          {
            ...messagePartial,
            id: Math.random(),
            message: '',
            status: AIMessageStatus.PENDING,
            direction: AIMessageDirection.INCOMING,
          },
        ],
      );

      return { previousHistory };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(chatQueryKey, context?.previousHistory);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['chatHistory']);
    },
  });
};
