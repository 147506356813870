import { computed, ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { ROUTE_NAMES } from '@/constants/routeNames';

export const useCurrentConversationId = (): ComputedRef<string | null> => {
  const route = useRoute();
  return computed(() => {
    const id = route.params.id;
    if (route.name === ROUTE_NAMES.CONVERSATION && typeof id === 'string') {
      return id;
    }

    return null;
  });
};
