import { reactive } from 'vue';
import { onExtensionMessage, sendMessageToExtension } from './listeners';
import { isNotNullOrUndefined } from '@/utils/undefined.guards';

type ExtensionConfig = {
  isInstalled: boolean;
  gmeetEnabled?: boolean;
};

export const extensionConfig = reactive<ExtensionConfig>({
  isInstalled: false,
});

export function updateExtensionConfig(update: { gmeetEnabled?: boolean }) {
  sendMessageToExtension('UPDATE_CONFIG', update);
  if (isNotNullOrUndefined(update.gmeetEnabled)) {
    extensionConfig.gmeetEnabled = update.gmeetEnabled;
  }
}

export function registerConfigListener() {
  onExtensionMessage('CONFIG', (config) => {
    extensionConfig.isInstalled = true;
    extensionConfig.gmeetEnabled = config.gmeetEnabled;
  });
}
