import { supabase } from '@/auth/supabase';
import { store } from '@/store';
import { showError, showMessage } from '@/utils/alert';
import { trackEvent } from '@/utils/analytics';
import { UtmMedium, UtmSource } from '@/utils/analytics/enums';
import { applyCodeToUrl } from '@/utils/url';

export function googleSignIn(referralCode?: string) {
  const url = new URL(location.href);
  url.searchParams.set('utm_source', UtmSource.Google);
  url.searchParams.set('utm_medium', UtmMedium.OAuth);

  supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: applyCodeToUrl(url, referralCode),
    },
  });
}

export async function emailSignIn(email: string, referralCode?: string) {
  const url = new URL(location.href);
  url.searchParams.set('utm_source', UtmSource.Email);
  url.searchParams.set('utm_medium', UtmMedium.MagicLink);

  const result = await supabase.auth.signInWithOtp({
    email,
    options: {
      emailRedirectTo: applyCodeToUrl(url, referralCode),
    },
  });
  if (result.error) {
    trackEvent('Sign in error', { provider: 'magiclink' });
    showError(result.error.message);
  } else {
    trackEvent('Sign in link sent');
    showMessage(`A sign-in link has been sent to ${email}`);
  }
}

export function signOut() {
  trackEvent('LogOut');
  supabase.auth.signOut().then(({ error }) => {
    if (error) {
      showError(error.message);
      return;
    }
    delete store.user;
    window.location.replace('/');
  });
}

export function processOAuthUrl(url: string) {
  trackEvent('Flow authorisation request', { auth_url: url });
  const newWindow = window.open(url, '_blank', 'popup');
  return new Promise<void>((resolve, reject) => {
    const interval = setInterval(() => {
      if (newWindow?.closed) {
        clearInterval(interval);
        if (window.localStorage.getItem('OAuthResult') === 'SUCCESS') {
          resolve();
        } else {
          trackEvent('Sign in error', { provider: 'google' });
          reject();
        }
        window.localStorage.removeItem('OAuthResult');
      }
    });
  });
}
