<script setup lang="ts">
import { defineModel } from 'vue';
import { validationRequired } from '@/utils/validation/text-field';

const model = defineModel({ required: true });
</script>
<template>
  <div class="flex flex-col gap-y-2">
    <label class="typo-body1" for="manage-folder-popup__name"> Name </label>
    <v-text-field
      id="manage-folder-popup__name"
      v-model="model"
      class="manage-folder-popup__input"
      hide-details="auto"
      placeholder="Enter folder name"
      :rules="[validationRequired]"
    />
  </div>
</template>
