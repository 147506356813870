import { computed } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { useCurrentConversationId } from '@/store/hooks';
import { api } from '@/api';
import { ToDoItem, UpdateTodoItemDto, WorkspacePerson } from './types';
import { useCurrentWorkspaceId } from '@/auth/use-workspace';

export const useTodos = () => {
  const conversationId = useCurrentConversationId();

  return useQuery({
    enabled: computed(() => !!conversationId.value),
    queryKey: ['todos', conversationId],
    queryFn: ({ signal }) => {
      return api.todoItems.forConversation.query(
        { conversationId: conversationId.value! },
        { signal },
      );
    },
  });
};

export const useUpdateTodoItem = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();
  const currentWorkspaceId = useCurrentWorkspaceId();

  const todosQueryKey = ['todos', conversationId];
  const peopleQueryKey = ['currentWorkspacePeople', currentWorkspaceId];

  return useMutation({
    mutationFn: (dto: UpdateTodoItemDto) => api.todoItems.update.mutate(dto),
    onMutate: async (dto) => {
      await queryClient.cancelQueries({ queryKey: todosQueryKey });

      const { id, dueAt, ...updates } = dto;

      let previousTodos: ToDoItem[] | undefined;
      queryClient.setQueryData(
        todosQueryKey,
        (prev: ToDoItem[] | undefined) => {
          previousTodos = prev;
          return prev?.map((todoItem) => {
            if (todoItem.id !== id) {
              return todoItem;
            }

            const personOverwrite =
              updates.personId &&
              queryClient
                .getQueryData<WorkspacePerson[]>(peopleQueryKey)
                ?.find(({ id }) => id === updates.personId);

            return {
              ...todoItem,
              ...updates,
              ...(dueAt && { dueAt: dueAt.toISOString() }),
              ...(updates.personId && {
                person: <ToDoItem['person']>{
                  ...todoItem.person,
                  ...personOverwrite,
                },
              }),
            };
          });
        },
      );

      return { previousTodos };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(todosQueryKey, context?.previousTodos);
    },
    onSettled: () => queryClient.invalidateQueries(['todos']),
  });
};

export const useDeleteTodoItem = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();

  const todosQueryKey = ['todos', conversationId];

  return useMutation({
    mutationFn: (id: number) => api.todoItems.delete.mutate({ id }),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: todosQueryKey });

      let previousTodos: ToDoItem[] | undefined;
      queryClient.setQueryData(
        todosQueryKey,
        (prev: ToDoItem[] | undefined) => {
          previousTodos = prev;
          return prev?.filter((todoItem) => todoItem.id !== id);
        },
      );

      return { previousTodos };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(todosQueryKey, context?.previousTodos);
    },
    onSettled: () => queryClient.invalidateQueries(['todos']),
  });
};
