import { ref, Ref, watchEffect, computed, ComputedRef } from 'vue';
import { isEmpty } from 'lodash';
import { useUser } from '@/auth/use-session';
import { useCurrentWorkspace } from '@/auth/use-workspace';
import {
  useMakeCurrentWorkspacePrimary,
  useUpdateCurrentWorkspace,
} from '@/api/workspaces';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface WorkspaceData {
  name: string;
  isPrimary: boolean;
}

interface SaveWorkspaceDataHook {
  currentWorkspaceName: ComputedRef<string>;
  isCurrentWorkspacePrimary: ComputedRef<boolean>;
  updateCurrentWorkspaceMutation: ReturnType<
    typeof useUpdateCurrentWorkspace
  >['mutateAsync'];
  makeCurrentWorkspacePrimaryMutation: ReturnType<
    typeof useMakeCurrentWorkspacePrimary
  >['mutateAsync'];
}

interface ManageCurrentWorkspaceHook {
  workspaceData: Ref<WorkspaceData>;
  isCurrentWorkspacePrimary: ComputedRef<boolean>;
  hasChanges: ComputedRef<boolean>;
  isSaving: Ref<boolean>;
  clear: () => void;
  save: () => Promise<void>;
}

const workspaceData = ref<WorkspaceData>({} as WorkspaceData);
const isSaving = ref(false);

const useClear =
  (
    currentWorkspaceName: ComputedRef<string>,
    isCurrentWorkspacePrimary: ComputedRef<boolean>,
  ) =>
  () => {
    workspaceData.value = {
      name: currentWorkspaceName.value,
      isPrimary: isCurrentWorkspacePrimary.value,
    };
  };

const useSave =
  ({
    currentWorkspaceName,
    isCurrentWorkspacePrimary,
    makeCurrentWorkspacePrimaryMutation,
    updateCurrentWorkspaceMutation,
  }: SaveWorkspaceDataHook) =>
  async () => {
    try {
      isSaving.value = true;
      const { name, isPrimary } = workspaceData.value;

      if (name !== currentWorkspaceName.value) {
        await updateCurrentWorkspaceMutation({ name });
      }

      if (isPrimary !== isCurrentWorkspacePrimary.value) {
        await makeCurrentWorkspacePrimaryMutation();
      }
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isSaving.value = false;
    }
  };

export const useManageCurrentWorkspace = (): ManageCurrentWorkspaceHook => {
  const { data: user } = useUser();
  const { data: currentWorkspace } = useCurrentWorkspace();

  const currentWorkspaceName = computed(
    () => currentWorkspace.value?.name || '',
  );
  const isCurrentWorkspacePrimary = computed(() => {
    const primaryWorkspace = user.value?.primaryAccount?.workspaceId;
    const workspaceId = currentWorkspace.value?.id;
    return !!(
      primaryWorkspace &&
      workspaceId &&
      primaryWorkspace === workspaceId
    );
  });

  const clear = useClear(currentWorkspaceName, isCurrentWorkspacePrimary);

  if (isEmpty(workspaceData.value)) {
    clear();
  }

  watchEffect(() => {
    clear();
  });

  const hasChanges = computed(
    () =>
      workspaceData.value.name !== currentWorkspaceName.value ||
      workspaceData.value.isPrimary !== isCurrentWorkspacePrimary.value,
  );

  const { mutateAsync: updateCurrentWorkspaceMutation } =
    useUpdateCurrentWorkspace();
  const { mutateAsync: makeCurrentWorkspacePrimaryMutation } =
    useMakeCurrentWorkspacePrimary();
  const save = useSave({
    currentWorkspaceName,
    isCurrentWorkspacePrimary,
    updateCurrentWorkspaceMutation,
    makeCurrentWorkspacePrimaryMutation,
  });

  return {
    workspaceData,
    isCurrentWorkspacePrimary,
    hasChanges,
    isSaving,
    clear,
    save,
  };
};
