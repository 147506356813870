<script setup lang="ts">
import { LockIcon } from 'vue-tabler-icons';
import { useCurrentWorkspaceId } from '@/auth/use-workspace';
import { ROUTE_NAMES } from '@/constants/routeNames';

defineProps<{
  isAvailable: boolean;
}>();

const workspaceId = useCurrentWorkspaceId();
</script>
<template>
  <DisabledContent
    :is-available="isAvailable"
    class="upgradable-content__container"
  >
    <template #badge>
      <div class="upgradable-content__badge">
        <v-btn
          variant="text"
          elevation="0"
          height="auto"
          :ripple="{ class: 'text-primary' }"
          :to="{
            name: ROUTE_NAMES.SETTINGS_NEW.WORKSPACE.BILLINGS,
            params: { workspaceId },
          }"
        >
          <template #prepend>
            <Icon :src="LockIcon" class="w-4 h-4 text-primary" />
          </template>
          <template #default>
            <span>Upgrade your plan</span>
          </template>
        </v-btn>
      </div>
    </template>
    <template #default>
      <slot />
    </template>
  </DisabledContent>
</template>
<style>
.upgradable-content__container:hover .upgradable-content__badge {
  @apply max-w-[200px];
}

.upgradable-content__badge {
  @apply absolute top-0 bottom-0 left-0 max-w-0 z-40 transition-[max-width] duration-300 ease-in-out overflow-hidden flex items-center rounded;
}

.upgradable-content__badge .v-btn {
  @apply p-2;
}

.upgradable-content__badge .v-btn .v-btn__overlay {
  @apply bg-primary;
}

.upgradable-content__badge .v-btn .v-btn__content {
  @apply typo-body1 text-primary font-medium;
}

.upgradable-content__badge .v-btn .v-btn__prepend {
  @apply m-0;
}
</style>
