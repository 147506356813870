<script setup lang="ts">
import { useCurrentWorkspace } from '@/auth/use-workspace';
import { ref, onMounted, computed } from 'vue';
import { showMessage } from '@/utils/alert';
import { applyPromotionCode } from '@/api/subscriptions';
import { trackEvent } from '@/utils/analytics';
import { differenceInHours } from 'date-fns';
import Heading from '@/assets/notification_heading_discount.svg';

//TRUE - when the DiscountNotification record was made no more than 24 hours ago:
const isShown = ref(
  window.localStorage.getItem('DiscountNotification') &&
    Math.abs(
      differenceInHours(
        new Date(+window.localStorage.getItem('DiscountNotification') ?? 0),
        new Date(),
      ),
    ) < 24,
);

defineProps<{
  isAdmin?: boolean;
}>();

const loading = ref(false);
const { data: workspace } = useCurrentWorkspace();

const trialDaysLeft = computed(() => {
  if (!workspace.value?.trialEnd) {
    return null;
  }
  const finishTime = new Date(workspace.value.trialEnd);
  const currentTime = new Date();
  const trialDaysLeft = Math.ceil(
    differenceInHours(finishTime, currentTime) / 24,
  );
  return trialDaysLeft > 0 ? trialDaysLeft : 0;
});

const closeNotification = () => {
  isShown.value = false;
  resetLocalStorageData();
};

const applyDiscount = async () => {
  if (!workspace.value) {
    return;
  }
  loading.value = true;
  const res = await applyPromotionCode(
    workspace.value.id,
    localStorage.getItem('PromotionCode') as string,
  );
  if (res?.checkoutRedirectUrl) {
    resetLocalStorageData();
    window.open(res.checkoutRedirectUrl, '_self');
  } else {
    showMessage('Discount has been applied successfully');
    closeNotification();
  }
  loading.value = false;
};

onMounted(() => {
  if (!isShown.value) {
    return;
  }
  trackEvent('Discount start dialog view', {
    workspace: workspace.value?.id,
  });
});

function resetLocalStorageData() {
  window.localStorage.removeItem('DiscountNotification');
  window.localStorage.removeItem('FreeTrialNotification');
  window.localStorage.removeItem('PromotionCode');
}
</script>

<template>
  <Modal
    :is-open="isShown"
    class="w-[969px] h-[626px] max-w-[969px] max-h-[626px]"
    button-close-class="text-white"
    @close="closeNotification"
  >
    <div class="h-full w-full grid grid-cols-2 overflow-y-auto">
      <div class="py-8 px-12 flex flex-col justify-between items-center">
        <div class="flex flex-col gap-6">
          <component :is="Heading" class="-translate-x-2" />
          <p class="text-xl font-light">Enjoy the all capacity of Noty.ai:</p>
          <ul class="mt-3 flex flex-col gap-3">
            <li
              v-for="feature in [
                'Automatically transcribe',
                'ChatGPT-generated AI Summaries',
                'AI and Manual Meeting Notes',
                'Send instant meeting Follow-ups',
                'Create Team Spaces',
                'Share Conversations with your Teams',
              ]"
              :key="feature"
              class="flex gap-3"
            >
              <img
                src="https://storage.googleapis.com/noty-ai/webapp/lightning.webp"
                class="w-[13px] h-[26px]"
              />
              <span class="text-xl">{{ feature }}</span>
            </li>
          </ul>
        </div>
        <div class="w-full flex flex-col items-center gap-2">
          <Button
            :loading="loading"
            :disabled="!isAdmin"
            class="w-full h-12"
            @click="applyDiscount"
          >
            {{
              isAdmin
                ? 'Get my discount'
                : 'Only workspace admin can manage subscription'
            }}
          </Button>
          <p v-if="trialDaysLeft" class="text-slate-500 text-sm font-light">
            {{ trialDaysLeft }}-day free trial before purchase, completely
            risk-free
          </p>
        </div>
      </div>
      <div
        class="px-9 pt-16 pb-12 bg-primary flex flex-col items-center justify-center text-white text-center"
      >
        <img
          src="https://storage.googleapis.com/noty-ai/webapp/trial_welcome.webp"
          class="w-[446px] h-[264px] select-none"
          alt="welcome"
        />
      </div>
    </div>
  </Modal>
</template>
