<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
import { ChevronDownIcon, PlusIcon, PointFilledIcon } from 'vue-tabler-icons';
import { useFoldersMenu } from '../hooks';
import { useCurrentWorkspaceId } from '@/auth/use-workspace';
import { ROUTE_NAMES } from '@/constants/routeNames';

const { folders, openedPanel, isReadonly, toggleReadonlyState, createFolder } =
  useFoldersMenu();
const workspaceId = useCurrentWorkspaceId();
</script>

<template>
  <v-expansion-panels v-model="openedPanel" :readonly="isReadonly">
    <v-expansion-panel
      elevation="0"
      hide-actions
      readonly
      class="folders-dropdown bg-transparent"
      :ripple="{ class: 'text-primary' }"
    >
      <template #title>
        <div
          class="flex items-center justify-between gap-x-2 px-2 py-[9px] w-full z-[2]"
        >
          <Icon
            class="w-5 h-5 cursor-pointer"
            :class="{
              invisible: !folders?.length,
              'rotate-180': openedPanel !== undefined,
            }"
            :src="ChevronDownIcon"
          />
          <p class="grow text-left typo-body1">Folders</p>
          <Icon
            class="w-5 h-5 cursor-pointer"
            :src="PlusIcon"
            @mouseover="toggleReadonlyState"
            @mouseout="toggleReadonlyState"
            @click="createFolder"
          />
        </div>
      </template>
      <template #text>
        <div
          v-if="!folders"
          class="flex flex-col items-end gap-y-[2px] pt-[2px]"
        >
          <v-skeleton-loader v-for="i in 3" :key="i" class="w-5/6 h-7" />
        </div>
        <template v-else>
          <RouterLink
            v-for="{ id, name, conversations } in folders"
            :key="id"
            :to="{
              name: ROUTE_NAMES.CONVERSATIONS,
              params: { workspaceId },
              query: { spaceId: id },
            }"
          >
            <div
              class="flex flex-row items-center justify-between gap-x-2 py-[6px] pl-9 pr-2 typo-body1 text-ellipsis cursor-pointer"
            >
              <Icon class="w-2 h-2" :src="PointFilledIcon" />
              <p class="grow text-left">{{ name }}</p>
              <span class="text-2xs leading-3 text-blue-grey">
                {{ conversations.length }}
              </span>
            </div>
          </RouterLink>
        </template>
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style>
.folders-dropdown .v-expansion-panel-title {
  @apply p-0 min-h-0;
}

.folders-dropdown.v-expansion-panel--active .v-expansion-panel-title {
  @apply min-h-0;
}

.folders-dropdown .v-expansion-panel-title__overlay {
  @apply bg-primary rounded;
}

.folders-dropdown .v-expansion-panel-text__wrapper {
  @apply p-0;
}
</style>
