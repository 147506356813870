import { api } from '@/api';
import { supabase } from '@/auth/supabase';
import { store } from '@/store';
import { identifyUser } from '@/utils/analytics';
import { reset } from '@amplitude/analytics-browser';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { computed, onMounted, Ref, ref, watch } from 'vue';

const isInit = ref<boolean>(false);

export const useSession = () => {
  const client = useQueryClient();

  onMounted(() => {
    if (isInit.value) {
      return;
    }
    isInit.value = true;
    supabase.auth.onAuthStateChange((event, session) =>
      client.setQueryData(['auth'], session),
    );
  });

  return useQuery({
    queryKey: ['auth'],
    queryFn: () => supabase.auth.getSession().then(({ data }) => data.session),
  });
};

export const useUser = () => {
  const { isFetched, data } = useSession();

  return useQuery({
    queryKey: ['user'],
    staleTime: Infinity,
    queryFn: () => {
      const url = new URL(window.location.href);
      const referralCode = url.searchParams.get('referralCode') || '';
      return api.auth.signIn.mutate(
        referralCode
          ? {
              referralCode,
            }
          : {},
      );
    },
    enabled: computed(() => isFetched.value && !!data.value?.user),
    onSuccess: (user) => {
      store.user = user;
    },
  });
};

export const useIsAuth = (): Ref<boolean> => {
  const { data } = useUser();

  return computed<boolean>(() => !!data.value);
};

export const useUserId = () => {
  const { data } = useSession();
  return computed(() => data.value?.user?.id);
};

export const useIdentity = () => {
  const { data } = useUser();
  watch(
    () => data.value?.id,
    (id) => {
      if (id) {
        identifyUser(data.value);
      } else {
        reset();
      }
    },
  );
};
