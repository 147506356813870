<script setup lang="ts">
import { ref, watch } from 'vue';
import { useSetOnboarding } from '@/utils/onboarding';
import { useUser } from '@/auth/use-session';
import { trackEvent } from '@/utils/analytics';

const { data: user } = useUser();

const isActive = ref(false);

const expirationDate = new Date(2024, 2, 1); // till March
const currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);
const isVideoExpired = currentDate >= expirationDate;

const unwatch = watch(user, (fresh) => {
  if (!fresh) {
    return;
  }
  if (isVideoExpired) {
    isActive.value = false;
    return;
  }
  isActive.value = !user.value?.onboarding.natalieNYGreetings;
  unwatch();
});

const { mutate: setOnboarding } = useSetOnboarding();

const videoPlayer = ref<HTMLVideoElement | undefined>(undefined);

const closeEventHandler = () => {
  isActive.value = false;
  setOnboarding({ natalieNYGreetings: true });
};
trackEvent('Popup Welcome View');
</script>
<template>
  <v-dialog width="570" :model-value="isActive" persistent>
    <v-card
      class="p-10 pb-6 rounded-[16px] max-sm:rounded-lg bg-[url('@/assets/icons/noty_bg.svg')]"
    >
      <v-card-item class="p-0">
        <v-card-text class="p-0">
          <video
            ref="videoPlayer"
            width="500"
            class="rounded-[16px]"
            controls
            :volume="0.3"
            :playbackRate="1.25"
            autoplay
            mute
          >
            <source
              src="https://storage.googleapis.com/noty-ai/webapp/videos/Natalie-from-Noty.mp4"
              type="video/mp4"
            />
          </video>
        </v-card-text>
      </v-card-item>
      <v-card-actions class="p-0 ml-auto mt-6">
        <v-btn
          variant="text"
          text="Close"
          class="text-primary h-[38px] w-20"
          @click="closeEventHandler"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
