export const applyCodeToUrl = (url: URL, code?: string): string => {
  const QUERY_KEY = 'referralCode';

  if (!code?.trim()) {
    return url.toString();
  }
  if (!url.searchParams.get(QUERY_KEY)) {
    url.searchParams.append(QUERY_KEY, code);
  }

  return url.toString();
};
