import { Pricing } from '@/api/types';

import { useCurrentWorkspace } from '@/auth/use-workspace';
import { computed } from 'vue';

export const useIsTeamPlan = () => {
  const { data } = useCurrentWorkspace();

  return computed(() => data.value?.pricing === Pricing.TEAM);
};
