<script setup lang="ts">
import { ref, watch } from 'vue';
import { CheckIcon, CopyIcon } from 'vue-tabler-icons';
import { copyToClipboard } from '@/utils/clipboard';

const emit = defineEmits<{ (e: 'success'): void }>();

const props = withDefaults(
  defineProps<{
    value: string;
    iconSize?: number | string;
  }>(),
  { iconSize: 20 },
);

const isCopied = ref(false);
const isHovered = ref(false);

watch(isHovered, (value) => {
  if (!value && isCopied.value) {
    setTimeout(() => {
      isCopied.value = false;
    }, 1000);
  }
});

const clickHandler = () => {
  copyToClipboard(props.value);
  isCopied.value = true;
  emit('success');
};
</script>

<template>
  <Button
    v-tooltip="isCopied ? 'Copied' : 'Copy to clipboard'"
    pure
    :disabled="isCopied"
    class="py-1 px-1.5 rounded-md hover:bg-slate-100"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @click="clickHandler"
  >
    <Icon
      :src="isCopied ? CheckIcon : CopyIcon"
      :size="iconSize"
      :class="isCopied ? 'text-green-600' : 'text-slate-600'"
    />
  </Button>
</template>
