import { isEmail, isString } from '@/utils';

export const validationRequired = (value: string | string[]): true | string =>
  (isString(value) ? !!value.trim().length : !!value.length) ||
  'Required field';

export const validationEmail = (value: string | string[]): true | string =>
  (isString(value)
    ? isEmail(value)
    : !value.some((email): boolean => !isEmail(email))) ||
  'You have an invalid email value';

export const validationMinLength = (
  value: string,
  minChars = 2,
): true | string =>
  value.trim().length >= minChars ||
  `Please use at least ${minChars} char${minChars === 1 ? '' : 's'}`;

export const validationMaxLength = (
  value: string | string[],
  maxLength = 5,
): true | string =>
  value.length <= maxLength || 'You have reached maximum value';
