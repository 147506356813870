import { useUser } from '@/auth/use-session';
import deepmerge from 'deepmerge';
import { store } from '@/store';
import { showError } from '@/utils/alert';
import { OnboardingType } from '@/api/types';
import { updateUserData } from '@/api/users';
import { computed } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { ZoomJoinToType } from '@/api/types';

export enum OnboardingStepEnum {
  POLL = 'poll',
  WORKSPACE = 'workspace',
  EXTENSION = 'extension',
  CONSENT = 'consent',
}

const useUserOnboarding = () => {
  const { data } = useUser();
  return computed(() => {
    return data.value?.onboarding ? data.value?.onboarding : null;
  });
};

export const useCurrentOnboardingScreen = () => {
  const onboarding = useUserOnboarding();
  return computed(() => {
    if (onboarding.value === null) {
      return OnboardingStepEnum.POLL;
    }

    const { extension, workspace, poll } = onboarding.value;
    if (extension) {
      return OnboardingStepEnum.CONSENT;
    }
    if (workspace) {
      return OnboardingStepEnum.EXTENSION;
    }
    if (poll) {
      return OnboardingStepEnum.WORKSPACE;
    }
    return OnboardingStepEnum.POLL;
  });
};

/** @deprecated use useIsOnboardingProcess hook instead  */
export const isOnboardingCondition = () => {
  if (!store.user) {
    return false;
  }
  const onboarding = store.user.onboarding;
  if (
    !onboarding ||
    !onboarding.poll ||
    !onboarding.workspace ||
    !onboarding.extension ||
    !onboarding.consent
  ) {
    return true;
  }
  return false;
};

export const useIsOnboardingProcess = () => {
  const { data } = useUser();
  return computed(() => {
    if (!data.value) {
      return false;
    }
    const onboarding = data.value?.onboarding;
    return !(
      onboarding &&
      onboarding.poll &&
      onboarding.workspace &&
      onboarding.extension &&
      onboarding.consent
    );
  });
};

/** @deprecated use useSetOnboarding instead   */
export const setOnboarding = async (
  onboarding: OnboardingType,
  immediate = true,
) => {
  if (!store.user) {
    return;
  }
  try {
    const onboardingResponse = await updateUserData({
      onboarding: deepmerge(
        store.user.onboarding as OnboardingType,
        onboarding,
      ),
    });
    if (immediate) {
      store.user.onboarding = onboardingResponse.onboarding as OnboardingType;
    }
  } catch (e) {
    showError(e as Error);
  }
};

export const useSetOnboarding = () => {
  const { data } = useUser();

  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading, isSuccess } = useMutation({
    mutationKey: ['user'],
    mutationFn: (updatedKey: OnboardingType) =>
      updateUserData({
        onboarding: { ...(data.value?.onboarding as object), ...updatedKey },
      }),
    onSuccess(data) {
      queryClient.setQueryData(['user'], () => data);
    },
    onError(error: Error) {
      showError(error);
    },
  });
  return { mutate, mutateAsync, isLoading, isSuccess };
};

/** @deprecated write hook instead  */
export const resetOnboarding = async () => {
  if (!store.user) {
    return;
  }
  try {
    const onboarding: OnboardingType = { ...store.user.onboarding };
    delete onboarding.guides;
    const onboardingResponse = await updateUserData({ onboarding });
    store.user.onboarding = onboardingResponse.onboarding as OnboardingType;
  } catch (e) {
    showError(e as Error);
  }
};

export const getProgress = (step: number) => {
  return Math.round((2 * sigmoid(step) - 1) * 100);
};

function sigmoid(x: number, factor = 2) {
  return 1 / (1 + Math.exp(-x / factor));
}

export const useDefaultWorkspaceName = () => {
  const { data: user } = useUser();
  return computed(() => {
    if (user.value) {
      const userName =
        user.value.name?.split(' ')[0] || user.value.email.split('@')[0];
      return `${userName.slice(0, 21)}'s workspace`;
    }
    return 'My workspace';
  });
};

export const isZoomEnabled = () => {
  return computed(() =>
    [
      ZoomJoinToType.ALL,
      ZoomJoinToType.EXTERNAL,
      ZoomJoinToType.INTERNAL,
      ZoomJoinToType.OWNED,
    ].includes(
      store.currentWorkspace?.accounts?.find(
        ({ userId }) => userId === store.user?.id,
      )?.zoomBotJoiningStrategy as ZoomJoinToType,
    ),
  );
};
