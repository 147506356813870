import { AccountRole } from '@/api/types';
import { useUserId } from '@/auth/use-session';
import { useCurrentWorkspace } from '@/auth/use-workspace';
import { computed } from 'vue';

export const useIsWorkspaceAdmin = () => {
  const { data: workspace } = useCurrentWorkspace();
  const userId = useUserId();

  return computed(() => {
    if (!workspace.value) {
      return false;
    }
    return workspace.value?.accounts?.some((account) => {
      return (
        account.role === AccountRole.ADMIN && account.userId === userId.value
      );
    });
  });
};
