import ConfirmPopup from './ConfirmPopup.vue';
import CopyButton from './CopyButton.vue';
import EmailInvitation from './EmailInvitation.vue';
import Input from './Input.vue';
import People from './People.vue';
import Select from './Select.vue';
import UserInfo from './UserInfo.vue';
import UpgradableContent from './UpgradableContent.vue';

export default {
  ConfirmPopup,
  CopyButton,
  EmailInvitation,
  Input,
  People,
  Select,
  UserInfo,
  UpgradableContent,
};
