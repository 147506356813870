<template>
  <div class="loader-wrapper">
    <img
      src="https://storage.googleapis.com/noty-ai/webapp/loader.gif"
      class="w-12"
    />
  </div>
</template>

<style scoped>
.loader-wrapper {
  @apply w-full h-full z-10 relative flex justify-center items-center;
}
</style>
