<script setup lang="ts">
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { isString } from '@/utils';

const props = defineProps<{
  to: RouteLocationRaw;
  target?: '_blank' | '_self' | '_parent' | '_top';
}>();

const isExternal = computed(
  () => isString(props.to) && props.to.startsWith('http'),
);
</script>

<template>
  <a
    v-if="isExternal"
    :href="props.to as string"
    :target="props.target || '_blank'"
    @click.stop
  >
    <slot />
  </a>
  <RouterLink v-else v-bind="props"><slot /></RouterLink>
</template>
