import { Ref, ref } from 'vue';
import { useFinishCurrentConversationManually } from '@/api/conversations';
import { useCurrentConversationAssists } from '@/api/assists';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';
import { AIAssistType, AIAssistStatus } from '@/api/types';
import { useTodos } from '.';

interface FinishConversationManuallyHook {
  areAssistsGenerating: Ref<boolean>;
  finish: () => Promise<void>;
}

const areAssistsGenerating = ref(false);

const useFinish =
  (
    finishConversationMutation: ReturnType<
      typeof useFinishCurrentConversationManually
    >['mutateAsync'],
    fetchAssists: ReturnType<typeof useCurrentConversationAssists>['refetch'],
    refetchTodos: ReturnType<typeof useTodos>['refetchTodos'],
  ) =>
  async (): Promise<void> => {
    try {
      areAssistsGenerating.value = true;
      await finishConversationMutation();

      // Wait for assists to be generated, let up to 10 retries
      let i = 1;
      let isSummaryGenerated = false;
      let areTodoItemsGenerated = false;
      do {
        const { data: assists } = await fetchAssists();
        for (const { type, status } of assists ?? []) {
          if (isSummaryGenerated && areTodoItemsGenerated) {
            break;
          }

          if (status !== AIAssistStatus.FULFILLED) {
            continue;
          }

          if (type === AIAssistType.SUMMARY) {
            isSummaryGenerated = true;
          } else if (type === AIAssistType.TODO_ITEMS) {
            areTodoItemsGenerated = true;
          }
        }

        i++;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } while (i < 10 && (!isSummaryGenerated || !areTodoItemsGenerated));

      // Wait for todos to be generated, let up to 5 retries
      let refetchCount = 1;
      do {
        const { data: todos } = await refetchTodos();
        if (todos?.length) {
          break;
        }

        refetchCount++;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } while (refetchCount < 5);
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      areAssistsGenerating.value = false;
    }
  };

// TODO: Temporary solution, remove after fixing the cronjob
export const useFinishConversationManually =
  (): FinishConversationManuallyHook => {
    const { mutateAsync: finishConversationMutation } =
      useFinishCurrentConversationManually();
    const { refetch: fetchAssists } = useCurrentConversationAssists([
      AIAssistType.SUMMARY,
      AIAssistType.TODO_ITEMS,
    ]);
    const { refetchTodos } = useTodos();
    const finish = useFinish(
      finishConversationMutation,
      fetchAssists,
      refetchTodos,
    );

    return {
      areAssistsGenerating,
      finish,
    };
  };
