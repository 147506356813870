import Avatar from './Avatar.vue';
import Button from './Button.vue';
import Checkbox from './Checkbox.vue';
import OldCheckbox from './OldCheckbox.vue';
import Collapse from './Collapse.vue';
import Dropdown from './Dropdown.vue';
import Modal from './Modal.vue';
import RadioGroup from './RadioGroup.vue';
import Suspended from './Suspended.vue';
import TextEditable from './TextEditable.vue';
import MenuIcon from './MenuIcon.vue';
import ClosePopupButton from './ClosePopupButton.vue';
import DisabledContent from './DisabledContent.vue';
import LetterAvatar from './LetterAvatar.vue';

export default {
  Avatar,
  Button,
  Checkbox,
  // TODO: remove later
  OldCheckbox,
  Collapse,
  Dropdown,
  Modal,
  RadioGroup,
  Suspended,
  TextEditable,
  MenuIcon,
  ClosePopupButton,
  DisabledContent,
  LetterAvatar,
};
