<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { VDialog } from 'vuetify/components';
import { XIcon } from 'vue-tabler-icons';

withDefaults(
  defineProps<{
    isOpen?: boolean;
    closeOnClickOutside?: boolean;
    buttonCloseClass?: string;
  }>(),
  { closeOnClickOutside: true },
);

defineEmits<{ (e: 'close'): void }>();
</script>

<template>
  <VDialog
    :model-value="isOpen"
    :persistent="!closeOnClickOutside"
    @update:model-value="$emit('close')"
  >
    <div class="modal__container" :class="$attrs.class">
      <Icon
        :src="XIcon"
        :class="['modal__button-close', buttonCloseClass]"
        size="20"
        @click="$emit('close')"
      />
      <slot />
    </div>
  </VDialog>
</template>

<style scoped>
.modal__container {
  box-shadow: -2px 4px 12px #f1f5f9 !important;
  @apply max-w-[512px] max-h-[90vh] overflow-y-auto absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white p-0 rounded-xl;
}

.modal__button-close {
  @apply absolute z-50 right-5 top-5 text-slate-800 cursor-pointer hover:scale-110 active:scale-90;
}
</style>
