<script setup lang="ts">
import { useCurrentWorkspace } from '@/auth/use-workspace';
import { ref, onMounted, computed } from 'vue';
import { trackEvent } from '@/utils/analytics';
import { differenceInHours } from 'date-fns';
import Heading from '@/assets/notification_heading_trial.svg';
import { ROUTE_NAMES } from '@/constants/routeNames';
const { data: workspace } = useCurrentWorkspace();

const isShown = ref(
  localStorage.getItem('FreeTrialNotification') === workspace.value?.id,
);

const trialDaysLeft = computed(() => {
  if (!workspace.value?.trialEnd) {
    return null;
  }
  const finishTime = new Date(workspace.value.trialEnd);
  const currentTime = new Date();
  const trialDaysLeft = Math.ceil(
    differenceInHours(finishTime, currentTime) / 24,
  );
  return trialDaysLeft > 0 ? trialDaysLeft : 0;
});

const closeNotification = () => {
  isShown.value = false;
};

onMounted(() => {
  if (!isShown.value) {
    return;
  }
  trackEvent('Trial start dialog view', {
    workspace: workspace.value?.id,
  });
  localStorage.removeItem('FreeTrialNotification');
});
</script>

<template>
  <Modal
    data-testid="button-close-notification-x"
    :is-open="isShown"
    class="w-[969px] h-[626px] max-w-[969px] max-h-[626px]"
    button-close-class="text-white"
    @close="closeNotification"
  >
    <div class="h-full w-full grid grid-cols-2 overflow-y-auto">
      <div class="p-8 flex flex-col justify-between items-center">
        <div class="flex flex-col gap-6">
          <component :is="Heading" class="-translate-x-2" />
          <p class="text-lg">
            Enjoy all premium features of Noty.ai Business Plan:
          </p>
          <ul class="mt-2 flex flex-col gap-3">
            <li
              v-for="feature in [
                'Google Meet and Zoom transcription',
                'Highlights in 1 click',
                'Quick AI follow-ups',
                'ChatGPT-generated summarization',
                'Folders for better data management',
                'Transcript sharing and team collaboration',
              ]"
              :key="feature"
              class="flex gap-3 font-light"
            >
              <img
                src="https://storage.googleapis.com/noty-ai/webapp/lightning.webp"
                class="w-[13px] h-[26px]"
              />
              <span class="text-lg">{{ feature }}</span>
            </li>
          </ul>
        </div>
        <Link
          :to="{ name: ROUTE_NAMES.SETTINGS.PLANS }"
          data-testid="button-close-notification"
          class="w-full h-12 uppercase bg-primary rounded-xl flex items-center justify-center hover:bg-primary-dark active:scale-95 duration-300"
          @click="closeNotification"
        >
          Check out Noty Plans
        </Link>
      </div>
      <div
        class="px-4 pt-16 pb-12 bg-primary flex flex-col items-center justify-between text-white text-center"
      >
        <p>
          The free trial of the Business Plan lasts for the next
          {{ trialDaysLeft }} days.
        </p>
        <img
          src="https://storage.googleapis.com/noty-ai/webapp/trial_welcome.webp"
          class="w-[446px] h-[264px] select-none"
          alt="welcome"
        />
        <p>
          To continue using premium features, subscribe to our Business Plan and
          make your meetings 10x more productive.
        </p>
      </div>
    </div>
  </Modal>
</template>
