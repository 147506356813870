import { computed, ComputedRef, Ref, ref, watch } from 'vue';
import {
  WorkspaceConversationEndEmailTarget as MeetingRewindSetting,
  AllowedEndEmailTargetByPricing,
  WorkspacePricing,
} from '@/api/types';
import { useCurrentWorkspace } from '@/auth/use-workspace';
import { useUpdateCurrentWorkspace } from '@/api/workspaces';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface MeetingRewindOption {
  title: string;
  value: MeetingRewindSetting;
  isAvailable: boolean;
}

interface ManageMeetingRewindsHook {
  options: ComputedRef<MeetingRewindOption[]>;
  meetingRewindSetting: Ref<MeetingRewindSetting | null>;
  hasChanges: ComputedRef<boolean>;
  isSaving: Ref<boolean>;
  clear: () => void;
  save: () => Promise<void>;
}

const meetingRewindSetting = ref<MeetingRewindSetting | null>(null);
const isSaving = ref(false);

const useClear =
  (currentSetting: ComputedRef<MeetingRewindSetting | null>) => (): void => {
    meetingRewindSetting.value = currentSetting.value;
  };

const useSave =
  (
    updateCurrentWorkspaceMutation: ReturnType<
      typeof useUpdateCurrentWorkspace
    >['mutateAsync'],
  ) =>
  async (): Promise<void> => {
    if (!meetingRewindSetting.value) {
      return;
    }

    try {
      isSaving.value = true;
      await updateCurrentWorkspaceMutation({
        conversationEndEmailTarget: meetingRewindSetting.value,
      });
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isSaving.value = false;
    }
  };

export const useManageMeetingRewinds = (): ManageMeetingRewindsHook => {
  const { data: currentWorkspace } = useCurrentWorkspace();
  const currentWorkspaceSetting = computed(
    () =>
      <MeetingRewindSetting | null>(
        currentWorkspace.value?.conversationEndEmailTarget
      ) ?? null,
  );
  const clear = useClear(currentWorkspaceSetting);

  watch(currentWorkspaceSetting, clear);
  if (!meetingRewindSetting.value && currentWorkspaceSetting.value) {
    clear();
  }

  const hasChanges = computed(
    () => meetingRewindSetting.value !== currentWorkspaceSetting.value,
  );

  const { mutateAsync: updateCurrentWorkspaceMutation } =
    useUpdateCurrentWorkspace();
  const save = useSave(updateCurrentWorkspaceMutation);

  const options = computed<MeetingRewindOption[]>(() =>
    [
      {
        title: 'Everyone',
        value: MeetingRewindSetting.EVERYONE,
      },
      {
        title: 'Workspace domains',
        value: MeetingRewindSetting.WORKSPACE_DOMAINS,
      },
      {
        title: 'Nobody',
        value: MeetingRewindSetting.NOBODY,
      },
    ].map(({ title, value }) => ({
      title,
      value,
      isAvailable:
        AllowedEndEmailTargetByPricing[
          <WorkspacePricing>currentWorkspace.value?.pricing
        ].includes(value),
    })),
  );

  return {
    options,
    meetingRewindSetting,
    hasChanges,
    isSaving,
    clear,
    save,
  };
};
