<script setup lang="ts">
import { computed, ref } from 'vue';
import { CheckIcon, CopyIcon } from 'vue-tabler-icons';
import { copyToClipboard } from '@/utils/clipboard';

const props = withDefaults(
  defineProps<{
    value: string;
    variant?: 'outlined' | 'flat' | 'text';
    noIcon?: boolean;
  }>(),
  { variant: 'outlined', noIcon: false },
);

const isCopied = ref(false);

const statusText = computed(() => (isCopied.value ? 'Copied' : 'Copy'));

const clickHandler = () => {
  copyToClipboard(props.value);
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, 3000);
};
</script>

<template>
  <v-btn
    v-if="props.noIcon"
    :variant="props.variant"
    :color="isCopied ? 'green' : 'primary'"
    :disabled="isCopied"
    :text="statusText"
    class="text-sm"
    @click="clickHandler"
  />
  <v-tooltip v-else :text="statusText">
    <template #activator="{ props: tooltip }">
      <v-btn
        density="compact"
        color="primary"
        :variant="variant"
        :active="isCopied"
        v-bind="tooltip"
        aria-label="Copy"
        :icon="isCopied ? CheckIcon : CopyIcon"
        rounded
        @click="clickHandler"
      />
    </template>
  </v-tooltip>
</template>
