<script setup lang="ts">
import { ref, defineModel, watch } from 'vue';
import { syncRef } from '@vueuse/core';
import { SquareRoundedIcon, SquareRoundedCheckIcon } from 'vue-tabler-icons';

const model = defineModel<boolean | null>({
  required: false,
  default: null,
});
const emit = defineEmits<{ (e: 'change'): void }>();
const props = defineProps<{
  value?: boolean;
  manual?: boolean;
  disabled?: boolean;
  label?: string;
  iconClass?: string;
}>();

const localModel = ref(model.value ?? props.value);
syncRef(localModel, model, { direction: 'ltr' });

watch(
  () => props.value,
  (newValue) => {
    localModel.value = newValue;
  },
);

const toggle = () => {
  if (props.disabled) {
    return;
  }

  props.manual ? emit('change') : (localModel.value = !localModel.value);
};
</script>
<template>
  <label class="flex items-center gap-x-2 cursor-pointer" @click="toggle">
    <slot v-if="localModel" name="checked">
      <Icon :src="SquareRoundedCheckIcon" class="w-6 h-6" :class="iconClass" />
    </slot>
    <slot v-else name="unchecked">
      <Icon :src="SquareRoundedIcon" class="w-6 h-6" :class="iconClass" />
    </slot>
    <span v-if="label" class="typo-body1">
      {{ label }}
    </span>
    <slot v-else name="label" />
  </label>
</template>
