import { api } from '@/api';
import { router } from '@/router';
import { store } from '@/store';
import { showError, showMessage } from '@/utils/alert';
import type {
  SpaceUpdateData,
  InvitedPersonType,
  SpaceRole,
} from '@/api/types';
import { trackEvent } from '@/utils/analytics';
import { defaultSpaceColor } from '@/constants/spaceColors';
import { ROUTE_NAMES } from '@/constants/routeNames';

export const getSpace = (spaceId: string) => {
  return api.spaces.getSpace.query({ spaceId });
};

const folderNames = [
  'Professional',
  'Innovative',
  'Strategic',
  'Dynamic',
  'Efficient',
  'Productive',
  'Collaborative',
  'Insightful',
  'Resourceful',
  'Pioneering',
  'Optimize',
  'Empower',
  'Synergy',
  'Solutions',
  'Progress',
  'Growth',
  'Impact',
  'Excellence',
  'Influence',
  'Advantage',
  'Inspiration',
  'Achievements',
];
export const getRandomFolderName = () => {
  const randomIndex = Math.floor(Math.random() * folderNames.length);
  return `${folderNames[randomIndex]} Folder`;
};

/** @deprecated rewrite to hook */
export const createSpace = async (workspaceId: string, name?: string) => {
  try {
    const newSpace = await api.spaces.createSpace.mutate({
      workspaceId,
      spaceData: {
        name: name ?? getRandomFolderName(),
        color: defaultSpaceColor(),
      },
    });
    if (!newSpace) {
      return;
    }
    router.push({
      name: ROUTE_NAMES.SETTINGS.FOLDER,
      params: { workspaceId, spaceId: newSpace.id },
    });
  } catch (e) {
    showError(e as Error);
  }
};

/** @deprecated rewrite to hook */
export const deleteSpace = async (
  spaceId: string,
  deleteConversations: boolean,
) => {
  try {
    const deletedSpace = await api.spaces.deleteSpace.mutate({
      spaceId,
      deleteConversations,
    });
    if (!(store.currentWorkspace && deletedSpace)) {
      return;
    }
    showMessage('The folder has been deleted');
    store.currentWorkspace.spaces = store.currentWorkspace.spaces.filter(
      (space) => space.id !== deletedSpace.id,
    );
  } catch (e) {
    showError(e as Error);
  }
};

/** @deprecated rewrite to hook */
export const updateSpace = async (
  spaceId: string,
  spaceData: SpaceUpdateData,
) => {
  try {
    const updatedSpace = await api.spaces.updateSpace.mutate({
      spaceId,
      spaceData,
    });
    const targetSpaceIndex = store.currentWorkspace?.spaces.findIndex(
      (space) => space.id === updatedSpace.id,
    );
    if (!store.currentWorkspace || targetSpaceIndex === undefined) {
      throw Error('Folder not found');
    }
    store.currentWorkspace.spaces[targetSpaceIndex] = {
      ...store.currentWorkspace.spaces[targetSpaceIndex],
      ...updatedSpace,
    };
  } catch (e) {
    showError(e as Error);
  }
};

/** @deprecated rewrite to hook */
export const deleteSpaceAccess = async (spaceId: string, accountId: string) => {
  trackEvent('Space leave', { spaceId });
  try {
    await api.spaces.deleteSpaceAccess.mutate({ spaceId, accountId });
  } catch (e) {
    showError(e as Error);
    throw e;
  }
};

/** @deprecated rewrite to hook */
export const updateSpaceAccessRole = (
  spaceId: string,
  accountId: string,
  role: SpaceRole,
) => {
  return api.spaces.updateSpaceAccessRole.mutate({
    spaceId,
    accountId,
    role,
  });
};

/** @deprecated rewrite to hook */
export const inviteSpaceMembers = async (
  spaceId: string,
  invites: InvitedPersonType[],
) => {
  trackEvent('Space invite', {
    space: spaceId,
    email: invites.map(({ email }) => email).join(),
  });
  try {
    return await api.spaces.inviteSpaceMember.mutate({ spaceId, invites });
  } catch (e) {
    showError(e as Error);
    throw e;
  }
};
