import App from '@/App.vue';
import commonComponents from '@/components/common';
import { registerExtensionListeners } from '@/extension/listeners';
import '@/index.css';
import { router } from '@/router';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { vIntersectionObserver } from '@vueuse/components';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import '@/index.css';
import { setupCalendar } from 'v-calendar';
import { createApp } from 'vue';
import Vue3Toasity, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { vuetify } from './noty.theme';
import { initSentry } from './utils/analytics/sentry';
import { initQaTools } from './utils/qa-testing';

initQaTools();
registerExtensionListeners();

const app = createApp(App);

initSentry(app, router);

app
  .directive('intersection-observer', vIntersectionObserver)
  .use(router)
  .use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          staleTime: 1000,
          refetchOnWindowFocus: false,
        },
      },
    },
  })
  .use(vuetify)
  .use(setupCalendar)
  .use(Vue3Toasity, {
    autoClose: 3000,
    position: toast.POSITION.TOP_RIGHT,
    toastClassName: 'rounded-lg font-inter',
    bodyClassName: 'flex gap-2 break-normal',
  })
  .use(FloatingVue, {
    container: '#app',
  });

Object.entries(commonComponents).forEach(([name, component]) => {
  app.component(name, component);
});

app.mount('#app');
