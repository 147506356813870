const DEBUG_UPGRADE_ACCOUNT = '_debug_upgrade_account_feature';

export const initQaTools = () => {
  const url = new URL(window.location.href);
  initUpgradeAccountFlag(url);
};

const initUpgradeAccountFlag = (url: URL) => {
  if (url.searchParams.has(DEBUG_UPGRADE_ACCOUNT)) {
    const flag = url.searchParams.get(DEBUG_UPGRADE_ACCOUNT);
    if (flag === 'true' || flag === '1') {
      localStorage.setItem(DEBUG_UPGRADE_ACCOUNT, 'true');
    }
  }
};

export const getUpgradeAccountFlag = () =>
  localStorage.getItem(DEBUG_UPGRADE_ACCOUNT) === 'true';
