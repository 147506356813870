import { ref, computed } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { toDate } from 'date-fns';
import { useCurrentConversationId } from '@/store/hooks';
import { router } from '@/router';
import { api } from '@/api';
import {
  ReturnedBlock,
  BlockCreateData,
  BlockUpdateData,
  BlockType,
} from '@/api/types';

export const blocks: { value: Record<string, ReturnedBlock> } = ref({});
export const hoveredId = ref('');

export const clearConversationBlocks = () => {
  blocks.value = {};
};

export const fetchConversationBlocks = async (conversationId: string) => {
  const blocksRes = await api.blocks.getByConversation.query(
    { conversationId },
    { context: { skipBatch: true } },
  );
  if (blocksRes) {
    blocks.value = applyHighlights(blocksRes);
  }
};

function applyHighlights(blocks: ReturnedBlock[]) {
  const blocksWithAppliedHighlights = blocks
    .filter((block) => block.type === 'HIGHLIGHT')
    .reduce((acc, block) => {
      const parentId = block.parentId;
      if (!parentId) {
        return acc;
      }
      const parent = acc.find((block) => block.id === parentId);
      if (!parent) {
        return acc;
      }
      parent.highlight = block;
      return acc;
    }, blocks);

  return blocksWithAppliedHighlights.reduce((acc, block) => {
    return { ...acc, [block.id]: block };
  }, {});
}

export async function createBlock(
  conversationId: string,
  create: BlockCreateData,
) {
  const block = await api.blocks.createBlock.mutate({
    conversationId,
    ...create,
  });
  blocks.value[block.id] = block;
  if (block.parentId) {
    blocks.value[block.parentId].highlight = block;
  }
}

export async function updateBlock(blockId: string, blockData: BlockUpdateData) {
  blocks.value[blockId].text = blockData.text ?? blocks.value[blockId].text;
  const parentId = blocks.value[blockId].parentId;
  if (parentId) {
    const highlight = blocks.value[parentId].highlight;
    if (highlight) {
      highlight.text = blockData.text ?? highlight.text;
    }
  }
  await api.blocks.updateBlock.mutate({ blockId, blockData });
  return blocks.value[blockId];
}

export function deleteBlock(blockId: string) {
  const block = blocks.value[blockId];
  if (block.parentId) {
    delete blocks.value[block.parentId].highlight;
  }
  if (block.highlight) {
    delete blocks.value[block.highlight.id];
  }
  delete blocks.value[blockId];
  return api.blocks.deleteBlock.mutate({ blockId });
}

let scrollHandler: (id: string) => void;

export function onScroll(handler: (id: string) => void) {
  scrollHandler = handler;
}

export function scrollTranscript(blockId?: string | null) {
  if (!blockId || !scrollHandler) {
    return;
  }
  scrollHandler(blockId);
}

export const searchableText = computed(
  () => (router.currentRoute.value.query.search as string) || '',
);

export const useCurrentConversationBlocks = () => {
  const conversationId = useCurrentConversationId();
  return useQuery<ReturnedBlock[]>({
    enabled: computed(() => !!conversationId.value),
    queryKey: ['conversationBlocks', conversationId],
    queryFn: () =>
      api.blocks.getByConversation.query({
        conversationId: conversationId.value!,
        types: [
          BlockType.TRANSCRIPT_LINE,
          BlockType.CHAT_MESSAGE,
          BlockType.HIGHLIGHT,
        ],
      }),
  });
};

export const useCreateHighlight = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();

  const blocksQueryKey = ['conversationBlocks', conversationId];

  return useMutation({
    mutationFn: (dto: {
      blockId: NonNullable<BlockCreateData['parentId']>;
      speakerId: NonNullable<BlockCreateData['participantId']>;
    }) => {
      if (!conversationId.value) {
        throw new Error('Conversation id is required');
      }

      return api.blocks.createBlock.mutate({
        conversationId: conversationId.value,
        type: BlockType.HIGHLIGHT,
        parentId: dto.blockId,
        participantId: dto.speakerId,
        /**
         * idk if this is used but kept for now
         * in order not to break anything old
         * @todo remove this if not used
         */
        properties: { type: 'highlight' },
      });
    },
    onMutate: async ({ blockId }) => {
      await queryClient.cancelQueries({ queryKey: blocksQueryKey });

      let previousHistory: ReturnedBlock[] | undefined;
      queryClient.setQueryData(
        blocksQueryKey,
        (prev: ReturnedBlock[] | undefined) => {
          previousHistory = prev;
          return [
            ...(prev ?? []),
            <ReturnedBlock>{ parentId: blockId, type: BlockType.HIGHLIGHT },
          ];
        },
      );

      return { previousHistory };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(blocksQueryKey, context?.previousHistory);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['conversationBlocks']);
    },
  });
};

export const useDeleteBlock = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();

  const blocksQueryKey = ['conversationBlocks', conversationId];

  return useMutation({
    mutationFn: (blockId: ReturnedBlock['id']) =>
      api.blocks.deleteBlock.mutate({ blockId }),
    onMutate: async (blockId) => {
      await queryClient.cancelQueries({ queryKey: blocksQueryKey });

      let previousHistory: ReturnedBlock[] | undefined;
      queryClient.setQueryData(
        blocksQueryKey,
        (prev: ReturnedBlock[] | undefined) => {
          previousHistory = prev;
          return prev?.filter(({ id }) => id !== blockId);
        },
      );

      return { previousHistory };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(blocksQueryKey, context?.previousHistory);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['conversationBlocks']);
    },
  });
};

interface UpdateBlockDto {
  blockId: ReturnedBlock['id'];
  data: BlockUpdateData;
}

export const useUpdateBlock = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();

  const blocksQueryKey = ['conversationBlocks', conversationId];

  return useMutation({
    mutationFn: ({ blockId, data }: UpdateBlockDto) =>
      api.blocks.updateBlock.mutate({
        blockId,
        blockData: data,
      }),
    onMutate: async ({ blockId, data }) => {
      const { startTime, endTime, ...restData } = data;
      await queryClient.cancelQueries({ queryKey: blocksQueryKey });

      let previousHistory: ReturnedBlock[] | undefined;
      queryClient.setQueryData(
        blocksQueryKey,
        (prev: ReturnedBlock[] | undefined) => {
          previousHistory = prev;
          return prev?.map((block) =>
            block.id === blockId
              ? {
                  ...block,
                  ...restData,
                  ...(startTime && {
                    startTime: toDate(startTime).toISOString(),
                  }),
                  ...(endTime && { endTime: toDate(endTime).toISOString() }),
                }
              : block,
          );
        },
      );

      return { previousHistory };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(blocksQueryKey, context?.previousHistory);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['conversationBlocks']);
    },
  });
};
