import { ERRORS } from '@/constants/errors';
import { toast, ToastType, type ToastOptions } from 'vue3-toastify';
import { isString, safeParse } from '@/utils';

const notify = (text: string, type: ToastType, options?: ToastOptions) => {
  toast(text, {
    type,
    ...options,
  });
};

export function showMessage(message: string, options?: ToastOptions) {
  notify(message, 'success', options);
}

const getPrettyError = (code: string): string =>
  ({
    [ERRORS.AUTH_REQUIRED]:
      'Authentication Required: Please sign in to access this feature.',
    'conversation.transcript.empty': 'No transcript available',
    'workspace.admin.missing':
      'Workspace should have an admin to approve the upgrade',
    'account.role.mismatch':
      'Only accounts with LITE role could request upgrade',
    'account.access.restricted': 'No such account in the workspace',
    'workspace.plan.paid_required':
      'Contact workspace admin to upgrade your subscription plan',
    'account.calendar.already_exists':
      'The calendar is already connected to the current workspace. Try to resync',
  })[code] ?? code;

export function showError(error: string | Error) {
  let message = 'Error';
  if (isString(error)) {
    message = error;
  } else if (isString(error?.message)) {
    message = safeParse(error.message)?.[0]?.message || error.message;
  }
  notify(getPrettyError(message), 'error');
}
