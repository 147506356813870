import { computed } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { api } from '@/api';
import type {
  ConversationMembersInputData,
  ConversationInputQuery,
  ConversationGetBySearchQueryInput,
  ConversationUpdateInput,
} from '@/api/types';
import { store } from '@/store';
import { useCurrentConversationId } from '@/store/hooks';
import { showError, showMessage } from '@/utils/alert';
import { trackEvent } from '@/utils/analytics';

export const getConversationsByQuery = ({
  workspaceId,
  skip = 0,
  take,
  spaceId,
  personIds,
  minTime,
  maxTime,
  meetingIds,
}: ConversationInputQuery) => {
  const query: ConversationInputQuery = {
    workspaceId,
    skip,
    take,
    ...(spaceId && { spaceId }),
    ...(minTime && { minTime }),
    ...(maxTime && { maxTime }),
    ...(personIds && { personIds }),
    ...(meetingIds && { meetingIds }),
  };

  return api.conversations.getByQuery.query(query, { skipBatch: true });
};

export const getConversationsBySearchQuery = async (
  params: ConversationGetBySearchQueryInput,
) => {
  try {
    return await api.conversations.getBySearchQuery.query(params);
  } catch (e) {
    showError(e as Error);
  }
};

export const deleteConversation = (conversationId: string) => {
  return api.conversations.delete.mutate({ conversationId });
};

export const updateConversation = async (data: ConversationUpdateInput) => {
  try {
    const conversation = await api.conversations.update.mutate(data);
    if (!(store.currentConversation && conversation)) {
      return;
    }
    store.currentConversation = {
      ...store.currentConversation,
      ...conversation,
    };
  } catch (e) {
    showError(e as Error);
  }
};

export const getConversationAccessMembers = async (conversationId: string) => {
  try {
    const invitedMembers = await api.conversations.getConversationAccess.query({
      conversationId,
    });
    return invitedMembers.map((member) => {
      member.invited = true;
      return member;
    });
  } catch (e) {
    showError(e as Error);
  }
};

export const deleteConversationAccessMember = async (
  conversationId: string,
  personId: string,
) => {
  trackEvent('Remove member from conversation', { conversationId, personId });
  try {
    await api.conversations.deleteConversationAccess.mutate({
      conversationId,
      personId,
    });
    showMessage('Success');
  } catch (e) {
    showError(e as Error);
  }
};

export const inviteConversationAccessMembers = async (
  conversationId: string,
  membersData: ConversationMembersInputData,
) => {
  try {
    await api.conversations.createConversationAccess.mutate({
      conversationId,
      ...membersData,
    });
  } catch (e) {
    showError(e as Error);
  }
};

export const useCurrentConversation = () => {
  const conversationId = useCurrentConversationId();

  return useQuery({
    enabled: computed(() => !!conversationId.value),
    queryKey: ['currentConversation', conversationId],
    queryFn: async () => {
      const [conversation, permissions] = await Promise.all([
        api.conversations.getPresentationById.query({
          conversationId: conversationId.value!,
        }),
        api.conversations.canEdit.query({
          conversationId: conversationId.value!,
        }),
      ]);

      if (!conversation) {
        throw new Error('Conversation not found');
      }

      return { ...conversation, ...permissions };
    },
  });
};

export const useUpdateCurrentConversation = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();

  return useMutation({
    mutationFn: (dto: Omit<ConversationUpdateInput, 'conversationId'>) => {
      if (!conversationId.value) {
        throw new Error('Conversation id is required');
      }

      return api.conversations.update.mutate({
        ...dto,
        conversationId: conversationId.value,
      });
    },
    onSuccess: () => queryClient.invalidateQueries(['currentConversation']),
  });
};

export const useDeleteCurrentConversation = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();

  return useMutation({
    mutationFn: () => {
      if (!conversationId.value) {
        throw new Error('Conversation id is required');
      }

      return api.conversations.delete.mutate({
        conversationId: conversationId.value,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['conversations']);
      queryClient.invalidateQueries(['currentConversation']);
    },
  });
};

export const useFinishCurrentConversationManually = () => {
  const queryClient = useQueryClient();
  const conversationId = useCurrentConversationId();

  return useMutation({
    mutationFn: () => {
      if (!conversationId.value) {
        throw new Error('Conversation id is required');
      }

      return api.conversations.finishManually.mutate({
        conversationId: conversationId.value,
      });
    },
    onSuccess: () => queryClient.invalidateQueries(['currentConversation']),
  });
};
