<script setup lang="ts">
import { computed } from 'vue';
import { useUser } from '@/auth/use-session';

const { data: user } = useUser();
const name = computed(() => {
  const fb = 'user';
  return user.value ? user.value.name || user.value.email || fb : fb;
});
</script>
<template>
  <div v-if="user" class="flex items-center gap-x-2">
    <LetterAvatar
      :name="name"
      :image="user?.avatarUrl ?? ''"
      :image-props="{ cover: true }"
    />
    <span class="typo-body2 font-semibold">{{ name }}</span>
  </div>
</template>
