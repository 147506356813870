<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { PencilIcon } from 'vue-tabler-icons';
import { showError } from '@/utils/alert';
import { purifyHTML } from '@/utils/purifyHTML';

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'onEnter'): void;
}>();

const props = withDefaults(
  defineProps<{
    modelValue: string;
    defaultValue?: string;
    placeholder?: string;
    showIcon?: boolean;
    readonly?: boolean;
    minLimit?: number;
    maxLimit?: number;
    immediate?: boolean;
    allowMultilines?: boolean;
    highlightedText?: string;
  }>(),
  { minLimit: 2 },
);

const contentEditable = ref();
const isEditing = ref(false);

const showHighlightedContent = ref(!!props.highlightedText);

watch(props, ({ highlightedText }) => {
  showHighlightedContent.value = !!highlightedText;
});

const hideHighlightedContent = () => {
  showHighlightedContent.value = false;
  setTimeout(() => {
    /* set cursor position to the end */
    contentEditable.value.focus();
    selectContents(contentEditable.value, props.modelValue.length);
  });
};

const highlightedContent = computed(() => {
  if (!props.highlightedText || !props.modelValue) {
    return;
  }

  const highlightedContent = props.modelValue.replace(
    new RegExp(props.highlightedText, 'gi'),
    '<mark class="bg-secondary font-medium">$&</mark>',
  );
  return purifyHTML(highlightedContent, { mark: ['class'] });
});

const startEditing = () => {
  if (isEditing.value) {
    return;
  }
  isEditing.value = true;
  selectContents(contentEditable.value);
};

const endEditing = (validate: FocusEvent | boolean = true) => {
  const text = contentEditable.value.innerText.trim();

  if (validate) {
    isEditing.value = false;
    if (text.length < props.minLimit) {
      showError(`Text is required at least ${props.minLimit} characters`);
      //set default value if exist or previous valid value:
      if (props.defaultValue) {
        emit('update:modelValue', props.defaultValue);
      } else {
        contentEditable.value.innerText = props.modelValue;
      }
      return;
    }
  }

  if (text !== props.modelValue) {
    emit('update:modelValue', text);
  }
  showHighlightedContent.value = !!props.highlightedText;
};

const onChange = (event: KeyboardEvent) => {
  if (event.key === 'Enter' && !props.allowMultilines) {
    event.preventDefault();
    return;
  }

  const text = (event.target as HTMLElement).innerText;
  const selectedSubtext = window.getSelection()?.toString() || '';

  if (!props.maxLimit) {
    return;
  }

  const neededSpace = text.length - selectedSubtext.length;
  if (
    (text.length >= props.maxLimit && !selectedSubtext) ||
    neededSpace > props.maxLimit
  ) {
    event.preventDefault();
    showError(`Text is limited to ${props.maxLimit} characters`);
  }
};

const onPaste = (event: ClipboardEvent) => {
  const text = (event.target as HTMLElement).innerText;
  const selectedSubtext = window.getSelection()?.toString() || '';
  const pastedText = event.clipboardData?.getData('text/plain') || '';
  const cursorPosition = Math.min(
    window.getSelection().anchorOffset,
    window.getSelection().focusOffset,
  );

  const newText =
    text.slice(0, cursorPosition) +
    pastedText +
    text.slice(cursorPosition + selectedSubtext.length);

  if (newText.length < props.minLimit) {
    showError(`Text is required at least ${props.minLimit} characters`);
    return;
  }
  if (props.maxLimit && props.maxLimit < newText.length) {
    showError(`Text is limited to ${props.maxLimit} characters`);
    return;
  }

  contentEditable.value.innerText = newText;
  selectContents(contentEditable.value, cursorPosition + pastedText.length); //set new cursor position
};

function selectContents(event: Event | Node, position?: number) {
  const targetEvent = 'target' in event ? (event.target as Node) : event;
  const range = document.createRange();
  if (position) {
    // Set cursor at provided position
    range.setStart(targetEvent.childNodes[0], position);
    range.collapse(true);
  } else {
    // Select whole content
    range.selectNodeContents(targetEvent);
  }
  const sel = window.getSelection();
  sel?.removeAllRanges();
  sel?.addRange(range);
}

const onEnterHandler = () => {
  if (props.allowMultilines) {
    return;
  }
  contentEditable.value.blur();
  emit('onEnter');
};
</script>

<template>
  <div
    :class="['wrapper', { 'hover:shadow-stroke': !isEditing && !readonly }]"
    @click="!readonly && startEditing"
  >
    <p
      v-if="showHighlightedContent && highlightedContent"
      @click="hideHighlightedContent"
      v-html="highlightedContent"
    />
    <p
      v-else
      ref="contentEditable"
      :contenteditable="!readonly"
      class="editable"
      spellcheck="false"
      @focus.stop="isEditing = true"
      @blur="endEditing"
      @keypress.enter="onEnterHandler"
      @keypress="onChange"
      @paste.prevent="onPaste"
      @keyup="immediate && endEditing(false)"
      v-text="!modelValue && !isEditing ? placeholder : modelValue"
    />
    <Icon
      v-if="showIcon"
      :src="PencilIcon"
      size="20"
      class="mt-0.5"
      :stroke-width="1.5"
    />
  </div>
</template>

<style scoped>
.wrapper {
  @apply rounded-lg p-1 cursor-text shadow-subtle flex gap-2;
}

.editable[contenteditable] {
  @apply outline-none break-all;
}
</style>
