import { ref, Ref } from 'vue';

interface SidebarSizeHook {
  isCollapsed: Ref<boolean>;
  toggle: () => void;
}

const isCollapsed = ref(false);

export const useSidebarSize = (): SidebarSizeHook => {
  const toggle = (): void => {
    isCollapsed.value = !isCollapsed.value;
  };

  return {
    isCollapsed,
    toggle,
  };
};
