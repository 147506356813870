import { ref, onUnmounted, Ref, UnwrapRef } from 'vue';
import { startOfDay, endOfDay } from 'date-fns';

interface SelectedDatesHook {
  selectedDates: Ref<[fromDate: Date, toDate: Date] | null>;
  handleDateChange: (newValue: Date | Date[] | null) => void;
}

const selectedDates = ref<UnwrapRef<SelectedDatesHook['selectedDates']>>(null);

const handleDateChange: SelectedDatesHook['handleDateChange'] = (newValue) => {
  if (
    Array.isArray(newValue) &&
    newValue[0] instanceof Date &&
    newValue[1] instanceof Date
  ) {
    selectedDates.value = [startOfDay(newValue[0]), endOfDay(newValue[1])];
  } else {
    selectedDates.value = null;
  }
};

export const useDateFilter = (): SelectedDatesHook => {
  onUnmounted(() => (selectedDates.value = null));

  return {
    selectedDates,
    handleDateChange,
  };
};
