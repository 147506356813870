import { ref, Ref, onUnmounted } from 'vue';
import { useQuery, UseQueryReturnType } from '@tanstack/vue-query';
import { Person } from '@/api/types';
import { getWorkspacePeople } from '@/api/workspaces';
import { useCurrentWorkspaceId } from '@/auth/use-workspace';
import { useActiveSpace } from '../hooks';

type PersonData = Pick<Person, 'id' | 'name' | 'email' | 'avatarUrl'>;

interface ParticipantsFilterHook {
  query: UseQueryReturnType<PersonData[], unknown>;
  selectedParticipants: Ref<string[]>;
}

const selectedParticipants = ref<string[]>([]);

export const useParticipantsFilter = (): ParticipantsFilterHook => {
  onUnmounted(() => (selectedParticipants.value = []));

  const workspaceId = useCurrentWorkspaceId();
  const activeSpace = useActiveSpace();

  const query = useQuery<PersonData[]>({
    queryKey: ['participants', workspaceId, activeSpace],
    queryFn: async () => {
      const participants = await getWorkspacePeople(
        workspaceId.value,
        activeSpace.value?.id,
      );
      return participants ?? [];
    },
    staleTime: 60000,
  });

  return {
    query,
    selectedParticipants,
  };
};
