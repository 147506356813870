import { ComputedRef, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useCurrentWorkspace } from '@/auth/use-workspace';
import { Folder } from '@/api/types';

export const useActiveSpace = (): ComputedRef<Folder | null> => {
  const { data: workspace } = useCurrentWorkspace();
  const route = useRoute();

  return computed(() =>
    route.query.spaceId
      ? (workspace.value?.spaces?.find(
          (space) => space.id === route.query.spaceId,
        ) ?? null)
      : null,
  );
};
