<script setup lang="ts">
import { reactive, watch } from 'vue';
import { XIcon } from 'vue-tabler-icons';
import { isEmail } from '@/utils';

const props = withDefaults(
  defineProps<{
    rules:
      | (([T]: string[] | string) => string | true)[]
      | (([T]: string[]) => string | true)[];
    modelValue: string[];
    isLoading?: boolean;
    placeholder?: string;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
    isLoading: false,
    placeholder: 'Type emails to invite new teammates...',
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>();

watch(
  () => props.modelValue,
  (newValue: string[]) => {
    state.emails = newValue;
  },
);

const state = reactive({
  items: [], //always empty, used for v-combobox initialization
  emails: props.modelValue,
});

function removeEmail(email: string) {
  emit(
    'update:modelValue',
    state.emails.filter((el) => el !== email),
  );
}
</script>

<template>
  <v-combobox
    ref="input"
    v-model="state.emails"
    validate-on="blur"
    :items="state.items"
    variant="outlined"
    multiple
    :placeholder="props.placeholder"
    class="basis-full max-h-[200px] overflow-scroll"
    :rules="rules"
    :disabled="disabled"
    :loading="isLoading"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #selection="{ attrs, item, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :input-value="selected"
        label
        small
        class="bg-primary-1 border rounded-sm mr-[1px] max-h-6"
        :class="isEmail(item.value) ? 'text-primary' : 'text-alerts-error'"
      >
        <span class="pr-2">
          {{ item.value }}
        </span>
        <Icon
          class="cursor-pointer hover:text-alerts-error"
          size="16"
          :src="XIcon"
          @click="removeEmail(item.title)"
        />
      </v-chip>
    </template>
  </v-combobox>
</template>

<style scoped>
.v-combobox :deep(.v-input__control) .v-field__input input {
  @apply my-1 text-sm;
}
</style>
