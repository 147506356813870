<script setup lang="ts">
import { trackEvent } from '@/utils/analytics';
import { useIsOnboardingProcess } from '@/utils/onboarding';
import { useStorage } from '@vueuse/core';
import { ref, watch } from 'vue';
import { XIcon } from 'vue-tabler-icons';
import { useDisplay } from 'vuetify';

const model = ref<boolean>(false);
const isOnboarding = useIsOnboardingProcess();
const isShown = useStorage('mobile-under-dev', false);
const { smAndDown } = useDisplay();

watch(
  () => ({ onboarding: isOnboarding.value, shown: isShown.value }),
  ({ onboarding, shown }) => {
    if (!smAndDown.value || onboarding || shown) {
      return;
    }
    model.value = true;
    trackEvent('Mobile popup shown');
  },
  { immediate: true },
);

const close = () => {
  trackEvent('Mobile popup closed');
  isShown.value = true;
  model.value = false;
};
</script>

<template>
  <v-dialog v-model="model" :persistent="true" width="auto" max-width="380px">
    <v-card class="rounded-3xl p-6 pt-4 text-center align-center">
      <v-img
        class="w-[126px] h-[126px] center"
        src="https://storage.googleapis.com/noty-ai/webapp/popup/Group%201544.png"
      />
      <v-btn
        :icon="XIcon"
        aria-label="Close"
        class="absolute top-2 right-2"
        variant="flat"
        @click="close"
      />
      <h1 class="mt-8">Thank you for joining Noty</h1>
      <p class="typo-body1 my-8">
        Our mobile version is still under development. Please log in on your
        desktop to use Noty.
      </p>
      <v-card-actions class="align-center flex justify-center">
        <v-btn variant="flat" color="primary" class="typo-h3" @click="close">
          Got it
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
