import { ref, Ref } from 'vue';
import { useDeleteAccount } from '@/api/accounts';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface LeaveCurrentWorkspaceHook {
  isLeaveCurrentWorkspaceConfirmationOpen: Ref<boolean>;
  isLeaving: Ref<boolean>;
  toggleLeaveCurrentWorkspaceConfirmation: () => void;
  leaveCurrentWorkspace: () => Promise<void>;
}

const isLeaveCurrentWorkspaceConfirmationOpen = ref(false);
const isLeaving = ref(false);

const toggleLeaveCurrentWorkspaceConfirmation = (): void => {
  isLeaveCurrentWorkspaceConfirmationOpen.value =
    !isLeaveCurrentWorkspaceConfirmationOpen.value;
};

const useLeave =
  (deleteAccountMutation: ReturnType<typeof useDeleteAccount>['mutateAsync']) =>
  async (): Promise<void> => {
    try {
      isLeaving.value = true;
      await deleteAccountMutation();
      isLeaveCurrentWorkspaceConfirmationOpen.value = false;
      // Redirect home with reload
      location.replace('/');
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isLeaving.value = false;
    }
  };

export const useLeaveCurrentWorkspace = (): LeaveCurrentWorkspaceHook => {
  const { mutateAsync: deleteAccountMutation } = useDeleteAccount();
  const leaveCurrentWorkspace = useLeave(deleteAccountMutation);

  return {
    isLeaveCurrentWorkspaceConfirmationOpen,
    isLeaving,
    toggleLeaveCurrentWorkspaceConfirmation,
    leaveCurrentWorkspace,
  };
};
