import { ref, Ref } from 'vue';
import { useGetFoldersByWorkspaceId } from '@/api/folders';
import { FolderWithConversations } from '@/api/types';
import { useManageFolder } from '@/components/settings/Folders/hooks';

interface FoldersMenuHook {
  folders: Ref<FolderWithConversations[] | undefined>;
  openedPanel: Ref<0 | undefined>;
  isReadonly: Ref<boolean>;
  toggleReadonlyState: () => void;
  createFolder: () => void;
}

/**
 * @remarks indicates which expansion panel is open:
 * `0` - first (and the only one),
 * `undefined` - closed
 */
const openedPanel = ref<0 | undefined>();

/**
 * @remarks a trick to prevent the expansion panel
 * from state toggling
 */
const isReadonly = ref(false);

export const useFoldersMenu = (): FoldersMenuHook => {
  const { data: folders } = useGetFoldersByWorkspaceId();
  const { open: createFolder } = useManageFolder();

  const toggleReadonlyState = () => {
    isReadonly.value = !isReadonly.value;
  };

  return {
    folders,
    openedPanel,
    isReadonly,
    toggleReadonlyState,
    // explicitly bind `undefined` in order to prevent
    // mistakenly passing `event` as the first argument
    createFolder: createFolder.bind(null, undefined),
  };
};
