import { getCustomToken } from '@/api/auth';
import { store } from '@/store';
import { trackEvent } from '@/utils/analytics';
import { User } from '@supabase/supabase-js';
import { registerConfigListener } from './config';

export let EXTENSION_INSTALLED = false;

type EXTENSION_MESSAGE_TYPE =
  | 'refresh-extension-token'
  | 'refresh-extension-session'
  | 'VERSION'
  | 'CONFIG'
  | 'SYNC_USER';

export function onExtensionMessage(
  type: EXTENSION_MESSAGE_TYPE,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (data: any) => void,
) {
  window.addEventListener('message', (event) => {
    if (
      event.origin === location.origin &&
      event.data.sender === 'NOTY_EXTENSION' &&
      event.data.type === type
    ) {
      callback(event.data.data);
    }
  });
}

type Type =
  | 'AUTH'
  | 'VERSION'
  | 'UPDATE_CONFIG'
  | 'REFRESH_TOKEN'
  | 'push-token-to-extension'
  | 'push-session-to-extension';

type Data = {
  AUTH: { refresh_token: string } | null;
  REFRESH_TOKEN: string;
  VERSION: string;
  UPDATE_CONFIG: { gmeetEnabled: boolean };
  'push-token-to-extension': string;
  'push-session-to-extension': { access_token: string; refresh_token: string };
};

export function sendMessageToExtension<T extends Type>(type: T, data: Data[T]) {
  window.postMessage({ type, data, sender: 'NOTY_WEBAPP' });
}

/** @deprecated */
let extensionAuth: User | null;

/** @deprecated */
async function syncExtensionAuth() {
  console.warn(
    'syncExtensionAuth method is going to be removed. Please contact yuriy@noty.ai if you see this message',
  );
  const webappUser = store.user;

  if (
    (!extensionAuth && webappUser) ||
    webappUser?.email !== extensionAuth?.email
  ) {
    sendMessageToExtension('AUTH', await getCustomToken());
    trackEvent('Extension sign in', {});
    return;
  }
  if (extensionAuth && !webappUser) {
    sendMessageToExtension('AUTH', null);
    extensionAuth = webappUser ?? null;
    return;
  }

  /* if webapp and extension user don't match */
  console.log('WEBAPP SYNC', webappUser, extensionAuth);
  if (webappUser?.email !== extensionAuth?.email) {
    if (webappUser) {
      console.log('WEBAPP AUTH TOKEN');
      sendMessageToExtension('AUTH', await getCustomToken());
      trackEvent('Extension sign in', {});
    } else {
      trackEvent('Sign in extension failed', {});
      console.log('WEBAPP AUTH NULL');
      sendMessageToExtension('AUTH', null);
    }
    extensionAuth = webappUser ?? null;
  }
}

export function registerExtensionListeners() {
  onExtensionMessage('VERSION', (data) => {
    EXTENSION_INSTALLED = true;
    window.localStorage.setItem('EXTENSION_VERSION', data);
    sendMessageToExtension('VERSION', window.WEBAPP_VERSION);
  });

  /** feels like can be removed */
  onExtensionMessage('SYNC_USER', (user) => {
    console.log('onExtensionMessage SYNC_USER');
    console.warn(
      'onExtensionMessage SYNC_USER method is going to be removed. Please contact yuriy@noty.ai if you see this message',
    );
    extensionAuth = user;
    syncExtensionAuth();
  });

  registerConfigListener();

  /* remove listener and related items after extension 1.55 release */
  onExtensionMessage('refresh-extension-token', async () => {
    console.log('Extension refresh');
    const { refresh_token } = await getCustomToken();
    sendMessageToExtension('push-token-to-extension', refresh_token);
  });

  onExtensionMessage('refresh-extension-session', async () => {
    console.log('Extension sign in');
    const session = await getCustomToken();
    sendMessageToExtension('push-session-to-extension', session);
  });
}
