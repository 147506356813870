import type { Identifiable } from '@/api/types';

const colors = [
  'red',
  'green-bright-secondary',
  'blue-secondary',
  'yellow',
  'orange',
  'pink',
];

const peopleColors = new Map<string, string>();

function getRandomColor(uuid: string): string {
  const hashCode = uuid
    .split('')
    .reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);

  return colors[Math.abs(hashCode) % colors.length];
}

export function borderColor(person: Identifiable) {
  if (!peopleColors.has(person.id)) {
    peopleColors.set(person.id, getRandomColor(person.id || '777'));
  }
  return `border-${peopleColors.get(person.id)}-300`;
}

export function bgColor(person: Identifiable) {
  if (!peopleColors.has(person.id)) {
    peopleColors.set(person.id, getRandomColor(person.id || '777'));
  }
  return `bg-${peopleColors.get(person.id)}-300`;
}
const colorsMap = new Map<string, string>();
export function getColor(id: string) {
  if (!colorsMap.has(id)) {
    colorsMap.set(id, getRandomColor(id || '777'));
  }
  return `${colorsMap.get(id)}`;
}
