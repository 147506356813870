import { computed, ref, Ref, ComputedRef, watch } from 'vue';
import {
  LanguageTitleMap,
  LangCode,
} from 'trpc_backend/src/domain/conversations/languages';
import { useUser } from '@/auth/use-session';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';
import { useUpdateUserData } from '@/api/users';

interface GmeetLangHook {
  showLangsAutocomplete: Ref<boolean>;
  isUpdating: Ref<boolean>;
  langCode: Ref<LangCode | undefined>;
  langTitle: ComputedRef<string | undefined>;
  updateLang: (lang: LangCode) => Promise<void>;
}

const showLangsAutocomplete = ref(false);
const isUpdating = ref(false);
const langCode = ref<LangCode | undefined>(undefined);

const useUpdateLang =
  (
    updateUserDataMutation: ReturnType<typeof useUpdateUserData>['mutateAsync'],
  ) =>
  async (language: LangCode | null) => {
    if (!language) {
      return;
    }

    try {
      isUpdating.value = true;
      await updateUserDataMutation({
        settings: { conversationCapture: { GMEET: { language } } },
      });
      showLangsAutocomplete.value = false;
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isUpdating.value = false;
    }
  };

export const useGmeetLang = (): GmeetLangHook => {
  const { data: user } = useUser();
  // Do not rewrite the language code if it is being updated
  if (!isUpdating.value) {
    langCode.value = user.value?.settings?.conversationCapture.GMEET.language;
  }
  watch(user, (newUser) => {
    langCode.value = newUser?.settings?.conversationCapture.GMEET.language;
  });

  const langTitle = computed(() => LanguageTitleMap[<LangCode>langCode.value]);

  const { mutateAsync: updateUserDataMutation } = useUpdateUserData();
  const updateLang = useUpdateLang(updateUserDataMutation);

  return {
    showLangsAutocomplete,
    langCode,
    langTitle,
    isUpdating,
    updateLang,
  };
};
