<script setup lang="ts">
const emit = defineEmits<{ (e: 'upload', data: string): void }>();

const onChangeHandler = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;

  if (!files?.length) {
    return;
  }

  toBase64(files[0]).then((data) => {
    emit('upload', data);
  });
};

function toBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    // the docs say that result should always be a string, but double-check for future-proofing
    reader.onload = () =>
      typeof reader.result === 'string'
        ? resolve(reader.result)
        : reject('Unexpected type received from FileReader');
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}
</script>

<template>
  <label for="file-upload">
    <input
      id="file-upload"
      class="hidden"
      type="file"
      accept="image/*"
      @change="onChangeHandler"
    />
    <slot />
  </label>
</template>
