<script setup lang="ts">
import { ref, computed } from 'vue';
import { LoaderIcon, XIcon, ChevronDownIcon } from 'vue-tabler-icons';
import { isString } from '@/utils';

interface SelectOptionType {
  id: string;
  email: string;
  name?: string;
  color?: string;
  avatarUrl?: string;
}

const props = withDefaults(
  defineProps<{
    modelValue?: SelectOptionType | SelectOptionType['id'] | null;
    options?: SelectOptionType[];
    placeholder?: string;
    loading?: boolean;
  }>(),
  {
    placeholder: 'Select option',
    options: () => [],
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: SelectOptionType | null): void;
}>();

const isCollapseOpen = ref(false);

const modelValueName = computed(() => {
  if (!props.modelValue) {
    return props.placeholder;
  }
  if (isString(props.modelValue)) {
    const activeOption = props.options.find(
      (option) => option.id === props.modelValue,
    );
    return activeOption?.name || activeOption?.email;
  }
  return props.modelValue.name || props.modelValue.email;
});

const changeValue = (newValue: SelectOptionType | null) => {
  isCollapseOpen.value = false;
  emit('update:modelValue', newValue);
};
</script>

<template>
  <Collapse
    :is-open="isCollapseOpen"
    :accordion="false"
    class="z-30"
    @toggle="isCollapseOpen = !isCollapseOpen"
  >
    <template #header>
      <div
        class="collapse-header"
        :class="[
          { 'cursor-not-allowed bg-slate-200': !options.length },
          { 'cursor-progress': props.loading },
        ]"
      >
        <div v-tooltip="modelValueName" class="flex items-center gap-2">
          <Avatar
            v-if="props.modelValue"
            :person="props.modelValue"
            size="6"
            class="shadow-stroke text-black"
          />
          <h3
            class="ellipsis-line max-w-[160px] typo-body1"
            :class="[
              { 'text-black': props.modelValue },
              { 'text-slate-500': !props.modelValue },
            ]"
          >
            {{ modelValueName }}
          </h3>
        </div>
        <Icon
          v-if="props.loading"
          :src="LoaderIcon"
          class="animate-spin"
          size="18"
        />
        <div
          v-else
          class="flex items-center justify-between gap-1 text-slate-500"
        >
          <Icon
            v-show="props.modelValue"
            :src="XIcon"
            class="hover:rotate-90 hover:text-red-500 active:scale-90"
            size="18"
            @click.stop="changeValue(null)"
          />
          <Icon
            :src="ChevronDownIcon"
            :class="{ '-rotate-180': isCollapseOpen }"
            size="20"
          />
        </div>
      </div>
    </template>
    <template #content>
      <ul
        v-if="options.length"
        class="w-64 mt-0.5 p-1 rounded-2xl border border-solid border-slate-200 bg-white flex flex-col gap-1 max-h-64 overflow-y-auto overflow-x-hidden"
      >
        <li
          v-for="option in options"
          :key="option.id"
          class="option"
          :class="[
            {
              'option--active':
                option.id ===
                (isString(props.modelValue)
                  ? props.modelValue
                  : props.modelValue?.id),
            },
          ]"
          @click.stop="changeValue(option)"
        >
          <Avatar :person="option" size="6" class="shadow-stroke" />
          <span class="ellipsis-line">{{ option.name || option.email }}</span>
        </li>
      </ul>
    </template>
  </Collapse>
</template>

<style scoped>
.collapse-header {
  @apply w-64 py-2 px-3 bg-white rounded-2xl border border-solid border-slate-200 flex items-center justify-between cursor-pointer;
}

.option {
  @apply py-2 px-3 rounded-2xl h-fit min-h-fit border-0 text-black normal-case cursor-pointer hover:bg-purple-100 active:scale-95 duration-100;
  @apply flex justify-start items-center gap-2 flex-nowrap;
}

.option--active {
  @apply bg-purple-200;
}
</style>
