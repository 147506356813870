import { api } from '@/api';
import { showError } from '@/utils/alert';
import { trackEvent } from '@/utils/analytics';
import type { InvitedPersonType, MemberType } from '@/api/types';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useUserId } from '@/auth/use-session';
import { useCurrentWorkspace } from '@/auth/use-workspace';

export const deleteAccount = async (accountId: string, workspace: string) => {
  trackEvent('Workspace leave', { workspace });
  try {
    await api.accounts.deleteAccount.mutate({ accountId });
  } catch (e) {
    showError(e as Error);
    throw e;
  }
};

export const updateAccountRole = (
  accountId: string,
  role: MemberType['role'],
) => {
  return api.accounts.updateAccountRole.mutate({ accountId, role });
};

export const inviteWorkspaceMembers = (
  workspaceId: string,
  invites: InvitedPersonType[],
) => {
  trackEvent('Workspace invite', { workspace: workspaceId });

  return api.accounts.inviteWorkspaceMembers.mutate({
    workspaceId,
    invites,
  });
};

export const requestUpdateAccountRole = (workspaceId: string) => {
  return api.accounts.requestUpgradeAccountRole.mutate({ workspaceId });
};

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();
  const { data: currentWorkspace } = useCurrentWorkspace();

  return useMutation({
    mutationFn: () => {
      const accountId = currentWorkspace.value?.accounts.find(
        (account) => account.userId === userId.value,
      )?.id;

      if (!accountId) {
        throw new Error('Account not found');
      }

      return api.accounts.deleteAccount.mutate({ accountId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
      queryClient.invalidateQueries(['workspaces']);
    },
  });
};
