import { api } from '@/api';
import { WorkspaceAccountPerson } from '@/api/types';
import { useSession, useUser, useUserId } from '@/auth/use-session';
import { store } from '@/store';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const overrideWorkspaceId = ref<string | null>(null);

export const useCurrentWorkspaceId = () => {
  const { data } = useUser();

  return computed(
    () =>
      overrideWorkspaceId.value ||
      data.value?.primaryAccount?.workspaceId ||
      '',
  );
};

export const useCurrentWorkspace = () => {
  const { data: session } = useSession();

  const workspaceId = useCurrentWorkspaceId();
  const enabled = computed(() => !!workspaceId.value);

  return useQuery<unknown, unknown, WorkspaceAccountPerson>({
    queryKey: ['currentWorkspace', workspaceId, session.value?.user?.id],
    queryFn: () => {
      if (!session.value?.user) {
        return api.workspaces.getPublicInfo.query({ id: workspaceId.value });
      }
      return api.workspaces.getById.query({ id: workspaceId.value });
    },
    enabled: enabled,
    onSuccess: (ws) => {
      store.currentWorkspace = ws;
    },
  });
};

export const useUpdateCurrentWorkspace = () => {
  const id = useUserId();
  const workspaceId = useCurrentWorkspaceId();
  const client = useQueryClient();

  return (update: WorkspaceAccountPerson) =>
    client.setQueryData(['currentWorkspace', workspaceId, id], update);
};

export const useSetCurrentWorkspaceId = () => {
  const route = useRoute();
  const router = useRouter();

  return (id: string) => {
    if (
      route.name &&
      route?.params?.workspaceId &&
      id &&
      route.params.workspaceId !== id
    ) {
      void router.push({
        name: route.name,
        params: { ...route.params, workspaceId: id },
      });
    }
    return (overrideWorkspaceId.value = id);
  };
};

export const useWorkspaces = () => {
  const { data } = useSession();

  return useQuery({
    queryKey: ['workspaces'],
    staleTime: 10000,
    queryFn: () => api.workspaces.getByUserIdSorted.query(),
    enabled: computed(() => !!data.value?.user),
  });
};
