<script setup lang="ts">
import { SettingsIcon } from 'vue-tabler-icons';
import { TranscriptionLang, SidebarItem } from './components';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { MenuLink } from '@/api/types';

const links: MenuLink[] = [
  {
    title: 'Settings',
    icon: SettingsIcon,
    to: { name: ROUTE_NAMES.SETTINGS_NEW.INDEX },
  },
];
</script>

<template>
  <div class="flex flex-col gap-y-[10px]">
    <TranscriptionLang />
    <SidebarItem
      v-for="{ title, icon, to } in links"
      :key="title"
      :title="title"
      :to="to"
    >
      <template #prepend>
        <Icon :src="icon" class="h-5 w-5" />
      </template>
    </SidebarItem>
  </div>
</template>
