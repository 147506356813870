import Brick from './Brick.vue';
import FilePicker from './FilePicker.vue';
import Icon from './Icon.vue';
import Link from './Link.vue';
import Loader from './Loader.vue';
import Switch from './Switch.vue';
import Divider from './Divider.vue';

export default {
  Brick,
  FilePicker,
  Icon,
  Link,
  Loader,
  Switch,
  Divider,
};
