import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const isReady = ref<boolean>(false);
export const isRouterReady = () => {
  const router = useRouter();
  onMounted(async () => {
    await router.isReady();
    isReady.value = true;
  });
  return isReady;
};
