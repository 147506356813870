<script setup lang="ts">
import { useDeleteAccount } from '@/components/settings/Personal/hooks';
import { WorkspaceCard } from './components';

const {
  isDeleteAccountPopupOpen,
  workspacesByRole,
  closeAllPopups,
  openDeleteAccountConfirmation,
} = useDeleteAccount();
</script>
<template>
  <v-dialog v-model="isDeleteAccountPopupOpen" persistent max-width="544">
    <div
      class="flex flex-col gap-y-8 bg-white w-full overflow-x-hidden rounded-2xl p-8 max-h-[800px] border border-grey-20 border-solid relative"
    >
      <ClosePopupButton :on-click="closeAllPopups" />
      <div class="flex flex-col gap-y-6">
        <div class="flex flex-col gap-y-4">
          <h4 class="font-inter text-2xl leading-normal">
            Deleting your account
          </h4>
          <p class="typo-body1">
            This action cannot be undone. Your access to the workspaces and
            Noty.ai integrations will be revoked. Please choose wisely if you
            would like to delete your account.
          </p>
          <div
            v-if="workspacesByRole.ADMIN.length"
            class="flex flex-col gap-y-4"
          >
            <h5 class="font-inter font-semibold">
              This private workspace will be deleted
            </h5>
            <p class="typo-body1">
              To keep this workspace, Cancel now and assign an admin role to one
              of the members. This member will become the owner of the workspace
              after your access to it is revoked.
            </p>
            <div class="flex flex-col gap-y-4 max-h-[170px] overflow-y-auto">
              <WorkspaceCard
                v-for="{
                  id,
                  name,
                  conversations,
                  accessors,
                } in workspacesByRole.ADMIN"
                :key="id"
                :name="name"
                :conversations-quantity="conversations.length"
                :accessors="accessors"
              />
            </div>
          </div>
        </div>
        <div
          v-if="workspacesByRole.MEMBER.length"
          class="flex flex-col gap-y-4"
        >
          <h5 class="font-inter font-semibold">
            You will stop being a member of these workspaces
          </h5>
          <div class="flex flex-col gap-y-4 max-h-[100px] overflow-y-auto">
            <WorkspaceCard
              v-for="{
                id,
                name,
                conversations,
                accessors,
              } in workspacesByRole.MEMBER"
              :key="id"
              :name="name"
              :conversations-quantity="conversations.length"
              :accessors="accessors"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-end gap-x-4">
        <v-btn
          color="primary"
          variant="text"
          height="36"
          class="typo-body1 font-semibold"
          @click="openDeleteAccountConfirmation"
        >
          Continue
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          height="36"
          class="typo-body1 font-semibold"
          @click="closeAllPopups"
        >
          Cancel
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
