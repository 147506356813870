<script setup lang="ts">
import { DefineComponent, reactive, ref, watch, computed } from 'vue';
import { CheckIcon, XIcon } from 'vue-tabler-icons';
import { useFocus } from '@vueuse/core';
import { showMessage } from '@/utils/alert';

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    errorMessage?: string;
    icon?: DefineComponent;
    disabled?: boolean;
    isConfirmButton?: boolean;
    minLength?: number;
    maxLength?: number;
    isClearable?: boolean;
    inputClass?: string;
    inputId?: string;
    stretchable?: boolean;
  }>(),
  {
    modelValue: '',
    minLength: 0,
    maxLength: 64,
  },
);

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>();

const inputElemRef = ref();
const { focused } = useFocus(inputElemRef);

const state = reactive({
  value: props.modelValue,
});

const resetStateValue = () => (state.value = props.modelValue);

watch(() => props.modelValue, resetStateValue);

const isValid = computed(
  () =>
    (props.isClearable && !state.value) ||
    props.minLength <= state.value?.length,
);

const updateModelValue = (isFocusOnInput = false) => {
  if (!isValid.value) {
    showMessage(`Text must contain at least ${props.minLength} character(s)`, {
      type: 'warning',
    });
    focused.value = false;
    return;
  }
  emit('update:modelValue', state.value);
  focused.value = isFocusOnInput;
};

const changeValue = (event: Event) => {
  state.value = (event.target as HTMLInputElement).value;
  if (!props.isConfirmButton) {
    updateModelValue(true);
  }
};

const clearValue = () => {
  state.value = '';
  updateModelValue();
};
</script>

<template>
  <div
    class="input-wrapper"
    :class="{ 'shrink-0': props.stretchable && focused }"
  >
    <Icon
      v-if="props.icon"
      :src="props.icon"
      class="input__icon"
      :class="{ 'input__icon--disabled': props.disabled }"
      size="18"
    />
    <input
      v-bind="$attrs"
      :id="inputId"
      ref="inputElemRef"
      type="text"
      class="input"
      :class="[
        inputClass,
        { 'input--danger': errorMessage },
        { 'input--disabled': props.disabled },
        { 'input--with-icon': props.icon },
        { 'input--with-confirm-btn': isConfirmButton },
        { 'input--empty': !state.value },
      ]"
      :value="state.value"
      :minlength="props.minLength"
      :maxlength="props.maxLength"
      :disabled="props.disabled"
      @input="changeValue"
      @blur="props.isConfirmButton && resetStateValue()"
      @keypress.enter="
        isConfirmButton &&
          (!state.value || state.value !== props.modelValue) &&
          updateModelValue()
      "
    />
    <div class="input__controls">
      <Button
        v-if="props.isClearable && state.value"
        class="input__controls__clear-btn"
        @mousedown.prevent
        @click="clearValue"
      >
        <Icon :src="XIcon" size="22" />
      </Button>
      <Button
        v-if="props.isConfirmButton && (props.icon ? true : focused && isValid)"
        v-show="focused && (!state.value || state.value !== props.modelValue)"
        class="input__controls__confirm-btn"
        :disabled="focused && !isValid"
        @mousedown.prevent
        @click="updateModelValue"
      >
        <Icon :src="props.icon || CheckIcon" size="22" />
      </Button>
    </div>
    <p v-show="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<style scoped>
.input-wrapper {
  @apply w-full relative;
}

.input {
  @apply font-inter h-fit w-full m-0 p-2 rounded-lg outline-0 border-2 border-solid border-slate-300 bg-white text-sm;
  @apply focus:border-primary focus:outline-0 placeholder:text-slate-400;
}

.input--empty {
  @apply focus:border-slate-300 !important;
}

.input--danger {
  @apply border border-solid border-red-600 !important;
}

.error {
  @apply mt-1 text-left text-red-600 text-2xs;
}

.input__icon {
  @apply absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none;
}

.input__controls {
  @apply absolute h-full top-0 right-0 flex items-center;
}

.input__controls__clear-btn {
  @apply mr-2 p-0 cursor-pointer text-slate-400 hover:text-red-500 hover:rotate-90 active:scale-90;
  @apply bg-white hover:bg-white;
}

.input__controls__confirm-btn {
  @apply h-full aspect-square bg-primary text-white p-0 rounded-none hover:bg-primary;
}

.input--with-icon {
  @apply pl-12;
}

.input--with-confirm-btn {
  @apply focus:pr-16;
}

.input--disabled,
.input__icon--disabled {
  @apply text-slate-500;
}
</style>
